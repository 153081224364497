import React, { useState, FormEvent, useCallback } from "react";
import { Modal, Form, Button, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Errors, HasErrors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { BlockApi } from "services/apis/BlockApi";
import { Block, DefaultBlock } from "services/apis/types/block/Block";
import { PhoneNumberType } from "services/apis/types/number/PhoneNumberType";
import { isValidLrn } from "services/validators/ValidationUtil";
import { isNumber } from "services/util/StringUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInput from "components/framework/forms/TextFormInput";
import {
  DefaultOptionalDataInfo,
  OptionalDataInfo
} from "services/apis/types/number/OptionalDataInfo";
import { nameOf } from "services/util/ObjectUtil";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { DefaultDpcSsnInfo, DpcSsnInfo } from "services/apis/types/block/DpcSsnInfo";
import { SvType } from "services/apis/types/block/SvType";
import CloseButton from "components/framework/modals/CloseButton";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";

type Props = {
  closeModal: () => void;
  block?: Block;
};

export default function CreateBlockModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [block, setBlock] = useState<Block>(props.block ? props.block : DefaultBlock);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const closeModal = useCallback(props.closeModal, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    const errors = validateBlock(block);

    if (HasErrors(errors)) {
      setErrors(errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);
      }
    } else {
      BlockApi.addOrModify(block)
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "network.block.edit.successNotificationMessage" })
          );
          closeModal();
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Modal className="modal-dialog-centered modal-lg" isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          {!props.block && <FormattedMessage id="network.block.edit.createModalTitle" />}
          {props.block && <FormattedMessage id="network.block.edit.modifyModalTitle" />}
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-4"
              labelTranslationId="network.block.edit.blockDash"
              value={block.blockDash}
              handleInputChange={(value: string) => setBlock({ ...block, blockDash: value })}
              errorHandler={getErrorHandler(nameOf<Block>("blockDash"))}
            />
            <TextFormInput
              formGroupClassName="col-lg-4"
              labelTranslationId="network.block.edit.lrn"
              value={block.lrn}
              handleInputChange={(value: string) => setBlock({ ...block, lrn: value })}
              errorHandler={getErrorHandler(nameOf<Block>("lrn"))}
            />
            <DropdownFormInput
              className="col-lg-4"
              required
              labelTranslationId="network.block.edit.svType"
              value={block.svType}
              handleInputChange={(value: string) => setBlock({ ...block, svType: value })}
              errorHandler={getErrorHandler(nameOf<Block>("svType"))}
              options={Object.keys(SvType).map((x) => {
                return { key: x, value: SvType[x] };
              })}
            />
          </Row>
          <h4>
            <FormattedMessage id="network.block.edit.networkData.header" />
          </h4>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.networkData.classDpc"
              value={block.networkData ? block.networkData.classDpc : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  networkData: block.networkData
                    ? { ...block.networkData, classDpc: value }
                    : { ...DefaultDpcSsnInfo, classDpc: value }
                })
              }
              errorHandler={getErrorHandler(nameOf<Block, DpcSsnInfo>("networkData", "classDpc"))}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.networkData.classSsn"
              value={block.networkData ? block.networkData.classSsn : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  networkData: block.networkData
                    ? { ...block.networkData, classSsn: value }
                    : { ...DefaultDpcSsnInfo, classSsn: value }
                })
              }
              errorHandler={getErrorHandler(nameOf<Block, DpcSsnInfo>("networkData", "classSsn"))}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.networkData.lidbDpc"
              value={block.networkData ? block.networkData.lidbDpc : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  networkData: block.networkData
                    ? { ...block.networkData, lidbDpc: value }
                    : { ...DefaultDpcSsnInfo, lidbDpc: value }
                })
              }
              errorHandler={getErrorHandler(nameOf<Block, DpcSsnInfo>("networkData", "lidbDpc"))}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.networkData.lidbSsn"
              value={block.networkData ? block.networkData.lidbSsn : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  networkData: block.networkData
                    ? { ...block.networkData, lidbSsn: value }
                    : { ...DefaultDpcSsnInfo, lidbSsn: value }
                })
              }
              errorHandler={getErrorHandler(nameOf<Block, DpcSsnInfo>("networkData", "lidbSsn"))}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.networkData.isvmDpc"
              value={block.networkData ? block.networkData.isvmDpc : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  networkData: block.networkData
                    ? { ...block.networkData, isvmDpc: value }
                    : { ...DefaultDpcSsnInfo, isvmDpc: value }
                })
              }
              errorHandler={getErrorHandler(nameOf<Block, DpcSsnInfo>("networkData", "isvmDpc"))}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.networkData.isvmSsn"
              value={block.networkData ? block.networkData.isvmSsn : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  networkData: block.networkData
                    ? { ...block.networkData, isvmSsn: value }
                    : { ...DefaultDpcSsnInfo, isvmSsn: value }
                })
              }
              errorHandler={getErrorHandler(nameOf<Block, DpcSsnInfo>("networkData", "isvmSsn"))}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.networkData.cnamDpc"
              value={block.networkData ? block.networkData.cnamDpc : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  networkData: block.networkData
                    ? { ...block.networkData, cnamDpc: value }
                    : { ...DefaultDpcSsnInfo, cnamDpc: value }
                })
              }
              errorHandler={getErrorHandler(nameOf<Block, DpcSsnInfo>("networkData", "cnamDpc"))}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.networkData.cnamSsn"
              value={block.networkData ? block.networkData.cnamSsn : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  networkData: block.networkData
                    ? { ...block.networkData, cnamSsn: value }
                    : { ...DefaultDpcSsnInfo, cnamSsn: value }
                })
              }
              errorHandler={getErrorHandler(nameOf<Block, DpcSsnInfo>("networkData", "cnamSsn"))}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.networkData.wsmscDpc"
              value={block.networkData ? block.networkData.wsmscDpc : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  networkData: block.networkData
                    ? { ...block.networkData, wsmscDpc: value }
                    : { ...DefaultDpcSsnInfo, wsmscDpc: value }
                })
              }
              errorHandler={getErrorHandler(nameOf<Block, DpcSsnInfo>("networkData", "wsmscDpc"))}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.networkData.wsmscSsn"
              value={block.networkData ? block.networkData.wsmscSsn : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  networkData: block.networkData
                    ? { ...block.networkData, wsmscSsn: value }
                    : { ...DefaultDpcSsnInfo, wsmscSsn: value }
                })
              }
              errorHandler={getErrorHandler(nameOf<Block, DpcSsnInfo>("networkData", "wsmscSsn"))}
            />
          </Row>
          <h4>
            <FormattedMessage id="network.block.edit.optionalData.header" />
          </h4>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.optionalData.altSpId"
              value={block.optionalData ? block.optionalData.altSpId : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  optionalData: block.optionalData
                    ? { ...block.optionalData, altSpId: value }
                    : { ...DefaultOptionalDataInfo, altSpId: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Block, OptionalDataInfo>("optionalData", "altSpId")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.optionalData.lastAltSpId"
              value={block.optionalData ? block.optionalData.lastAltSpId : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  optionalData: block.optionalData
                    ? { ...block.optionalData, lastAltSpId: value }
                    : { ...DefaultOptionalDataInfo, lastAltSpId: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Block, OptionalDataInfo>("optionalData", "lastAltSpId")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.optionalData.altBillingId"
              value={block.optionalData ? block.optionalData.altBillingId : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  optionalData: block.optionalData
                    ? { ...block.optionalData, altBillingId: value }
                    : { ...DefaultOptionalDataInfo, altBillingId: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Block, OptionalDataInfo>("optionalData", "altBillingId")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.optionalData.altUserLocationType"
              value={block.optionalData ? block.optionalData.altUserLocationType : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  optionalData: block.optionalData
                    ? { ...block.optionalData, altUserLocationType: value }
                    : { ...DefaultOptionalDataInfo, altUserLocationType: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Block, OptionalDataInfo>("optionalData", "altUserLocationType")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.optionalData.altUserLocationValue"
              value={block.optionalData ? block.optionalData.altUserLocationValue : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  optionalData: block.optionalData
                    ? { ...block.optionalData, altUserLocationValue: value }
                    : { ...DefaultOptionalDataInfo, altUserLocationValue: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Block, OptionalDataInfo>("optionalData", "altUserLocationValue")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.optionalData.voiceUri"
              value={block.optionalData ? block.optionalData.voiceUri : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  optionalData: block.optionalData
                    ? { ...block.optionalData, voiceUri: value }
                    : { ...DefaultOptionalDataInfo, voiceUri: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Block, OptionalDataInfo>("optionalData", "voiceUri")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.optionalData.mmsUri"
              value={block.optionalData ? block.optionalData.mmsUri : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  optionalData: block.optionalData
                    ? { ...block.optionalData, mmsUri: value }
                    : { ...DefaultOptionalDataInfo, mmsUri: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Block, OptionalDataInfo>("optionalData", "mmsUri")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.block.edit.optionalData.smsUri"
              value={block.optionalData ? block.optionalData.smsUri : ""}
              handleInputChange={(value: string) =>
                setBlock({
                  ...block,
                  optionalData: block.optionalData
                    ? { ...block.optionalData, smsUri: value }
                    : { ...DefaultOptionalDataInfo, smsUri: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Block, OptionalDataInfo>("optionalData", "smsUri")
              )}
            />
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={() => props.closeModal()}>
            <FormattedMessage id="network.block.edit.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className="ml-auto" disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="network.block.edit.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

const validateBlock = (block: Block) => {
  const errors: Errors = {};

  if (!block.blockDash || block.blockDash.length !== 7 || !isNumber(block.blockDash)) {
    AddError(errors, nameOf<Block>("blockDash"), "network.block.edit.blockDash.invalid");
  }

  if (!isValidLrn(block.lrn)) {
    AddError(errors, nameOf<Block>("lrn"), "network.block.edit.lrn.invalid");
  }

  if (!block.svType || !Object.values(PhoneNumberType).find((x) => x === block.svType)) {
    AddError(errors, nameOf<Block>("svType"), "network.block.edit.svType.invalid");
  }

  return errors;
};
