import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { validateNumberInputValue } from "services/validators/ValidatePhoneNumbers";
import { ValidationResult } from "services/validators/ValidationResult";
import { FormattedMessage, useIntl } from "react-intl";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { getPhoneNumberList } from "services/util/StringUtil";
import PortOutValidationSection from "./PortOutValidationSection";
import PortOutProposalSection from "./PortOutProposalSection";
import { PortOutModel, DefaultPortOutModel } from "components/orders/types/PortOutModel";
import { Link } from "react-router-dom";
import { ApiError } from "components/common/types/ApiError";
import { ApiErrorType } from "components/common/types/ApiErrorType";

export type PortOutContext = {
  deleteOrderCardCallback: () => void;
  deletePhoneNumberCallback: (phoneNumber: string) => void;
};

export const PortOutContext = React.createContext<PortOutContext>({
  deleteOrderCardCallback: () => {},
  deletePhoneNumberCallback: (phoneNumber: string) => {}
});

export default function PortOut() {
  const [validationErrorMessages, setValidationErrorMessages] = useState<ApiError[]>([]);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [portOutModel, setPortOutModel] = useState<PortOutModel>(DefaultPortOutModel);
  const intl = useIntl();
  const isMounted = useIsMounted();

  const generateCardHandler = async (
    numberInputValue: string,
    spId: string,
    providerName: string
  ) => {
    if (isMounted.current) {
      setShowLoadingIndicator(true);
    }

    const validationResult = validateNumberInputValue(numberInputValue);

    if (validationResult === ValidationResult.Valid) {
      setValidationErrorMessages([]);
      setPortOutModel({
        ...portOutModel,
        phoneNumbers: getPhoneNumberList(numberInputValue),
        newSpId: spId,
        newProviderName: providerName
      });
    } else {
      setValidationErrorMessages([
        {
          message: `${intl.formatMessage({ id: "orders.portOut.stepOne.invalidNumbers" })}`,
          type: ApiErrorType.Danger
        }
      ]);
    }

    setShowLoadingIndicator(false);
  };

  const portOutContext: PortOutContext = {
    deleteOrderCardCallback: () => setPortOutModel({ ...portOutModel, phoneNumbers: [] }),
    deletePhoneNumberCallback: (phoneNumber: string) =>
      setPortOutModel({
        ...portOutModel,
        phoneNumbers: portOutModel.phoneNumbers.filter((y) => y !== phoneNumber)
      })
  };

  return (
    <PortOutContext.Provider value={portOutContext}>
      <BreadcrumbHeader>
        <Link to={"/Orders"}>
          <FormattedMessage id="orders.title" />
        </Link>
        <FormattedMessage id="orders.portOut.title" />
      </BreadcrumbHeader>
      <Container fluid>
        <Row className="card-wrapper">
          <Col lg="12">
            <PortOutValidationSection
              generateCardCallback={generateCardHandler}
              noOfPhoneNumbers={portOutModel.phoneNumbers.length}
              validationMessages={validationErrorMessages}
            />
          </Col>
          <Col lg="12">
            {showLoadingIndicator ? (
              <LoadingIndicator white />
            ) : (
              <>
                {portOutModel.phoneNumbers.length > 0 && (
                  <PortOutProposalSection
                    portOutModel={portOutModel}
                    setPortOutModel={setPortOutModel}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </PortOutContext.Provider>
  );
}
