import React, { useState, FormEvent, useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";
import { useQuery } from "services/routing/RoutingUtil";
import { useIntl, FormattedMessage } from "react-intl";
import { ResetPasswordModel, DefaultResetPasswordModel } from "./ResetPasswordModel";
import { Errors, HasErrors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { IdentityApi } from "services/apis/IdentityApi";
import LoginLayout from "components/framework/loginLayout/LoginLayout";
import LoginHeader from "components/framework/loginLayout/LoginHeader";
import { Link } from "react-router-dom";
import { isEmail } from "services/util/StringUtil";
import { nameOf } from "services/util/ObjectUtil";
import { getFieldErrors, handleError } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInputGroup from "components/framework/forms/TextFormInputGroup";

export default function ResetPassword() {
  const queryString = useQuery();
  const intl = useIntl();
  const isMounted = useRef(true);
  const [resetPasswordModel, setResetPasswordModel] = useState<ResetPasswordModel>(DefaultResetPasswordModel);
  const { setErrors, getErrorHandler } = useErrors();
  const [showResetPasswordError, setShowResetPasswordError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    const errors = validateModel(resetPasswordModel);

    if (HasErrors(errors)) {
      setErrors(errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);
      }
    } else {
      IdentityApi.resetPassword({
        email: resetPasswordModel.email,
        token: queryString.get("token") || "",
        password: resetPasswordModel.password
      })
        .then(() => setShowSuccessMessage(true))
        .catch(error => {
          handleError(error);
          if (isMounted.current) {
            setShowResetPasswordError(true);
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <LoginLayout>
      <LoginHeader title={intl.formatMessage({ id: "account.resetPassword.header" })} />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center mb-4">
          <Col lg="5" md="7">
            <Alert color="danger" isOpen={showResetPasswordError} toggle={() => setShowResetPasswordError(false)}>
              <span className="alert-text ml-1">
                <strong>
                  <FormattedMessage id="account.resetPassword.error" />
                </strong>
              </span>
            </Alert>
            <Alert color="info" isOpen={showSuccessMessage} toggle={() => setShowSuccessMessage(false)}>
              <span className="alert-text ml-1">
                <strong>
                  <FormattedMessage
                    id="account.resetPassword.successMessage"
                    values={{
                      loginPage: (
                        <Link to="/Login">
                          <strong>
                            <FormattedMessage id="account.resetPassword.loginPage" />
                          </strong>
                        </Link>
                      )
                    }}
                  />
                </strong>
              </span>
            </Alert>

            {!showSuccessMessage && (
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>
                      <FormattedMessage id="account.resetPassword.title" />
                    </small>
                  </div>
                  <Form role="form" onSubmit={handleSubmit}>
                    <TextFormInputGroup
                      icon="fa-envelope"
                      placeholderTranslationId="account.resetPassword.email"
                      type="text"
                      value={resetPasswordModel.email}
                      handleInputChange={value => setResetPasswordModel({ ...resetPasswordModel, email: value })}
                      errorHandler={getErrorHandler(nameOf<ResetPasswordModel>("email"))}
                    />
                    <TextFormInputGroup
                      placeholderTranslationId="account.resetPassword.password"
                      icon="fas fa-unlock-alt"
                      type="password"
                      value={resetPasswordModel.password}
                      handleInputChange={value => setResetPasswordModel({ ...resetPasswordModel, password: value })}
                      errorHandler={getErrorHandler(nameOf<ResetPasswordModel>("password"))}
                    />

                    <TextFormInputGroup
                      type="password"
                      icon="fas fa-unlock-alt"
                      placeholderTranslationId="account.resetPassword.confirmPassword"
                      value={resetPasswordModel.confirmPassword}
                      handleInputChange={value => setResetPasswordModel({ ...resetPasswordModel, confirmPassword: value })}
                      errorHandler={getErrorHandler(nameOf<ResetPasswordModel>("confirmPassword"))}
                    />

                    <div className="text-center">
                      <Button className="btn btn-primary mt-4" color="primary" type="submit">
                        {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                        <FormattedMessage id="account.resetPassword.resetPassword" />
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            )}
            {!showSuccessMessage && (
              <Row className="mt-3">
                <Col xs="6">
                  <small>
                    <Link to="/Login" className="text-light">
                      <FormattedMessage id="account.resetPassword.goBack" />
                    </Link>
                  </small>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </LoginLayout>
  );
}

const validateModel = (resetPasswordModel: ResetPasswordModel): Errors => {
  const errors: Errors = {};

  if (resetPasswordModel.email.length === 0) {
    AddError(errors, nameOf<ResetPasswordModel>("email"), "account.resetPassword.email.emptyError");
  }

  if (resetPasswordModel.password.length === 0) {
    AddError(errors, nameOf<ResetPasswordModel>("password"), "account.resetPassword.password.emptyError");
  }

  if (resetPasswordModel.confirmPassword.length === 0) {
    AddError(errors, nameOf<ResetPasswordModel>("confirmPassword"), "account.resetPassword.password.emptyError");
  }

  if (!isEmail(resetPasswordModel.email)) {
    AddError(errors, nameOf<ResetPasswordModel>("email"), "account.resetPassword.email.notAnEmailError");
  }

  if (
    resetPasswordModel.password &&
    resetPasswordModel.confirmPassword &&
    resetPasswordModel.password !== resetPasswordModel.confirmPassword
  ) {
    AddError(errors, nameOf<ResetPasswordModel>("confirmPassword"), "account.resetPassword.confirmPassword.passwordsDoNotMatchError");
  }

  return errors;
};
