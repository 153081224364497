import React, { useState, FormEvent, useCallback, useEffect, useContext } from "react";
import { Modal, Form, Button, Row} from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Moment } from "moment";
import { AppContext } from "services/appContext/AppContext";
import { useErrors } from "services/customHooks/useErrors";
import { Modify, DefaultModify } from "../types/Modify";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { NumberApi } from "services/apis/NumberApi";
import { removeDashes, isPhoneNumber, isRange } from "services/util/StringUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { HasErrors, Errors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { PortApi } from "services/apis/PortApi";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import NpacMaintenanceAlert from "../NpacMaintenanceAlert";
import CloseButton from "components/framework/modals/CloseButton";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { ModifyModel } from "services/apis/types/port/ModifyModel";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { SvType } from "services/apis/types/block/SvType";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import { DefaultDpcSsnInfo, DpcSsnInfo } from "services/apis/types/block/DpcSsnInfo";
import {
  DefaultOptionalDataInfo,
  OptionalDataInfo
} from "services/apis/types/number/OptionalDataInfo";
import { getUtcDate, isSunday } from "services/util/DateUtil";
import PhoneNumberInput from "components/framework/forms/PhoneNumberInput";
import { getFormattedNumbers } from "services/portControl/PhoneNumberService";

type Props = {
  closeModal: () => void;
  phoneNumber?: string;
};

export default function ModifyModal(props: Props) {
  const appContext = useContext(AppContext);
  const { setErrors, getErrorHandler } = useErrors();
  const [modify, setModify] = useState<Modify>({
    ...DefaultModify,
    phoneNumberRange: props.phoneNumber ? props.phoneNumber : ""
  });
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [loadingNumberDetails, setLoadingNumberDetails] = useState(false);
  const [hasNotAllowedSpId, setHasNotAllowedSpId] = useState(true);
  const [lastFetchedNumber, setLastFetchedNumber] = useState("");
  const intl = useIntl();
  const isMounted = useIsMounted();
  const closeModal = useCallback(props.closeModal, []);

  const getSelectedSpId = useCallback(() => {
    return appContext.appContext.localStorageInfo.selectedProfile
      ? appContext.appContext.localStorageInfo.selectedProfile.spId
      : "";
  }, [appContext.appContext.localStorageInfo.selectedProfile]);

  const fetchNumberDetails = useCallback(
    (phoneNumber: string) => {
      NumberApi.details(removeDashes(phoneNumber))
        .then((result) => {
          if (isMounted.current) {
            const hasNotAllowedSpId = result.spId !== getSelectedSpId();
            setHasNotAllowedSpId(hasNotAllowedSpId);
            const newModify = {
              ...modify,
              phoneNumberRange: getFormattedNumbers(modify.phoneNumberRange),
              modifyModel: {
                ...modify.modifyModel,
                spId: result.spId,
                oldSpId: result.originalSpId,
                npacRegion: result.npacRegion,
                lrn: result.lrn,
                svType: result.type ? result.type : DefaultModify.modifyModel.svType,
                billingId: result.billingId,
                userLocationType: result.userLocationType,
                userLocationValue: result.userLocationValue,
                networkData: result.networkData,
                optionalData: result.optionalData
              }
            };
            setErrors(validateModify(newModify, hasNotAllowedSpId));
            setModify(newModify);
          }
        })
        .catch((error) => handleError(error))
        .finally(() => {
          if (isMounted.current) {
            setLoadingNumberDetails(false);
          }
        });
    },
    [getSelectedSpId, isMounted, modify, setErrors]
  );

  const fetchNumbers = useCallback(
    (tnRange: string) => {
      NumberApi.search(tnRange)
        .then((result) => {
          if (isMounted.current) {
            const hasNotAllowedSpId = result.filter((x) => x.spId !== getSelectedSpId()).length > 0;
            setHasNotAllowedSpId(hasNotAllowedSpId);
            if (hasNotAllowedSpId) {
              setLoadingNumberDetails(false);
              setErrors(validateModify(modify, hasNotAllowedSpId));
            } else {
              fetchNumberDetails(result[0].phoneNumber);
            }
          }
        })
        .catch((error) => handleError(error));
    },
    [fetchNumberDetails, getSelectedSpId, isMounted, modify, setErrors]
  );

  useEffect(() => {
    const phoneNumber = getFormattedNumbers(modify.phoneNumberRange);
    const isNumber = isPhoneNumber(phoneNumber);
    const isNumberRange = isRange(phoneNumber);

    if ((isNumber || isNumberRange) && phoneNumber !== lastFetchedNumber) {
      setLoadingNumberDetails(true);

      if (isNumberRange) {
        fetchNumbers(phoneNumber);
      } else {
        fetchNumberDetails(phoneNumber);
      }

      setLastFetchedNumber(phoneNumber);
    }
  }, [fetchNumberDetails, fetchNumbers, modify.phoneNumberRange, lastFetchedNumber]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const errors = validateModify(modify, hasNotAllowedSpId);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowLoadingIndicator(true);
      PortApi.modify(modify.phoneNumberRange, modify.modifyModel)
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "orders.modify.edit.numberSuccessNotificationMessage" })
          );
          closeModal();
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Modal className="modal-dialog-centered modal-lg" isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id="orders.modify.edit.title" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          <Row>
            <PhoneNumberInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.tnRange"
              placeholderTranslationId="orders.modify.edit.tnRange.placeholder"
              value={modify.phoneNumberRange}
              handleInputChange={(value: string) =>
                setModify({ ...modify, phoneNumberRange: value })
              }
              errorHandler={getErrorHandler(nameOf<Modify>("phoneNumberRange"))}
            />
            <div className="col-lg-6 d-flex align-items-center">
              {loadingNumberDetails && <LoadingIndicator small />}
            </div>
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.spId"
              value={modify.modifyModel.spId}
              readonly
              handleInputChange={(value: string) =>
                setModify({ ...modify, modifyModel: { ...modify.modifyModel, spId: value } })
              }
              errorHandler={getErrorHandler(nameOf<Modify, ModifyModel>("modifyModel", "spId"))}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.clientReference"
              value={modify.modifyModel.clientReference}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: { ...modify.modifyModel, clientReference: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel>("modifyModel", "clientReference")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.lrn"
              value={modify.modifyModel.lrn}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({ ...modify, modifyModel: { ...modify.modifyModel, lrn: value } })
              }
              errorHandler={getErrorHandler(nameOf<Modify, ModifyModel>("modifyModel", "lrn"))}
            />
            <DropdownFormInput
              className="col-lg-6"
              labelTranslationId="orders.modify.edit.svType"
              required
              value={modify.modifyModel.svType}
              disabled={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: { ...modify.modifyModel, svType: value }
                })
              }
              errorHandler={getErrorHandler(nameOf<Modify, ModifyModel>("modifyModel", "svType"))}
              options={Object.keys(SvType).map((x) => {
                return { key: x, value: SvType[x] };
              })}
            />
          </Row>
          <Row>
            <DateTimeFormInput
              className="col-lg-6"
              labelTranslationId="orders.modify.edit.dueDate"
              value={getUtcDate(modify.modifyModel.dueDate)}
              disabled={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: Moment) =>
                setModify({
                  ...modify,
                  modifyModel: { ...modify.modifyModel, dueDate: value.toDate() }
                })
              }
              errorHandler={getErrorHandler(nameOf<Modify, ModifyModel>("modifyModel", "dueDate"))}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.billingId"
              value={modify.modifyModel.billingId}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({ ...modify, modifyModel: { ...modify.modifyModel, billingId: value } })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel>("modifyModel", "billingId")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.userLocationType"
              value={modify.modifyModel.userLocationType}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: { ...modify.modifyModel, userLocationType: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel>("modifyModel", "userLocationType")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.userLocationValue"
              value={modify.modifyModel.userLocationValue}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: { ...modify.modifyModel, userLocationValue: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel>("modifyModel", "userLocationValue")
              )}
            />
          </Row>

          <h4>
            <FormattedMessage id="orders.modify.edit.networkData.header" />
          </h4>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.classDpc"
              value={modify.modifyModel.networkData ? modify.modifyModel.networkData.classDpc : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    networkData: modify.modifyModel.networkData
                      ? { ...modify.modifyModel.networkData, classDpc: value }
                      : { ...DefaultDpcSsnInfo, classDpc: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, DpcSsnInfo>("modifyModel", "networkData", "classDpc")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.classSsn"
              value={modify.modifyModel.networkData ? modify.modifyModel.networkData.classSsn : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    networkData: modify.modifyModel.networkData
                      ? { ...modify.modifyModel.networkData, classSsn: value }
                      : { ...DefaultDpcSsnInfo, classSsn: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, DpcSsnInfo>("modifyModel", "networkData", "classSsn")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.lidbDpc"
              value={modify.modifyModel.networkData ? modify.modifyModel.networkData.lidbDpc : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    networkData: modify.modifyModel.networkData
                      ? { ...modify.modifyModel.networkData, lidbDpc: value }
                      : { ...DefaultDpcSsnInfo, lidbDpc: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, DpcSsnInfo>("modifyModel", "networkData", "lidbDpc")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.lidbSsn"
              value={modify.modifyModel.networkData ? modify.modifyModel.networkData.lidbSsn : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    networkData: modify.modifyModel.networkData
                      ? { ...modify.modifyModel.networkData, lidbSsn: value }
                      : { ...DefaultDpcSsnInfo, lidbSsn: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, DpcSsnInfo>("modifyModel", "networkData", "lidbSsn")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.isvmDpc"
              value={modify.modifyModel.networkData ? modify.modifyModel.networkData.isvmDpc : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    networkData: modify.modifyModel.networkData
                      ? { ...modify.modifyModel.networkData, isvmDpc: value }
                      : { ...DefaultDpcSsnInfo, isvmDpc: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, DpcSsnInfo>("modifyModel", "networkData", "isvmDpc")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.isvmSsn"
              value={modify.modifyModel.networkData ? modify.modifyModel.networkData.isvmSsn : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    networkData: modify.modifyModel.networkData
                      ? { ...modify.modifyModel.networkData, isvmSsn: value }
                      : { ...DefaultDpcSsnInfo, isvmSsn: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, DpcSsnInfo>("modifyModel", "networkData", "isvmSsn")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.cnamDpc"
              value={modify.modifyModel.networkData ? modify.modifyModel.networkData.cnamDpc : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    networkData: modify.modifyModel.networkData
                      ? { ...modify.modifyModel.networkData, cnamDpc: value }
                      : { ...DefaultDpcSsnInfo, cnamDpc: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, DpcSsnInfo>("modifyModel", "networkData", "cnamDpc")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.cnamSsn"
              value={modify.modifyModel.networkData ? modify.modifyModel.networkData.cnamSsn : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    networkData: modify.modifyModel.networkData
                      ? { ...modify.modifyModel.networkData, cnamSsn: value }
                      : { ...DefaultDpcSsnInfo, cnamSsn: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, DpcSsnInfo>("modifyModel", "networkData", "cnamSsn")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.wsmscDpc"
              value={modify.modifyModel.networkData ? modify.modifyModel.networkData.wsmscDpc : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    networkData: modify.modifyModel.networkData
                      ? { ...modify.modifyModel.networkData, wsmscDpc: value }
                      : { ...DefaultDpcSsnInfo, wsmscDpc: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, DpcSsnInfo>("modifyModel", "networkData", "wsmscDpc")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.networkData.wsmscSsn"
              value={modify.modifyModel.networkData ? modify.modifyModel.networkData.wsmscSsn : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    networkData: modify.modifyModel.networkData
                      ? { ...modify.modifyModel.networkData, wsmscSsn: value }
                      : { ...DefaultDpcSsnInfo, wsmscSsn: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, DpcSsnInfo>("modifyModel", "networkData", "wsmscSsn")
              )}
            />
          </Row>
          <h4>
            <FormattedMessage id="orders.modify.edit.optionalData.header" />
          </h4>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.altSpId"
              value={modify.modifyModel.optionalData ? modify.modifyModel.optionalData.altSpId : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    optionalData: modify.modifyModel.optionalData
                      ? { ...modify.modifyModel.optionalData, altSpId: value }
                      : { ...DefaultOptionalDataInfo, altSpId: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, OptionalDataInfo>(
                  "modifyModel",
                  "optionalData",
                  "altSpId"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.lastAltSpId"
              value={
                modify.modifyModel.optionalData ? modify.modifyModel.optionalData.lastAltSpId : ""
              }
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    optionalData: modify.modifyModel.optionalData
                      ? { ...modify.modifyModel.optionalData, lastAltSpId: value }
                      : { ...DefaultOptionalDataInfo, lastAltSpId: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, OptionalDataInfo>(
                  "modifyModel",
                  "optionalData",
                  "lastAltSpId"
                )
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.altBillingId"
              value={
                modify.modifyModel.optionalData ? modify.modifyModel.optionalData.altBillingId : ""
              }
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    optionalData: modify.modifyModel.optionalData
                      ? { ...modify.modifyModel.optionalData, altBillingId: value }
                      : { ...DefaultOptionalDataInfo, altBillingId: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, OptionalDataInfo>(
                  "modifyModel",
                  "optionalData",
                  "altBillingId"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.altUserLocationType"
              value={
                modify.modifyModel.optionalData
                  ? modify.modifyModel.optionalData.altUserLocationType
                  : ""
              }
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    optionalData: modify.modifyModel.optionalData
                      ? { ...modify.modifyModel.optionalData, altUserLocationType: value }
                      : { ...DefaultOptionalDataInfo, altUserLocationType: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, OptionalDataInfo>(
                  "modifyModel",
                  "optionalData",
                  "altUserLocationType"
                )
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.altUserLocationValue"
              value={
                modify.modifyModel.optionalData
                  ? modify.modifyModel.optionalData.altUserLocationValue
                  : ""
              }
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    optionalData: modify.modifyModel.optionalData
                      ? { ...modify.modifyModel.optionalData, altUserLocationValue: value }
                      : { ...DefaultOptionalDataInfo, altUserLocationValue: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, OptionalDataInfo>(
                  "modifyModel",
                  "optionalData",
                  "altUserLocationValue"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.voiceUri"
              value={
                modify.modifyModel.optionalData ? modify.modifyModel.optionalData.voiceUri : ""
              }
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    optionalData: modify.modifyModel.optionalData
                      ? { ...modify.modifyModel.optionalData, voiceUri: value }
                      : { ...DefaultOptionalDataInfo, voiceUri: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, OptionalDataInfo>(
                  "modifyModel",
                  "optionalData",
                  "voiceUri"
                )
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.mmsUri"
              value={modify.modifyModel.optionalData ? modify.modifyModel.optionalData.mmsUri : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    optionalData: modify.modifyModel.optionalData
                      ? { ...modify.modifyModel.optionalData, mmsUri: value }
                      : { ...DefaultOptionalDataInfo, mmsUri: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, OptionalDataInfo>(
                  "modifyModel",
                  "optionalData",
                  "mmsUri"
                )
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="orders.modify.edit.optionalData.smsUri"
              value={modify.modifyModel.optionalData ? modify.modifyModel.optionalData.smsUri : ""}
              readonly={loadingNumberDetails || hasNotAllowedSpId}
              handleInputChange={(value: string) =>
                setModify({
                  ...modify,
                  modifyModel: {
                    ...modify.modifyModel,
                    optionalData: modify.modifyModel.optionalData
                      ? { ...modify.modifyModel.optionalData, smsUri: value }
                      : { ...DefaultOptionalDataInfo, smsUri: value }
                  }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<Modify, ModifyModel, OptionalDataInfo>(
                  "modifyModel",
                  "optionalData",
                  "smsUri"
                )
              )}
            />
          </Row>
          <Row>
            { 
              isSunday(modify.modifyModel.dueDate) && (
                <NpacMaintenanceAlert
                  classNames = "pc-npac-maintenance-modal-warning"/>
            )}
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={() => props.closeModal()}>
            <FormattedMessage id="orders.modify.edit.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className="ml-auto" disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="orders.modify.edit.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

const validateModify = (modify: Modify, hasNotAllowedSpId: boolean) => {
  const errors: Errors = {};

  if (!modify.phoneNumberRange) {
    AddError(errors, nameOf<Modify>("phoneNumberRange"), "orders.modify.edit.tnRange.required");
  } else if (!isPhoneNumber(modify.phoneNumberRange) && !isRange(modify.phoneNumberRange)) {
    AddError(errors, nameOf<Modify>("phoneNumberRange"), "orders.modify.edit.tnRange.invalid");
  }

  if (hasNotAllowedSpId && isRange(modify.phoneNumberRange)) {
    AddError(errors, nameOf<Modify>("phoneNumberRange"), "orders.modify.edit.tnRange.notAllowed");
  } else if (hasNotAllowedSpId && isPhoneNumber(modify.phoneNumberRange)) {
    AddError(
      errors,
      nameOf<Modify, ModifyModel>("modifyModel", "spId"),
      "orders.modify.edit.spId.notAllowed"
    );
  }

  return errors;
};
