import React from "react";
import SpIdCell from "components/common/SpIdCell";
import { FormattedMessage } from "react-intl";

type Props = {
  failedSpList: string[];
};

export default function FailedSpListCell(props: Props) {
  return (
    <>
      {props.failedSpList.length > 0 ? (
        props.failedSpList.map(x => <SpIdCell key={x} spId={x} />)
      ) : (
        <FormattedMessage id="notAvailable" />
      )}
    </>
  );
}
