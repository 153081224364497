import React, { useState, FormEvent, useContext } from "react";
import { Modal, Form, Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useErrors } from "services/customHooks/useErrors";
import { PortInModify } from "../types/PortInModify";
import moment, { Moment } from "moment";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { getFieldErrors, handleFieldOrApiErrors } from "services/util/ApiUtil";
import CloseButton from "components/framework/modals/CloseButton";
import { nameOf } from "services/util/ObjectUtil";
import { ModifyModel } from "services/apis/types/port/ModifyModel";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import { OrderApi } from "services/apis/OrderApi";
import { OrderDto } from "services/apis/types/order/OrderDto";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { SupplementType, SupplementTypesList } from "services/apis/types/order/SupplementType";
import { SupplementModel, DefaultSupplementModel } from "./SupplementModel";
import AccountDetails from "../AccountDetails";
import ServiceAddress from "../ServiceAddress";
import { NewSupplementModel } from "services/apis/types/order/NewSupplementModel";
import { getUtcDate, isSunday } from "services/util/DateUtil";
import classNames from "classnames";
import NpacMaintenanceAlert from "../NpacMaintenanceAlert";
import { OrderHandlerType, OrderHandlerTypesList } from "services/apis/types/port/OrderHandlerType";
import { AppContext } from "services/appContext/AppContext";
import { isNumber, isValidZipCode } from "services/util/StringUtil";
import { AddError, Errors, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { CustomerDetailsDto } from "services/apis/types/port/CustomerDetailsDto";
import { PortInAccountModel } from "../types/PortInAccountModel";
import Datetime, { TimeConstraints } from 'react-datetime';
import { ServiceAddressDto } from "services/apis/types/port/ServiceAddressDto";
import { useEffect } from 'react';
import Select from 'react-select';
import { userZoneInfos } from "components/manage/user/UserTimeZones";

type Props = {
  order: OrderDto;
  closeModal: () => void;
  onActionExecuted: () => void;
};

const timeConstraints: TimeConstraints = {
  hours: { min: 0, max: 23, step: 1 },
  minutes: { min: 0, max: 59, step: 10 }, // Set step to 10 for 10-minute intervals
  seconds: { min: 0, max: 59, step: 0 },
};

const calculateInitialTime = (value) => {
  const now = moment(value);
  const roundedMinutes = Math.ceil(now.minutes() / 10) * 10;
  return now.minutes(roundedMinutes).seconds(0);
}

export default function AddSupplementModal(props: Props) {
  const { appContext } = useContext(AppContext);
  const isVpop = useState<boolean>(() => appContext.localStorageInfo.selectedProfile?.external ?? false);
  const { setErrors, getErrorHandler } = useErrors();
  const [supplementModel, setSupplementModel] = useState<SupplementModel>({
    ...DefaultSupplementModel,
    dueDate: getUtcDate(props.order.focDate),
    orderHandler: props.order.metadata.handledBy as OrderHandlerType,
    customerDetails: props.order.customerDetails
  });

  useEffect(() => {
    if(props.order.supplementVersion > 0 || (props.order.supplementVersion === 0 && props.order.focDate === null)){
    setSupplementModel((prevModel) => ({
      ...prevModel,
      dueDate: moment(props.order.desiredDueDate),
    }))
  }
  }, [props.order.desiredDueDate, props.order.focDate]);

  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const intl = useIntl();
  const isMounted = useIsMounted();
  const [supplementOptions] = useState(() => isVpop
    ? SupplementTypesList.filter(s => s.value !== SupplementType.OrderHandler)
    : SupplementTypesList
  );
  const [orderHandlerOptions] = useState(OrderHandlerTypesList);
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(
    appContext.localStorageInfo.user?.zoneInfo || 'UTC'
  );
  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption.value);
  };
  const timezoneOptions = userZoneInfos.map((zone) => ({
    value: zone.value,
    label: zone.key,
  }));
  
  const [selectedTime, setSelectedTime] = useState<Moment | null>(
    props.order?.autoActivation ? calculateInitialTime(props.order?.autoActivation) : moment()
  );
  const handleTimeChange = (value) => {
    setSelectedTime(value);
  };

  const handleOrderConfirmation = () => {
    const model: NewSupplementModel = getNewSupplementModel(supplementModel, selectedTime, selectedTimeZone, props.order );
    
    OrderApi.addSupplement(props.order.orderId, props.order.tenant, model)
      .then(() => {
        showInfoNotification(
          intl.formatMessage({
            id: "orders.supplement.successNotificationMessage"
          })
        );
        showConfirmationModal && setShowConfirmationModal(false);
        props.closeModal();
        props.onActionExecuted();
      })
      .catch((error) => {
        handleFieldOrApiErrors(error);
        if (isMounted.current) {
          const errorsResult = getFieldErrors(error.fieldErrors);
          setErrors(errorsResult);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  }

  const handleCancelOrder = () => {
    setShowLoading(true);
    handleOrderConfirmation();      
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const errors = validateAccountDetails(supplementModel);
    if (supplementModel.supplementType === SupplementType.Cancel) {
      setShowConfirmationModal(true);
      return;
    }
    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowLoadingIndicator(true);      
      handleOrderConfirmation();
    }
  };

  return (
  <>
    <Modal
      size={supplementModel.supplementType === SupplementType.Other ? "lg" : "sm"}
      className={classNames("modal-dialog-centered", {
        "pc-modal-xlg": supplementModel.supplementType === SupplementType.Other
      })}
      isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id="orders.supplement.title" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          <div className="d-flex">
            <DropdownFormInput
              className="flex-fill"
              required
              labelTranslationId="orders.supplement.supplementType"
              value={supplementModel.supplementType as string}
              options={supplementOptions}
              handleInputChange={(value: string) =>
                setSupplementModel({
                  ...supplementModel,
                  supplementType: value as SupplementType
                })
              }
              errorHandler={getErrorHandler(nameOf<SupplementModel>("supplementType"))}
            />
          </div>
          {supplementModel.supplementType === SupplementType.DueDate && (
            <div>
              <div className="d-flex">
                <DateTimeFormInput
                  className="flex-fill"
                  labelTranslationId="orders.supplement.dueDate"
                  value={moment(supplementModel.dueDate)}
                  handleInputChange={(value: Moment) =>
                    setSupplementModel({
                      ...supplementModel,
                    dueDate: moment(value)
                    })
                  }
                  errorHandler={getErrorHandler(
                    nameOf<PortInModify, ModifyModel>("portInModifyModel", "dueDate")
                  )}
                />
              </div>
              {props.order.autoActivation && (
              <>
              <div className="d-flex flex-column">
                <h4 className="add-supplement-modal-section mr-3">
                  <FormattedMessage id="orders.portIn.actions.addSupplement.autoActivationTime"></FormattedMessage>
                </h4>
                <Datetime
                  onChange={handleTimeChange}
                  value={selectedTime || undefined}
                  input={true}
                  dateFormat={false}
                  timeConstraints= {timeConstraints}
                />
              </div>
              <div className="mt-3">
                <h4 className="add-supplement-modal-section mr-3">
                  <FormattedMessage id="orders.portIn.actions.addSupplement.timeZone"></FormattedMessage>
                </h4>
                <Select
                  id="timeZone"
                  name="timeZone"
                  value={{ value: selectedTimeZone, label: selectedTimeZone }}
                  onChange={handleTimeZoneChange}
                  options={timezoneOptions}
                  className="time-zone-select"
                  styles={{
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      padding: 0,
                    }),
                  }}
                />
              </div>
              </>
              )}
            </div>
          )}
          {supplementModel.supplementType === SupplementType.OrderHandler && (
            <div className="d-flex">
              <DropdownFormInput
                className="flex-fill"
                required
                labelTranslationId="orders.supplement.orderHandler"
                value={supplementModel.orderHandler as string}
                options={orderHandlerOptions}
                handleInputChange={(value: string) =>
                  setSupplementModel({
                    ...supplementModel,
                    orderHandler: value as OrderHandlerType
                  })
                }
              />
            </div>
          )}
          {supplementModel.supplementType === SupplementType.Other && (
            <>
              <div className="d-flex">
                <div className="add-supplement-modal-section mr-3">
                  <AccountDetails
                    customerDetails={supplementModel.customerDetails}
                    setCustomerDetails={(customerDetails) =>
                      setSupplementModel({ ...supplementModel, customerDetails: customerDetails })
                    }
                    getErrorHandler={getErrorHandler}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="add-supplement-modal-section mr-3">
                  <ServiceAddress
                    serviceAddress={supplementModel.customerDetails.serviceAddress}
                    setServiceAddress={(serviceAddress) =>
                      setSupplementModel({
                        ...supplementModel,
                        customerDetails: {
                          ...supplementModel.customerDetails,
                          serviceAddress: serviceAddress
                        }
                      })
                    }
                    getErrorHandler={getErrorHandler}
                  />
                </div>
              </div>
            </>
          )}
          {(supplementModel.supplementType === SupplementType.DueDate && isSunday(supplementModel.dueDate)) && (
            <div className="d-flex">
              <NpacMaintenanceAlert classNames="pc-npac-maintenance-warning" />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={props.closeModal}>
            <FormattedMessage id="orders.modify.edit.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className="ml-auto" disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="orders.modify.edit.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
    {showConfirmationModal && (
      <Modal className="modal-dialog-centered modal-md" isOpen={showConfirmationModal} centered backdrop="static">
        <div className="modal-header pb-0">
          <h6 className="modal-title">Confirm Action</h6>
          <CloseButton close={() => setShowConfirmationModal(false)} />
        </div>
        <div className="modal-body pb-0">
          <p>Are you sure you want to cancel this order?</p>
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={() => setShowConfirmationModal(false)}>
            <FormattedMessage id="confirmationModal.cancelButton" />
          </Button>
          <Button color="danger" type="button" className="ml-auto"  onClick={handleCancelOrder}>
            {showLoading && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="orders.orderDetails.portingNumber.table.confirmAction" />
          </Button>
        </div>
      </Modal>
    )}
  </>    
  );  
}

const autoActivationDate = (time, record: NewSupplementModel): string => {
  const formattedDate = moment.utc(record.dueDate).format('YYYY-MM-DD');
  const formattedTime = moment(time, 'hh:mm A').format('HH:mm:ss');
  const combinedDateTime = `${formattedDate}T${formattedTime}`;
  return combinedDateTime;
};

// map view model to api model
function getNewSupplementModel(supplementModel: SupplementModel, selectedTime, selectedTimeZone, order: OrderDto ) {
  const model: NewSupplementModel = {
    supplementType: supplementModel.supplementType
  };

  if (model.supplementType === SupplementType.DueDate) {
    model.dueDate = supplementModel.dueDate.toDate();
    if (order.autoActivation) {
      model.autoActivationDate = autoActivationDate(selectedTime,model);
      model.timeZone = selectedTimeZone;
    }
  } else if (model.supplementType === SupplementType.Other) {
    model.customerDetails = supplementModel.customerDetails;
  } else if (model.supplementType === SupplementType.OrderHandler) {
    model.orderHandler = supplementModel.orderHandler;
  }

  return model;
}


const validateAccountDetails = (supplementModel: SupplementModel): Errors => {
  const errors: Errors = {};
  if (supplementModel.supplementType === SupplementType.DueDate && !supplementModel.dueDate) {
    AddError(
      errors,
      nameOf<SupplementModel>("dueDate"),
      "orders.order.csr.modifyDueDate.required"
    );
  }

  if (supplementModel.supplementType === SupplementType.Other && (
    (!supplementModel.customerDetails.accountName))) {
    AddError(errors, nameOf<PortInAccountModel, CustomerDetailsDto>("customerDetails", "accountName"),
      "orders.accountDetails.accountName");

  }
  if (supplementModel.supplementType === SupplementType.Other && (
    (!supplementModel.customerDetails.authorizedName))) {
    AddError(errors, nameOf<PortInAccountModel, CustomerDetailsDto>("customerDetails", "authorizedName"),
      "orders.accountDetails.authorizedName");
  }

  if (supplementModel.customerDetails.serviceAddress.zipCode &&
    !isValidZipCode(supplementModel.customerDetails.serviceAddress.zipCode)) {
    AddError(
      errors,
      nameOf<PortInAccountModel, CustomerDetailsDto, ServiceAddressDto>(
        "customerDetails",
        "serviceAddress",
        "zipCode"
      ),
      "orders.portIn.stepTwo.dueDate.zipCodeError"
    )
  }

  if (supplementModel.supplementType === SupplementType.Other &&
    (!isNumber(supplementModel.customerDetails.billingPhoneNumber) ||
      supplementModel.customerDetails.billingPhoneNumber.length < 10 || supplementModel.customerDetails.billingPhoneNumber.length > 15)) {
    AddError(
      errors,
      nameOf<PortInAccountModel, CustomerDetailsDto>(
        "customerDetails",
        "billingPhoneNumber",
      ),
      "orders.portIn.stepTwo.billingPhoneNumber"
    )
  }

  return errors;
};