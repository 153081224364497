import React, { useState } from "react";
import { NavItem, NavLink, Collapse, Nav } from "reactstrap";
import { useLocation, useHistory } from "react-router";
import classnames from "classnames";

type Props = {
  title: string;
  linkTo: string;
  iconClassName?: string;
  toggleSidenav: () => void;
  closeSidenav: () => void;
  children?: React.ReactNode;
  highlightUrls?: string[];
};

export default function MenuItem(props: Props) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  let location = useLocation();
  let history = useHistory();

  const activeRoute = (routeName: string) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const isHighlighted = () => {
    return props.highlightUrls && props.highlightUrls.findIndex(x => location.pathname.indexOf(x) > -1) > -1;
  };

  const handleClick = (path: string) => {
    history.push(path);
    props.closeSidenav();
  };

  const handleCollapse = (e: any) => {
    e.preventDefault();
    setCollapseOpen(!collapseOpen);
  };

  return (
    <NavItem className="cursor-pointer">
      {props.children ? (
        <>
          <NavLink
            onClick={e => handleCollapse(e)}
            className={classnames({ active: isHighlighted() })}
            data-toggle="collapse"
            aria-expanded={collapseOpen}>
            {props.iconClassName && <i className={props.iconClassName} />}
            <span className="nav-link-text">{props.title}</span>
          </NavLink>
          <Collapse isOpen={collapseOpen}>
            <Nav className="nav-sm flex-column">{props.children}</Nav>
          </Collapse>
        </>
      ) : (
        <NavLink onClick={() => handleClick(props.linkTo)} className={activeRoute(props.linkTo)}>
          {props.iconClassName && <i className={props.iconClassName} />}
          <span className="nav-link-text">{props.title}</span>
        </NavLink>
      )}
    </NavItem>
  );
}
