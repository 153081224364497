import React, { useState, useEffect, useContext } from "react";
import { Card, CardHeader, CardBody, CardFooter, Row } from "reactstrap";
import { LrnResult } from "services/apis/types/lrn/LrnResult";
import { FormattedMessage, useIntl } from "react-intl";
import TextRow from "../common/TextRow";
import FormatDate from "components/framework/date/FormateDate";
import { CSVLink } from "react-csv";
import { LrnApi } from "services/apis/LrnApi";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import ActionDropdown from "../common/ActionDropdown";
import ActionDropdownItem from "../common/ActionDropdownItem";
import LrnDeleteModal from "./LrnDeleteModal";
import Authorize from "components/framework/authorization/Authorize";
import { handleError } from "services/util/ApiUtil";
import { PermissionType } from "services/authorization/PermissionType";
import { AppContext } from "services/appContext/AppContext";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";

type Props = {
    lrn: LrnResult;
};

export default function LrnDetailsCard(props: Props) {
    const intl = useIntl();
    const [numbers, setNumbers] = useState(new Array<string[]>());
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [actionsOpen, setActionsOpen] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { appContext } = useContext(AppContext);
    const [lrnIsAssociatedToUser] = useState(() => {
        const user = appContext.localStorageInfo.user
        const accountSpIds = user?.spIdProfiles.map(p => p.spId);

        const isAssociatedToUser = accountSpIds?.some(spid => spid === props.lrn.spId) ?? false;
        const isAdmin = user?.permissions &&
            (user?.permissions?.some(perm => perm === PermissionType.Admin.toString()) ?? false);

        return isAssociatedToUser || isAdmin;
    });

    const handleExport = () => {
        showInfoNotification(intl.formatMessage({ id: "network.lrn.export.exportNotification" }));
    };

    useEffect(() => {
        let isMounted = true;
        setShowLoadingIndicator(true);
        LrnApi.getNumbers(props.lrn.lrn)
            .then((lrnNumbers) => {
                if (isMounted) {
                    setNumbers(lrnNumbers.numbers.length > 0 ? lrnNumbers.numbers.map((x) => [x]): 
                                    new Array<string[]>(["No numbers assigned to this LRN"]));
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted) {
                    setShowLoadingIndicator(false);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [props.lrn.lrn]);

    return (
        <>
            {props.lrn && (
                <Card>
                    <CardHeader>
                        <Row>
                            <h3 className="mb-0 col-8">{props.lrn.lrn}</h3>
                            <Authorize spidPermissions={[PermissionType.NetworkWrite]}>
                                <ActionDropdown
                                    actionsOpen={actionsOpen}
                                    actionsToggle={() => setActionsOpen((prevState) => !prevState)}>
                                    <ActionDropdownItem
                                        handleAction={() => setShowDeleteModal(true)}
                                        iconClassName="fal fa-trash-alt"
                                        labelId="network.lrn.delete"
                                    />
                                </ActionDropdown>
                            </Authorize>
                            <h5 className="mb-0 col-12">
                                <FormattedMessage id="network.lrn.title" />
                            </h5>
                        </Row>
                    </CardHeader>
                    <CardBody className="text-xs">
                        <table>
                            <tbody>
                                <TextRow name={intl.formatMessage({ id: "network.lrn.spId" })}>
                                    <>{props.lrn.spId}</>
                                </TextRow>
                                <TextRow name={intl.formatMessage({ id: "network.lrn.lrnId" })}>
                                    <>{props.lrn.lrnId}</>
                                </TextRow>
                                <TextRow name={intl.formatMessage({ id: "network.lrn.activatedDate" })}>
                                    <FormatDate utcDate={props.lrn.creationDate} />
                                </TextRow>
                                <TextRow name={intl.formatMessage({ id: "network.lrn.blocks" })}>
                                    <>{props.lrn.blockCount}</>
                                </TextRow>
                                <TextRow name={intl.formatMessage({ id: "network.lrn.lata" })}>
                                    <>{props.lrn.lata}</>
                                </TextRow>
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="text-xs font-weight-bold pr-4">{intl.formatMessage({ id: "network.lrn.quantity" })}</td>
                                    <td className="text-xs font-weight-bold">{props.lrn.numberCount}</td>
                                    <td>
                                        {showLoadingIndicator ? (
                                            <LoadingIndicator small />
                                        ) : lrnIsAssociatedToUser && (
                                            <CSVLink
                                                onClick={() => handleExport()}
                                                data={numbers}
                                                target="_blank"
                                                filename={`lrn-${props.lrn.lrn}.csv`}
                                                className="btn btn-primary btn-sm float-end ml-5">
                                                <FormattedMessage id="network.lrn.export" />
                                            </CSVLink>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </CardFooter>
                </Card>
            )}
            {showDeleteModal && (
                <LrnDeleteModal closeModal={() => setShowDeleteModal(false)} lrn={props.lrn} />
            )}
        </>
    );
}