export enum SvRequestStatus {
  ActionRequired = "ActionRequired",
  Pending = "Pending",
  Authorized = "Authorized",
  Active = "Active",
  Sending = "Sending",
  Conflict = "Conflict",
  Old = "Old",
  Failed = "Failed",
  PartialFailure = "PartialFailure",
  Canceled = "Canceled",
  CancelPending = "CancelPending",
  DisconnectPending = "DisconnectPending",
  ActivatePending = "ActivatePending",
  PreOrderPending = "PreOrderPending",
  ReceivedFoc = "ReceivedFoc",
  Rejected = "Rejected",
  Submitted = "Submitted",
  CSRPending = "CSRPending",
  CSRRequested = "CSRRequested",
  CSRModified = "CSRModified",
  CSRInvalid = "CSRInvalid",
  CSREscalated = "CSREscalated",
  CSRApproved = "CSRApproved",
  CSRReceived = "CSRReceived"
}
