import React, { useState, FormEvent, useEffect, useContext } from "react";
import { Modal, Form, Button, Row, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { Errors, HasErrors, AddError } from "components/framework/errorHandling/ErrorUtil";
import CloseButton from "components/framework/modals/CloseButton";
import { ManageAction } from "../types/ManageAction";
import { PermissionType } from "services/authorization/PermissionType";
import Authorize from "components/framework/authorization/Authorize";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { NotificationModel, DefaultNotificationModel } from "../types/NotificationModel";
import { SubscriptionModel } from "../types/SubscriptionModel";
import { NotificationDto } from "services/apis/types/webhooks/NotificationDto";
import { WebhookApi } from "services/apis/WebhookApi";
import { SubscriptionDto } from "services/apis/types/webhooks/SubscriptionDto";
import { NotificationContext } from "./NotificationList";
import InputError from "components/framework/errorHandling/InputError";
import { NotificationType } from "../types/NotificationType";
import { isEmail } from "services/util/StringUtil";
import { NotificationPermissionType } from "../types/NotificationPermissionType";

type Props = {
  closeModal: () => void;
  notificationId?: string;
  notificationType: NotificationType;
  manageAction: ManageAction;
};

export default function NotificationModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [notificationModel, setNotificationModel] = useState<NotificationModel>(
    props.notificationId
      ? { ...DefaultNotificationModel, id: props.notificationId }
      : DefaultNotificationModel
  );
  const [notificationLoaded, setNotificationLoaded] = useState(props.notificationId ? false : true);
  const [subscriptions, setSubscriptions] = useState<SubscriptionDto[]>();
  const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false);
  const [showSaveLoadingIndicator, setShowSaveLoadingIndicator] = useState(false);
  const notificationContext = useContext(NotificationContext);
  const intl = useIntl();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (props.notificationId) {
      WebhookApi.details(props.notificationId)
        .then((notification) => {
          if (isMounted) {
            setNotificationModel((prev) => getNotificationModelWithDetails(prev, notification));
          }
        })
        .catch((error) => handleError(error))
        .finally(() => {
          if (isMounted) {
            setNotificationLoaded(true);
          }
        });
    }
  }, [isMounted, props.notificationId]);

  useEffect(() => {
    WebhookApi.getSubscriptions()
      .then((subscriptions) => {
        if (isMounted) {
          setSubscriptions(subscriptions);
        }
      })
      .catch((error) => {
        handleError(error);
        props.closeModal();
      });
  }, [isMounted, props]);

  useEffect(() => {
    if (subscriptions && notificationLoaded) {
      setNotificationModel((prev) => getNotificationModelWithSubscriptions(subscriptions, prev));
      setSubscriptionsLoaded(true);
    }
  }, [subscriptions, notificationLoaded]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const errors = validate(notificationModel, props.notificationType);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowSaveLoadingIndicator(true);
      const notificationDto: NotificationDto = {
        name: notificationModel.name,
        uri:
          props.notificationType === NotificationType.Webhook
            ? notificationModel.uri
            : `mailto:${notificationModel.uri}`,
        subscriptions: notificationModel.subscriptions
          .filter((x) => x.checked)
          .map((x) => x.subscriptionDto)
      };

      if (props.manageAction === ManageAction.Add) {
        WebhookApi.create(notificationDto)
          .then(() => {
            showInfoNotification(
              intl.formatMessage({
                id:
                  props.notificationType === NotificationType.Webhook
                    ? "notifications.edit.webhookCreateSuccessNotificationMessage"
                    : "notifications.edit.emailSubscriptionCreateSuccessNotificationMessage"
              }),
              undefined,
              true
            );
            notificationContext.notificationCallback();
            props.closeModal();
          })
          .catch((error) => {
            handleError(error);
            if (isMounted.current) {
              const errorsResult = getFieldErrors(error.fieldErrors);
              setErrors(errorsResult);
            }
          })
          .finally(() => {
            if (isMounted.current) {
              setShowSaveLoadingIndicator(false);
            }
          });
      } else if (props.notificationId) {
        WebhookApi.modify(props.notificationId, notificationDto)
          .then(() => {
            showInfoNotification(
              intl.formatMessage({
                id:
                  props.notificationType === NotificationType.Webhook
                    ? "notifications.edit.webhookModifySuccessNotificationMessage"
                    : "notifications.edit.emailSubscriptionModifySuccessNotificationMessage"
              })
            );
            notificationContext.notificationCallback();
            props.closeModal();
          })
          .catch((error) => {
            handleError(error);
            if (isMounted.current) {
              const errorsResult = getFieldErrors(error.fieldErrors);
              setErrors(errorsResult);
            }
          })
          .finally(() => {
            if (isMounted.current) {
              setShowSaveLoadingIndicator(false);
            }
          });
      }
    }
  };

  const subscriptionToggleHandler = (subscriptionDto: SubscriptionDto, checked: boolean) => {
    setNotificationModel((prevState) => {
      const stateSubscription = prevState.subscriptions.find(
        (x) =>
          x.subscriptionDto.event === subscriptionDto.event &&
          x.subscriptionDto.spId === subscriptionDto.spId
      );
      if (stateSubscription) {
        stateSubscription.checked = checked;
      }
      return { ...prevState };
    });
  };

  const modalTitle = intl.formatMessage({
    id: !props.notificationId
      ? props.notificationType === NotificationType.Webhook
        ? "notifications.edit.webhookCreateModalTitle"
        : "notifications.edit.emailSubscriptionCreateModalTitle"
      : props.notificationType === NotificationType.Webhook
      ? "notifications.edit.webhookModifyModalTitle"
      : "notifications.edit.emailSubscriptionModifyModalTitle"
  });

  return (
    <Authorize
      userPermissions={[
        PermissionType.Admin,
        PermissionType.CompanyAdmin,
        PermissionType.Webhooks
      ]}>
      <Modal className="modal-dialog-centered modal-lg" isOpen={true}>
        <div className="modal-header">
          <h5 className="modal-title">{modalTitle}</h5>
          <CloseButton close={props.closeModal} />
        </div>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <div className="modal-body row">
            {!notificationLoaded || !subscriptionsLoaded ? (
              <LoadingIndicator small />
            ) : (
              <>
                <div className="col-lg-6">
                  <Row>
                    <TextFormInput
                      formGroupClassName="col-lg-12"
                      labelTranslationId="notifications.edit.name"
                      value={notificationModel.name}
                      handleInputChange={(value: string) =>
                        setNotificationModel({
                          ...notificationModel,
                          name: value
                        })
                      }
                      errorHandler={getErrorHandler(nameOf<NotificationDto>("name"))}
                    />
                  </Row>
                  <Row>
                    <TextFormInput
                      formGroupClassName="col-lg-12"
                      labelTranslationId={
                        props.notificationType === NotificationType.Webhook
                          ? "notifications.edit.url"
                          : "notifications.edit.email"
                      }
                      value={notificationModel.uri}
                      handleInputChange={(value: string) =>
                        setNotificationModel({
                          ...notificationModel,
                          uri: value
                        })
                      }
                      errorHandler={getErrorHandler(nameOf<NotificationDto>("uri"))}
                    />
                  </Row>
                </div>
                {notificationModel.subscriptions.length > 0 && (
                  <div className="col-lg-6">
                    {Array.from(
                      new Set(notificationModel.subscriptions.map((x) => x.subscriptionDto.spId))
                    ).map((group) => (
                      <div key={group}>
                        <Label className="form-control-label">{group}</Label><br/>
                        {group !== NotificationPermissionType.general &&
                          notificationModel.subscriptions
                            .filter(
                              (subscriptionModel) => subscriptionModel.subscriptionDto.spId === group
                            )
                            .some(
                              (subscriptionModel) =>
                                subscriptionModel.subscriptionDto.event === NotificationPermissionType.orderNPACFailedRequests ||
                                subscriptionModel.subscriptionDto.event === NotificationPermissionType.newPortOutRequestReceived ||
                                subscriptionModel.subscriptionDto.event === NotificationPermissionType.orderNPACSvUpdated) && (
                          <Label className="form-control-label">
                            <FormattedMessage id="notifications.npac.titile" />
                          </Label>
                        )}
                        {notificationModel.subscriptions
                          .filter(
                            (subscriptionModel) => subscriptionModel.subscriptionDto.spId === group
                          )
                          .map((subscriptionModel) => (
                            <div>
                              {(subscriptionModel.subscriptionDto.event === NotificationPermissionType.orderNPACFailedRequests ||
                                subscriptionModel.subscriptionDto.event === NotificationPermissionType.newPortOutRequestReceived||
                                subscriptionModel.subscriptionDto.event === NotificationPermissionType.orderNPACSvUpdated) &&
                                <CheckboxFormInput
                                  key={subscriptionModel.subscriptionDto.event}
                                  value={subscriptionModel.subscriptionDto.spId.concat(
                                    subscriptionModel.subscriptionDto.event
                                  )}
                                  label={subscriptionModel.subscriptionDto.event}
                                  handleInputChange={(checked: boolean) =>
                                    subscriptionToggleHandler(
                                      subscriptionModel.subscriptionDto,
                                      checked
                                    )
                                  }
                                  checked={subscriptionModel.checked}
                                />
                              }
                            </div>
                          ))}
                        {group !== NotificationPermissionType.general &&
                          <Label className="form-control-label">
                            <FormattedMessage id="notifications.pc.titile" />
                          </Label>
                        }
                        {notificationModel.subscriptions
                          .filter(
                            (subscriptionModel) => subscriptionModel.subscriptionDto.spId === group
                          )
                          .map((subscriptionModel) => (
                            <div>
                              {!(subscriptionModel.subscriptionDto.event === NotificationPermissionType.orderNPACFailedRequests ||
                                subscriptionModel.subscriptionDto.event === NotificationPermissionType.newPortOutRequestReceived||
                                subscriptionModel.subscriptionDto.event === NotificationPermissionType.orderNPACSvUpdated) && 
                                <CheckboxFormInput
                                  key={subscriptionModel.subscriptionDto.event}
                                  value={subscriptionModel.subscriptionDto.spId.concat(
                                    subscriptionModel.subscriptionDto.event
                                  )}
                                  label={subscriptionModel.subscriptionDto.event}
                                  handleInputChange={(checked: boolean) =>
                                    subscriptionToggleHandler(
                                      subscriptionModel.subscriptionDto,
                                      checked
                                    )
                                  }
                                  checked={subscriptionModel.checked}
                                />
                              }
                            </div>
                          ))}
                      </div>
                    ))}
                    <InputError errors={getErrorHandler("events").getErrors()} />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="modal-footer">
            <Button color="link" type="button" onClick={() => props.closeModal()}>
              <FormattedMessage id="notifications.edit.cancelButton" />
            </Button>
            <Button
              color="primary"
              type="submit"
              className="ml-auto"
              disabled={showSaveLoadingIndicator}>
              {showSaveLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="notifications.edit.submitButton" />
            </Button>
          </div>
        </Form>
      </Modal>
    </Authorize>
  );
}

const validate = (notificationModel: NotificationModel, notificationType: NotificationType) => {
  const errors: Errors = {};

  if (!notificationModel.name) {
    AddError(errors, nameOf<NotificationDto>("name"), "notifications.edit.name.requiredError");
  }

  if (!notificationModel.uri) {
    AddError(
      errors,
      nameOf<NotificationDto>("uri"),
      notificationType === NotificationType.Webhook
        ? "notifications.edit.url.requiredError"
        : "notifications.edit.email.requiredError"
    );
  } else if (
    notificationType === NotificationType.EmailSubscription &&
    !isEmail(notificationModel.uri)
  ) {
    AddError(errors, nameOf<NotificationDto>("uri"), "notifications.edit.email.invalid");
  }

  return errors;
};

const getNotificationModelWithDetails = (
  prev: NotificationModel,
  notificationDto: NotificationDto
): NotificationModel => {
  return {
    ...prev,
    name: notificationDto.name,
    uri: notificationDto.uri,
    subscriptions:
      notificationDto.subscriptions?.map((x) => {
        return { subscriptionDto: x, checked: true };
      }) ?? []
  };
};

const getNotificationModelWithSubscriptions = (
  subscriptions: SubscriptionDto[],
  prev: NotificationModel
) => {
  let resultSubscriptions = subscriptions.map(
    (subscription): SubscriptionModel => {
      let stateSubscription = prev.subscriptions.find(
        (x) =>
          x.subscriptionDto.event === subscription.event &&
          x.subscriptionDto.spId === subscription.spId
      );
      return stateSubscription
        ? stateSubscription
        : {
            subscriptionDto: subscription,
            checked: false
          };
    }
  );
  return {
    ...prev,
    subscriptions: resultSubscriptions
  };
};
