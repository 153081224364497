import React, { useState, FormEvent, useEffect, useContext } from "react";
import { Button, Card, CardBody, Form, Container, Row, Col, Alert } from "reactstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { IdentityService, getNewAuthenticationInfo } from "services/identity/IdentityService";
import LoginLayout from "components/framework/loginLayout/LoginLayout";
import LoginHeader from "components/framework/loginLayout/LoginHeader";
import { AppContext } from "services/appContext/AppContext";
import { useErrors } from "services/customHooks/useErrors";
import { AddError, Errors, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { isEmail } from "services/util/StringUtil";
import TextFormInputGroup from "components/framework/forms/TextFormInputGroup";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { IdentifierApi } from "services/apis/identifierApi";
import PolygonLoader from "components/common/PolygonLoader";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

interface LocationState {
  from: {
      pathname: string;
  };
}

export default function Login() {
  const location = useLocation();
  const { setErrors, getErrorHandler } = useErrors();
  const { appContext } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessageId, setErrorMessageId] = useState("");
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const [paramsId, setParamsId] = useState<any>("");
  const [accessToken, setAccessToken] = useState<any>("");
  const isMounted = useIsMounted();
  const from: LocationState = (location.state || { from: { pathname: '/' } }) as LocationState;
  const pathname = appContext.isLoggedOut ? "" : from.from.pathname ;
  const [hasTokenIdentifier, setHasTokenIdentifier] = useState(false);
  const [hasSsoError, setHasSsoError] = useState(false);


  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const tokenIdentifier = query.get('tokenIdentifier');
    if(tokenIdentifier) {
      setParamsId(tokenIdentifier);
      setHasTokenIdentifier(true);
    }
  }, []);

  useEffect(() => {
    if (appContext.showSessionExpiredError) {
      setShowError(true);
      setErrorMessageId("account.login.sessionExpired");
    }
  }, [appContext]);


  useEffect(() => {
    if (appContext.initialized) {
      replaceUrlMethod();
    }
  }, [appContext.localStorageInfo.hasTokenIdentifier]);


  useEffect(() => {
    if(paramsId && !appContext.initialized) {
      IdentifierApi.getToken(paramsId)
      .then((result : any) => {
        if(result) {
          if(isMounted) {
            setAccessToken(result)
            authentication(result)
            showInfoNotification(result.message)
          }
        }
      })
      .catch((error) => {
        setShowError(true);
        setHasSsoError(true);
        setHasTokenIdentifier(false);
        replaceUrlMethod();
      });
    }
  },[paramsId, isMounted])

  const replaceUrlMethod = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('tokenIdentifier');
    const newSearch = searchParams.toString();
    let newUrl = `${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;
    const hashFragment = window.location.hash;
    if (hashFragment) {
      newUrl += hashFragment;
    } else {
      newUrl += '#/NumberExplorer';
    }
    window.history.replaceState(null, '', newUrl);
  }

  const authentication = (result) => {
    if (result.error) {
      return false;
    }

    AppContextAccessor.setAppContext((appContext) => {
      const authenticationInfo = getNewAuthenticationInfo(
        result.data.authResponse,
        appContext.localStorageInfo.authenticationInfo
      );

      return {
        ...appContext,
        localStorageInfo: {
          ...appContext.localStorageInfo,
          authenticationInfo: {
            ...authenticationInfo,
            authenticated: true,
            username: result.data.username
          },
          hasTokenIdentifier: true
        },
        previousUrl: pathname,
        isLoggedOut: false
      };
    });
    setHasTokenIdentifier(false)
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setHasSsoError(false);
    const errors = validateCredentials(email, password);

    if (HasErrors(errors)) {
      setErrors(errors);
      setShowLoadingIndicator(false);
    } else {
      setShowLoadingIndicator(true);

      const loggedIn = await IdentityService.login(email, password);
      if(loggedIn) {
        AppContextAccessor.setAppContext((appContext) => {
          return {
            ...appContext,
            previousUrl: pathname
          }
        });
      }
      if (!loggedIn) {
        setErrorMessageId("account.login.invalidCredentials");
        setShowLoadingIndicator(false);
        setShowError(true);
      }
    }
  };

  return (
    <>
    { hasTokenIdentifier || appContext.localStorageInfo.hasTokenIdentifier ?
      <>
        <div className="polygon-loader-main">
          <PolygonLoader/>
        </div>
      </> :
      <LoginLayout>
        <LoginHeader title={intl.formatMessage({ id: "account.login.header" })} />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Alert color="warning" isOpen={showError} toggle={() => setShowError(false)}>
                <span className="alert-text ml-1">
                  <strong>
                    {
                      hasSsoError ? <FormattedMessage id="account.login.invalidSsoCredentials" />
                        : <FormattedMessage id={errorMessageId} />
                    }
                  </strong>
                </span>
              </Alert>
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small className="font-weight-bold">
                      <FormattedMessage id="account.login.title" />
                    </small>
                  </div>
                  <Form role="form" onSubmit={handleSubmit}>
                    <TextFormInputGroup
                      icon="fa-envelope"
                      placeholderTranslationId="account.login.email"
                      value={email}
                      handleInputChange={(value: string) => setEmail(value)}
                      errorHandler={getErrorHandler("email")}
                    />
                    <TextFormInputGroup
                      icon="fa-unlock-alt"
                      placeholderTranslationId="account.login.password"
                      type="password"
                      value={password}
                      handleInputChange={(value: string) => setPassword(value)}
                      errorHandler={getErrorHandler("password")}
                    />
                    <div className="text-center">
                      <Button
                        className="mt-4 "
                        color="default"
                        type="submit"
                        disabled={showLoadingIndicator}>
                        {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                        <FormattedMessage id="account.login.signIn" />
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3 justify-content-between px-3">
                <small>
                  <Link to="/ForgotPassword" className="text-light text-xs">
                    <FormattedMessage id="account.login.forgotPassword" />
                  </Link>
                </small>
                {!isMixNetworkUrl &&
                <small>
                  <Link to="/Signup" className="text-light text-xs justify-content-end">
                    <FormattedMessage id="account.login.signup" />
                  </Link>
                </small>}
              </Row>
            </Col>
          </Row>
        </Container>
      </LoginLayout>
     }
    </>
  );
}

const validateCredentials = (email: string, password: string) => {
  const errors: Errors = {};

  if (!email) {
    AddError(errors, "email", "account.login.email.emptyError");
  } else if (!isEmail(email)) {
    AddError(errors, "email", "account.login.email.invalid");
  }
  if (!password) {
    AddError(errors, "password", "account.login.password.emptyError");
  }

  return errors;
};
