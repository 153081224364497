import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import OrderDetailsContainer from "./OrderDetailsContainer";
import { GetAppContext } from "services/appContext/AppContext";
import { useProfileSwitcher } from "services/customHooks/useProfileSwitcher";

export default function OrderDetails() {
  let { id }: any = useParams();
  
  const [appContext] = useState(GetAppContext());
  const getTenant = useCallback(()=> id?.split("-")[0],[id]);
  const needToSwitchProfile = useCallback(()=>appContext?.localStorageInfo?.selectedProfile?.spId !== id ?? false,[appContext, id]);
  const {navigate: switchProfile} = useProfileSwitcher();
  const location = useLocation();

  useEffect(()=> {
    if (needToSwitchProfile()){
      switchProfile(`/Orders/${id}`,getTenant());
    }
  },[location, id, getTenant, needToSwitchProfile, switchProfile]);

  return (
    <>
      <BreadcrumbHeader>
        <Link to={"/Orders"}>
          <FormattedMessage id="orders.title" />
        </Link>
        <span className="text-white">
          <FormattedMessage id="orders.orderDetails.breadcrumb" values={{ id }} />
        </span>
      </BreadcrumbHeader>
      <OrderDetailsContainer orderId={id} />
    </>
  );
}

