import React, { useContext, useState, ChangeEvent, useEffect, useCallback } from "react";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  NavbarBrand,
  Dropdown,
  
  NavItem
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "services/appContext/AppContext";
import { FormattedMessage, useIntl } from "react-intl";
import { IdentityService } from "services/identity/IdentityService";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { SpIdProfileDto } from "services/apis/types/account/SpIdProfileDto";
import NotificationsDropdown from "components/framework/adminLayout/NotificationsDropdown";
import classnames from "classnames";
import Select2Dropdown from "./Select2Dropdown";
import { HubSpotCallToAction } from "components/common/HubSpotCallToAction";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { v4 as uuidv4 } from 'uuid';

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

type Props = {
  toggleSidenav: () => void;
  sidenavOpen: boolean;
};

export default function AdminNavbar(props: Props) {
  const { appContext, setAppContext } = useContext(AppContext);
  const isInOrderDetailsPage = () => {
    // Get the current URL
    const currentUrl = window.location.href;
    // Check if the URL contains "/Orders/" followed by 23 characters
    return /\/Orders\/.{23}/.test(currentUrl);
  };
  const onOrderDetailsPage  = isInOrderDetailsPage();
  const addUniqueIds = (records) => {
    return records.map((record) => {
      // Check if the record already has an ID in local storage
      const storedId = localStorage.getItem(`recordId_${record.spId}_${record.companyId}`);
      const id = storedId || uuidv4();
      // Save the ID in local storage for future reference
      localStorage.setItem(`recordId_${record.spId}_${record.companyId}`, id);

      return { id, ...record };
    });
  };
  const initializeId = (record) => {
    // Retrieve the ID from local storage
    const storedId = localStorage.getItem(`recordId_${record.spId}_${record.companyId}`);
    return storedId || "";
  };
  const recordsWithUniqueIds = appContext.localStorageInfo.user?.spIdProfiles ? addUniqueIds(appContext.localStorageInfo.user?.spIdProfiles) : "";
  const initializingIdwithSelectedSpidProfile: any = appContext.localStorageInfo.selectedProfile ? initializeId(appContext.localStorageInfo.selectedProfile) : "";
  // Call the function to add unique IDs to the records
  const [id, setId] = useState<string>(initializingIdwithSelectedSpidProfile);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (appContext.localStorageInfo.selectedProfile) {
      setId(initializingIdwithSelectedSpidProfile);
    }
  }, [appContext.localStorageInfo.selectedProfile]);

  const dropdownToggle = () => setDropdownOpen((prevState) => !prevState);
  const logout = () => IdentityService.clearAuthenticationInfo();

  const selectSpidHandler = (spidInfo: SpIdProfileDto) => {
    const spIdProfile = appContext.localStorageInfo.user
      ? recordsWithUniqueIds.find((x) => {
        return x.id === spidInfo.id
      })
      : undefined;

    if (spIdProfile) {
      setId(spidInfo.id);
      setAppContext({
        ...appContext,
        localStorageInfo: { ...appContext.localStorageInfo, selectedProfile: spIdProfile }
      });
      showInfoNotification(
        `${intl.formatMessage(
          { id: "adminNavbar.newProfileSelected" },
          { spId: profileText(spIdProfile) }
        )}`
      );
      setDropdownOpen(false);
    }
  };

  const profileText = (profile: SpIdProfileDto) => {
      if (!isMixNetworkUrl){
        return profile.nonSpIdCompany ? profile.name : `${profile.name} (${profile.spId})`;
      }
      else{
        return `${profile.name} (${profile.companyId?.slice(-8)})` 
      }
    }

  const wholeSaleUserSpidText = appContext.localStorageInfo.selectedProfile && !appContext.localStorageInfo.selectedProfile.nonSpIdCompany
  ? `(${appContext.localStorageInfo.selectedProfile.companyId?.slice(-8)})`
  : "";
  
  const userSpidText = appContext.localStorageInfo.selectedProfile && !appContext.localStorageInfo.selectedProfile.nonSpIdCompany
  ? ` (${
      appContext.localStorageInfo.selectedProfile &&
      appContext.localStorageInfo.selectedProfile.spId
    })`
  : "";

  const spIdText = !isMixNetworkUrl ? userSpidText : wholeSaleUserSpidText ;

  const isFreeUser = useCallback(()=> appContext.localStorageInfo.selectedProfile?.external,[ appContext.localStorageInfo.selectedProfile]);
  
  const hubspotParameter = useCallback((parameterName: string)=> {
    const userType = isFreeUser() ? "freeUser" : "paidUser";
    return intl.formatMessage({id: `adminNavBar.hubSpot.callToAction.${userType}.${parameterName}`});
  },[intl, isFreeUser]);

  return (
    <Navbar className="navbar navbar-expand navbar-top navbar-dark pc-nav-border-bottom pc-bg-gradient-primary">
      <Container fluid={true}>
        <Nav className="align-items-center ml-auto ml-md-0 d-xl-none" navbar>
          <NavItem>
            <div
              className={classnames("pr-3 sidenav-toggler sidenav-toggler-dark", {
                active: props.sidenavOpen
              })}
              onClick={props.toggleSidenav}>
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </NavItem>
        </Nav>
        <NavbarBrand to="/" tag={Link} style={{marginRight: isMixNetworkUrl ? 'auto' : '1rem', marginLeft: isMixNetworkUrl ? '0px' : '-7px'}}>
          {!isMixNetworkUrl ? <img
            alt="PortControl"
            src={require("assets/img/pc_color_no_tagline.svg")}
            className="pc-navbar-img"
          /> : 
          <img 
          alt="MixNetwork" 
          src={require("assets/img/MIXLogo_HighRes.png")} 
          className="pc-navbar-img"
          />}
        </NavbarBrand>
        {!isMixNetworkUrl && <HubSpotCallToAction
          portalId={hubspotParameter("portalId")}
          ctaToken={hubspotParameter("ctaToken")}
        />}

        <Nav className="align-items-center ml-auto ml-md-0" navbar>
          <Dropdown nav isOpen={dropdownOpen} toggle={dropdownToggle}>
            <DropdownToggle className="nav-link pr-0 cursor-pointer" color="" tag="a">
              <Media className="align-items-center">
                <Media className="ml-2 d-none d-lg-block">
                <span className="mb-0 text-sm font-weight-bold">
                  {appContext.localStorageInfo.authenticationInfo &&
                    `${appContext.localStorageInfo.authenticationInfo.username} - ${appContext.localStorageInfo.selectedProfile?.name} ${spIdText}`}
                </span>
              </Media>
              <i className="ml-2 fa fa-user fa-lg" /> 
              </Media>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">
                  <FormattedMessage id="adminNavbar.switchProfile" />
                </h6>
              </DropdownItem>
              {appContext.localStorageInfo.user &&
                appContext.localStorageInfo.user.spIdProfiles &&
                appContext.localStorageInfo.user.spIdProfiles.length > 0 && (
                  <div className="dropdown-item">
                    <Select2Dropdown
                      data={
                        recordsWithUniqueIds.map((x) => ({ id: x.id, text: profileText(x) }))
                      }
                      isWholeSaleClientType={isMixNetworkUrl}
                      handleInputChange={(event) => {
                        const selectedOption = recordsWithUniqueIds.find(
                          (x) => x.id === event.target.value
                        );
                        // Ensure a selected option was found before calling selectSpidHandlers
                        if (selectedOption) {
                          selectSpidHandler(selectedOption);
                        }
                      }}
                      value={id}
                      autofocus={true}
                      onOrderDetailsPage={onOrderDetailsPage}
                    />
                  </div>
                )}
              <DropdownItem onClick={() => history.push("/MyProfile")} className="cursor-pointer">
                <i className="ni ni-single-02" />
                <span>
                  <FormattedMessage id="adminNavbar.myProfile" />
                </span>
              </DropdownItem>
              {!isMixNetworkUrl && (
                <DropdownItem className="cursor-pointer" onClick={() => history.push("/Support")}>
                  <i className="ni ni-support-16" />
                    <span>
                      <FormattedMessage id="adminNavbar.support" />
                    </span>
                </DropdownItem>
              )}
              <DropdownItem divider></DropdownItem>
              <DropdownItem onClick={() => logout()} className="cursor-pointer">
                <i className="ni ni-user-run" />
                <span>
                  <FormattedMessage id="adminNavbar.logout" />
                </span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <NotificationsDropdown />
        </Nav>
      </Container>
    </Navbar>
  );
}
