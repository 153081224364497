import { Row, Col, Card, CardHeader, CardTitle, CardBody, Button } from "reactstrap";
import React, { useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { CSVLink } from "react-csv";
import { AppConfiguration } from "AppConfiguration";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { ReportsApi } from "services/apis/ReportsApi";
import { handleError } from "services/util/ApiUtil";
import { DefaultPendingReportsDto, PendingReportsDto } from "services/apis/types/reports/PendingReportsDto";

export default function PendingReports() {
  const [showExportLoadingIndicator, setShowExportLoadingIndicator] = useState(false);
  const [pendingReportsDtos, setpendingReportsDtos] = useState<PendingReportsDto[]>([]);
  const isMounted = useIsMounted();
  const csvRef = useRef<any>();

  const handleExport = () => {
    setShowExportLoadingIndicator(true);

    ReportsApi.getPendingReports()
      .then((result) => {
        if (isMounted.current) {
          setpendingReportsDtos(result);
          csvRef.current.link.click();
        }
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        if (isMounted.current) {
          setShowExportLoadingIndicator(false);
        }
      });
  };
  return (
    <Col md="12">
      <Card>
        <CardHeader>
          <CardTitle tag="h6" className="text-uppercase text-light mb-0">
          </CardTitle>
          <span className="h2 font-weight-bold mb-0">
            <FormattedMessage id="reports.pendingReports.subtitle" />
          </span>
        </CardHeader>
        <CardBody>
          <Row className="pc-flex-end">
            <Col md="4" />
            <Col md="4" />
            <Col md="1" className="offset-2">
              <CSVLink
                ref={csvRef}
                data={pendingReportsDtos && pendingReportsDtos.length > 0 ? pendingReportsDtos : [DefaultPendingReportsDto]}
                target="_blank"
                className="hidden"
                filename={`pending-orders-${moment().format(AppConfiguration.dateFormat)}.csv`}></CSVLink>
              <Button
                color="primary"
                type="button"
                onClick={() => handleExport()}
                className="ml-auto"
                disabled={showExportLoadingIndicator}>
                {showExportLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                <FormattedMessage id="reports.export.downloadButton" />
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}
