import React from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Modal } from "reactstrap";
import CloseButton from "components/framework/modals/CloseButton";
import { useHistory } from "react-router-dom";
import CardSectionHeader from "components/framework/cards/CardSectionHeader";
import TextFormInput from "components/framework/forms/TextFormInput";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import { FormattedMessage } from "react-intl";

export default function SupportModal() {
  const history = useHistory();

  return (
    <Modal className="pc-modal-xlg modal-dialog-centered" isOpen={true}>
      <CloseButton close={() => history.goBack()} />
      <Card className="mb-0">
        <CardHeader className="d-flex">
          <div>
            <img
              alt="PortControl"
              src={require("assets/img/pc_color_no_tagline.svg")}
              className="pc-navbar-new-img"
            />
          </div>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-4">
              <CardSectionHeader labelTranslationId="support.operationsInformation" />
              <div className="mb-3 d-flex">
                <div className="flex-grow-1">
                  <div className="d-flex flex-direction-column">
                    <TextFormInput
                      readonly
                      labelTranslationId="support.operationsInformation.businessHours"
                      value={"Monday - Friday (5:00 AM - 5:00 PM PST)"}
                    />
                  </div>
                  <div className="d-flex flex-direction-column">
                    <TextFormInput
                      readonly
                      labelTranslationId="support.operationsInformation.generalSupport"
                      value={"Email: lnp@numhub.com"}
                    />
                    <TextFormInput readonly value={"Phone: 844-4-NUMHUB"} />
                  </div>
                  <div className="d-flex flex-direction-column">
                    <TextAreaFormInput
                      disabled
                      labelTranslationId="support.operationsInformation.afterHourAndHolidays"
                      value={
                        "Leave a detailed voicemail on the 800-PORTING number and a team representative will get back to you within 15 minutes. "
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <CardSectionHeader labelTranslationId="support.contacts" />
              <div className="mb-3 d-flex">
                <div className="flex-grow-1">
                  <div className="d-flex flex-direction-column">
                    <TextFormInput
                      readonly
                      labelTranslationId="support.contacts.level1"
                      value={"Lea Espy"}
                    />
                    <TextFormInput readonly value={"Email: lespy@numhub.com"} />
                    <TextFormInput readonly value={"Phone: 541-593-7054"} />
                  </div>
                  <div className="d-flex flex-direction-column">
                    <TextFormInput
                      readonly
                      labelTranslationId="support.contacts.level2"
                      value={"Sara Hutchinson"}
                    />
                    <TextFormInput readonly value={"Email: shutchinson@numhub.com"} />
                    <TextFormInput readonly value={"Phone: 541-598-2329"} />
                  </div>
                  <div className="d-flex flex-direction-column">
                    <TextFormInput
                      readonly
                      labelTranslationId="support.contacts.level3"
                      value={"Mark Bilton-Smith"}
                    />
                    <TextFormInput readonly value={"Email: mbiltonsmith@numhub.com"} />
                    <TextFormInput readonly value={"Phone: 541-598-2300"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-direction-column mb-3 pc-column-without-header">
                <TextFormInput
                  readonly
                  labelTranslationId="support.contacts.closedForTheFollowingDates"
                  value={"New Year’s Day"}
                />
                <TextFormInput readonly value={"Memorial Day"} />
                <TextFormInput readonly value={"Independence Day"} />
                <TextFormInput readonly value={"Labor Day"} />
                <TextFormInput readonly value={"Thanksgiving Day"} />
                <TextFormInput readonly value={"Friday after Thanksgiving"} />
                <TextFormInput readonly value={"Christmas Eve"} />
                <TextFormInput readonly value={"Christmas Day"} />
                <TextFormInput readonly value={"New Year’s Eve"} />
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            type="button"
            onClick={() =>
              window.open(
                "https://atlc.zendesk.com/hc/en-us/categories/360002136213-Local-Number-Porting-Management",
                "_blank"
              )
            }>
            <i className="ni ni-support-16" />
            <span>
              <FormattedMessage id="adminNavbar.support" />
            </span>
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  );
}
