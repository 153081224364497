import React from "react";
import LoginNavbar from "./LoginNavbar";
import Footer from "components/framework/footer/Footer";

type Props = {
  children: React.ReactNode;
};

export default function LoginLayout(props: Props) {
  return (
    <div className="main-content">
      <LoginNavbar />
      {props.children}
      <Footer />
    </div>
  );
}
