import React, { useMemo, useState, useEffect, useContext } from "react";
import { useIntl, IntlShape } from "react-intl";
import { handleError } from "services/util/ApiUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import SpIdCell from "components/common/SpIdCell";
import { Link } from "react-router-dom";
import { useIsMounted } from "services/customHooks/useIsMounted";
import ReactTable from "components/framework/table/ReactTable";
import { getValueOrEmpty } from "services/util/StringUtil";
import { AuditApi } from "services/apis/AuditApi";
import { NpacAuditListDto } from "services/apis/types/audit/NpacAuditListDto";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { AppContext } from "services/appContext/AppContext";
import { getFormattedUtcDate } from "services/util/DateUtil";

type Props = {
  triggerRefresh: boolean;
  searchValue: string;
};

export default function AuditTable(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [audits, setAudits] = useState<NpacAuditListDto[]>();
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), [intl]);
  const isMounted = useIsMounted();
  const { appContext } = useContext(AppContext);

  useEffect(() => {
    setShowLoadingIndicator(true);

    AuditApi.list(props.searchValue)
      .then((result) => {
        if (isMounted) {
          setAudits(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
  }, [
    isMounted,
    props.triggerRefresh,
    props.searchValue,
    appContext.localStorageInfo.selectedProfile
  ]);

  return showLoadingIndicator ? (
    <LoadingIndicator white />
  ) : (
    <ReactTable
      title={intl.formatMessage({ id: "audits.table.title" })}
      data={audits}
      columns={columns}
      showExport={true}
      filterMapper={filterMapper}
    />
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "audits.table.name" }),
    accessor: "name",
    Cell: (cell: any) => (
      <Link to={`/Audits/${cell.cell.row.original.id}`} className="text-underline">
        {cell.cell.value}
      </Link>
    )
  },
  {
    Header: intl.formatMessage({ id: "audits.table.range" }),
    accessor: "phoneNumberRange",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "audits.table.date" }),
    accessor: "requestedDate",
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
  },
  {
    Header: intl.formatMessage({ id: "audits.table.status" }),
    accessor: "status",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "audits.table.tenant" }),
    accessor: "tenant",
    Cell: (cell: any) => <SpIdCell spId={cell.cell.value} />
  }
];

const filterMapper = (audit: NpacAuditListDto) => {
  return {
    ...audit,
    requestedDate: getFormattedUtcDate(audit.requestedDate)
  };
};
