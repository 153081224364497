import React from "react";
import { FormattedMessage } from "react-intl";
import { TabPane, CardBody, Row, Col, Button } from "reactstrap";
import { PortInAccountModel } from "../../types/PortInAccountModel";
import PortInCard from "./PortInCard";
import PortInOverview from "./PortInOverview";
import ErrorList from "components/framework/errorHandling/ErrorList";
import CardSectionHeader from "components/framework/cards/CardSectionHeader";
import { DocumentModel } from "components/orders/types/DocumentModel";
import { OrderCardModel } from "../../types/OrderCardModel";
import { SpidLogo } from "services/apis/types/port/SpidLogo";
import { ApiError } from "components/common/types/ApiError";
import { OrderRequestType } from "services/apis/types/port/OrderRequestType";
import { PortProposalDto } from "services/apis/types/port/PortProposalDto";

type Props = {
  isDelete: () => void;
  orderRequestType: OrderRequestType;
  spidLogos: SpidLogo[];
  tabId: string;
  showCustomerDetails: boolean;
  showLoadingIndicator: boolean;
  createAllSelected: boolean | undefined;
  showStatusInOrderCards: boolean;
  showOrderHandler: boolean;
  overviewModel: PortInAccountModel;
  addDocument: (document: DocumentModel) => void;
  deleteDocument: (document: DocumentModel) => void;
  setOverviewModel: (overviewModel: PortInAccountModel) => void;
  setOrderCards: (orderCards: OrderCardModel[]) => void;
  showApplyAll: boolean;
  applyAll: (portInAccount: PortInAccountModel) => void;
  createIndividual: () => void;
  createAll: () => void;
  createFromSingleTab: () => void;
  disabled: boolean;
  portProposals: PortProposalDto | undefined;
};

export default function PortInAccountTab(props: Props) {
  const { overviewModel: {csrRequested} } = props;
  
  return (
    <>
      <TabPane tabId={props.tabId}>
        <CardBody>
          {props.overviewModel.apiErrors && props.overviewModel.apiErrors.length > 0 && (
            <Row className="mb-3">
              <Col lg="12">
                <ErrorList 
                  errors={props.overviewModel.apiErrors}
                  onDismiss={(error)=> props.setOverviewModel({
                    ...props.overviewModel,
                    apiErrors: [...props.overviewModel.apiErrors?.filter(e=> e.message !== error.message) as ApiError[]] 
                  })} />
              </Col>
            </Row>
          )}
          <PortInOverview
            orderRequestType={props.orderRequestType}
            documents={
              props.overviewModel.orderCards.length > 0
                ? props.overviewModel.orderCards[0].documents
                : []
            }
            addDocument={props.addDocument}
            deleteDocument={props.deleteDocument}
            showCustomerDetails={props.showCustomerDetails}
            showLoadingIndicator={props.showLoadingIndicator}
            overviewModel={props.overviewModel}
            applyAll={props.applyAll}
            showApplyAll={props.showApplyAll}
            setPortInOverviewModel={props.setOverviewModel}
            disabled={props.disabled}
            portProposals={props.portProposals}
          />

          <CardSectionHeader labelTranslationId="orders.portIn.stepTwo.portingNumbers" />

          <Row>
            {props.overviewModel.orderCards.map((orderCard) => (
              <Col
                md={6}
                lg={5}
                xl={3}
                key={`${orderCard.loosingSpid}-${orderCard.lata}-${orderCard.lrn}-${orderCard.portToOrginal}`}>
                <PortInCard
                  isDelete={props.isDelete}
                  orderRequestType={props.orderRequestType}
                  spidLogo={
                    props.spidLogos.find((spidLogo) => spidLogo.spid === orderCard.loosingSpid)
                      ?.logo
                  }
                  showStatusIcon={props.showStatusInOrderCards}
                  showOrderHandler={props.showOrderHandler}
                  orderCard={orderCard}
                  activeAccountOrderCards={props.overviewModel.orderCards}
                  setOrderCards={props.setOrderCards}
                  csrRequested={csrRequested}
                  disabled={props.disabled}
                />
              </Col>
            ))}

          </Row>
          <div className="py-5">
          <Row className="float-right pc-btn-card">
              <Col lg="12">
                { !props.showApplyAll ?   
                <Button
                    color="primary"
                    onClick={props.createFromSingleTab}
                    disabled={
                      props.createAllSelected === false ||
                      props.overviewModel.hasErrors ||
                      props.showLoadingIndicator ||
                      props.overviewModel.orderCards.every((x) => x.orderId)
                    }>
                    {props.showLoadingIndicator && props.createAllSelected === true && (
                      <i className="fas fa-spinner fa-spin mr-2" />
                    )}
                    <FormattedMessage id="orders.portIn.stepTwo.createFromSingleTab" />
                </Button> : null}                
              </Col>
            </Row>
            <Row className="float-right pc-btn-card">
              <Col lg="12">
                { props.showApplyAll ?   
                <Button
                    color="primary"
                    onClick={props.createAll}
                    disabled={
                      props.createAllSelected === false ||
                      props.overviewModel.hasErrors ||
                      props.showLoadingIndicator ||
                      props.overviewModel.orderCards.every((x) => x.orderId)
                    }>
                    {props.showLoadingIndicator && props.createAllSelected === true && (
                      <i className="fas fa-spinner fa-spin mr-2" />
                    )}
                    <FormattedMessage id="orders.portIn.stepTwo.createAll" />
                </Button> : null}                
              </Col>
            </Row>
            <Row className="float-right pc-btn-card">
              <Col lg="12">
              { props.showApplyAll ?                  
                <Button
                  className="mr-3 text-primary download-template-btn"
                  onClick={props.createIndividual}
                  hide = {props.showApplyAll}
                  disabled={
                    props.createAllSelected === true ||
                    props.disabled ||
                    props.overviewModel.hasErrors ||
                    props.showLoadingIndicator ||
                    props.overviewModel.orderCards.every((x) => x.orderId)
                  }>
                  {props.showLoadingIndicator && props.createAllSelected === false && (
                    <i className="fas fa-spinner fa-spin mr-2" />
                  )}
                  <FormattedMessage id="orders.portIn.stepTwo.createIndividual" />
                </Button >: null}
              </Col>
            </Row>
          </div>
        </CardBody>
      </TabPane>
    </>   
  );
}


