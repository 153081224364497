import React, { useState, FormEvent, useCallback } from "react";
import { Modal, Form, Button, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { NumberStatusDto } from "services/apis/types/order/NumberStatusDto";
import { useErrors } from "services/customHooks/useErrors";
import { PortOutModify, DefaultPortOutModify } from "../types/PortOutModify";
import { DefaultPortOutModifyModel } from "../types/PortOutModifyModel";
import { Moment } from "moment";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { HasErrors, Errors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { PortApi } from "services/apis/PortApi";
import { Authorized } from "../types/Authorized";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import { ModifyModel } from "services/apis/types/port/ModifyModel";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { PortOutRejectionReason } from "../types/PortOutRejectionReason";
import { isPhoneNumber, isRange } from "services/util/StringUtil";
import { getUtcDate } from "services/util/DateUtil";
import { OrderApi } from "services/apis/OrderApi";
import PhoneNumberInput from "components/framework/forms/PhoneNumberInput";
import { OrderDto } from "services/apis/types/order/OrderDto";

type Props = {
  order: OrderDto;
  numberStatus?: NumberStatusDto;
  closeModal: () => void;
};

export default function PortOutModifyModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [portOutModify, setPortOutModify] = useState<PortOutModify>({
    ...DefaultPortOutModify,
    phoneNumberRange: props.numberStatus?.phoneNumber || DefaultPortOutModify.phoneNumberRange,
    portOutModifyModel: {
      ...DefaultPortOutModifyModel,
      dueDate: props.numberStatus && getUtcDate(props.numberStatus.dueDate),
      phoneNumber: props.numberStatus?.phoneNumber || DefaultPortOutModify.phoneNumberRange
    }
  });
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const closeModal = useCallback(props.closeModal, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const errors = validateModify(portOutModify, props.numberStatus);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowLoadingIndicator(true);

      const model = {
        phoneNumber: portOutModify.portOutModifyModel.phoneNumber,
        modifyType: portOutModify.portOutModifyModel.modifyType,
        portDirection: portOutModify.portOutModifyModel.portDirection,
        dueDate: portOutModify.portOutModifyModel.dueDate
          ? portOutModify.portOutModifyModel.dueDate.toDate()
          : undefined,
        reject: portOutModify.portOutModifyModel.authorized === Authorized.NO,
        conflictCode: portOutModify.portOutModifyModel.rejectionReason,
        orderId: props.order.orderId
      };

      const api = props.numberStatus
        ? PortApi.modify(portOutModify.phoneNumberRange, model)
        : OrderApi.modify(props.order.orderId, props.order.tenant, model);

      api
        .then(() => {
          showInfoNotification(
            intl.formatMessage({
              id: props.numberStatus
                ? "orders.modify.edit.numberSuccessNotificationMessage"
                : "orders.modify.edit.orderSuccessNotificationMessage"
            })
          );
          closeModal();
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered modal-sm" isOpen={true}>
        <div className="modal-header">
          <h5 className="modal-title">
            <FormattedMessage id="orders.modify.edit.title" />
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.closeModal()}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <div className="modal-body">
            <Row>
              {props.numberStatus ? (
                <PhoneNumberInput
                  formGroupClassName="col-lg-12"
                  labelTranslationId="orders.modify.edit.tnRange"
                  placeholderTranslationId="orders.modify.edit.tnRange.placeholder"
                  value={portOutModify.phoneNumberRange}
                  handleInputChange={() => {}}
                  errorHandler={getErrorHandler(nameOf<PortOutModify>("phoneNumberRange"))}
                />
              ) : (
                <TextFormInput
                  formGroupClassName="col-lg-12"
                  labelTranslationId="orders.modify.edit.orderId"
                  value={props.order.orderId}
                  readonly
                />
              )}
            </Row>
            <Row>
              <DateTimeFormInput
                className="col-lg-12"
                labelTranslationId="orders.modify.edit.dueDate"
                value={portOutModify.portOutModifyModel.dueDate}
                handleInputChange={(value: Moment) =>
                  setPortOutModify({
                    ...portOutModify,
                    portOutModifyModel: { ...portOutModify.portOutModifyModel, dueDate: value }
                  })
                }
                errorHandler={getErrorHandler(
                  nameOf<PortOutModify, ModifyModel>("portOutModifyModel", "dueDate")
                )}
              />
            </Row>
            <Row>
              <DateTimeFormInput
                className="col-lg-12"
                labelTranslationId="orders.modify.edit.dueTime"
                value={portOutModify.portOutModifyModel.dueDate}
                isTimeInput
                handleInputChange={(value: Moment) => {
                  setPortOutModify({
                    ...portOutModify,
                    portOutModifyModel: { ...portOutModify.portOutModifyModel, dueDate: value }
                  });
                }}
                errorHandler={getErrorHandler(
                  nameOf<PortOutModify, ModifyModel>("portOutModifyModel", "dueDate")
                )}
              />
            </Row>
            <Row>
              <DropdownFormInput
                className="col-lg-12"
                required
                labelTranslationId="orders.modify.edit.authorized"
                value={portOutModify.portOutModifyModel.authorized}
                handleInputChange={(value: string) =>
                  setPortOutModify({
                    ...portOutModify,
                    portOutModifyModel: { ...portOutModify.portOutModifyModel, authorized: value }
                  })
                }
                errorHandler={getErrorHandler(
                  nameOf<PortOutModify, ModifyModel>("portOutModifyModel", "reject")
                )}
                options={Object.keys(Authorized).map((x) => ({ key: Authorized[x], value: x }))}
              />
            </Row>
            {portOutModify.portOutModifyModel.authorized === Authorized.NO && (
              <Row>
                <DropdownFormInput
                  className="col-lg-12"
                  required
                  labelTranslationId="orders.modify.edit.causeCode"
                  value={portOutModify.portOutModifyModel.rejectionReason}
                  handleInputChange={(value: string) =>
                    setPortOutModify({
                      ...portOutModify,
                      portOutModifyModel: {
                        ...portOutModify.portOutModifyModel,
                        rejectionReason: value
                      }
                    })
                  }
                  errorHandler={getErrorHandler(
                    nameOf<PortOutModify, ModifyModel>("portOutModifyModel", "conflictCode")
                  )}
                  options={Object.keys(PortOutRejectionReason).map((x) => ({
                    key: PortOutRejectionReason[x],
                    value: x
                  }))}
                />
              </Row>
            )}
          </div>
          <div className="modal-footer">
            <Button color="link" type="button" onClick={() => props.closeModal()}>
              <FormattedMessage id="orders.modify.edit.cancelButton" />
            </Button>
            <Button
              color="primary"
              type="submit"
              className="ml-auto"
              disabled={showLoadingIndicator}>
              {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="orders.modify.edit.submitButton" />
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

const validateModify = (modify: PortOutModify, numberStatus?: NumberStatusDto) => {
  const errors: Errors = {};

  if (numberStatus) {
    if (!modify.phoneNumberRange) {
      AddError(
        errors,
        nameOf<PortOutModify>("phoneNumberRange"),
        "orders.modify.edit.tnRange.required"
      );
    } else if (!isPhoneNumber(modify.phoneNumberRange) && !isRange(modify.phoneNumberRange)) {
      AddError(
        errors,
        nameOf<PortOutModify>("phoneNumberRange"),
        "orders.modify.edit.tnRange.invalid"
      );
    }
  }

  return errors;
};
