import React from "react";
import { BreadcrumbItem, Breadcrumb, Container } from "reactstrap";
import { Link } from "react-router-dom";
import FeedbackButton from "components/networkExplorer/common/FeedbackButton";

type Props = {
  children?: any;
};

export default function BreadcrumbHeader(props: Props) {
  return (
    <Container fluid className="text-white py-4">
      <div className="row">
        <div className="col-lg-6 col-7">
          <Breadcrumb className="d-md-inline-block" listClassName="breadcrumb-links breadcrumb-dark">
            <BreadcrumbItem>
              <Link key={"/"} to={"/"}>
                <i className="fas fa-home"></i>
              </Link>
            </BreadcrumbItem>
            {props.children && !props.children.length && <BreadcrumbItem>{props.children}</BreadcrumbItem>}
            {props.children &&
              props.children.length &&
              props.children.map((x: any, i: number) => <BreadcrumbItem key={i}>{x}</BreadcrumbItem>)}
          </Breadcrumb>
        </div>
        <div className="col-lg-6 col-5 text-right">
          <FeedbackButton />
        </div>
      </div>
    </Container>
  );
}
