import React, { useState, FormEvent } from "react";
import { Modal, Form, Button, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { Errors, HasErrors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { RequestAuditModel } from "services/apis/types/audit/RequestAuditModel";
import CloseButton from "components/framework/modals/CloseButton";
import { useHistory } from "react-router-dom";
import {
  RequestQueryModel,
  DefaultRequestQueryModel
} from "services/apis/types/query/RequestQueryModel";
import { QueryApi } from "services/apis/QueryApi";
import PhoneNumberInput from "components/framework/forms/PhoneNumberInput";
import { isValidName, hasNameLengthExceeded } from "services/util/StringUtil";

type Props = {
  closeModal: () => void;
  triggerRefresh?: () => void;
};

export default function CreateQueryModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [query, setQuery] = useState<RequestQueryModel>(DefaultRequestQueryModel);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const history = useHistory();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const errors = validateQuery(query);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowLoadingIndicator(true);

      QueryApi.create(query)
        .then(() => {
          if (props.triggerRefresh) {
            props.triggerRefresh();
          }
          showInfoNotification(intl.formatMessage({ id: "querySv.create.query.success" }));
          props.closeModal();
          history.push("/QuerySV");
        })
        .catch(error => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id="querySv.create.query.title" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={e => handleSubmit(e)}>
        <div className="modal-body">
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-12"
              labelTranslationId="querySv.create.query.name"
              value={query.name}
              handleInputChange={(value: string) => setQuery({ ...query, name: value })}
              errorHandler={getErrorHandler(nameOf<RequestAuditModel>("name"))}
            />
          </Row>
          <Row>
            <PhoneNumberInput
              formGroupClassName="col-lg-12"
              labelTranslationId="querySv.create.query.phoneNumbers"
              placeholderTranslationId="querySv.create.query.phoneNumbers.placeholder"
              value={query.phoneNumberRange}
              handleInputChange={(value: string) => setQuery({ ...query, phoneNumberRange: value })}
              errorHandler={getErrorHandler(nameOf<RequestAuditModel>("phoneNumberRange"))}
            />
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={() => props.closeModal()}>
            <FormattedMessage id="querySv.create.query.close" />
          </Button>
          <Button color="primary" type="submit" className="ml-auto" disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="querySv.create.query.submit" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

const validateQuery = (query: RequestQueryModel) => {
  const errors: Errors = {};

  if (!query.name) {
    AddError(errors, nameOf<RequestQueryModel>("name"), "querySv.create.query.name.emptyError");
  }
  if (query.name && !isValidName(query.name)) {
    AddError(errors, nameOf<RequestAuditModel>("name"), "querySv.create.query.name.invalidName");
  }
  if (query.name && !hasNameLengthExceeded(query.name)) {
    AddError(errors, nameOf<RequestQueryModel>("name"), "querySv.create.query.name.limitExceeded");
  }
  if (!query.phoneNumberRange) {
    AddError(
      errors,
      nameOf<RequestQueryModel>("phoneNumberRange"),
      "querySv.create.query.phoneNumbers.emptyError"
    );
  }

  return errors;
};
