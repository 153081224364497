import React from "react";
import { Card, CardBody, Row, CardTitle, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { OrderTypeConfigurations } from "components/orders/orderDetails/configuration/OrderTypeConfigurations";
import { OrderType } from "services/apis/types/order/OrderType";
import SpIdCell from "components/common/SpIdCell";

type Props = {
  orderType: OrderType
  gainingSpId: string,
  loosingSpId: string
}

export default function OrderPortTypeCard(props: Props) {
  return (
    <Card className="bg-gradient-primary flex-grow-1 ">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-white text-muted mb-0">
              <FormattedMessage id="orders.orderDetails.portType" />
            </CardTitle>
            <span className="h2 font-weight-bold text-white mb-0">
              {props.orderType}
            </span>
          </div>
          <Col className="col-auto">
            <div
              className={`${OrderTypeConfigurations[props.orderType].backgroundColor} shortcut-media avatar rounded-circle`}>
              <i className={`${OrderTypeConfigurations[props.orderType].iconClass}`} />
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-sm">
          <span className="text-white">
            <FormattedMessage id={OrderTypeConfigurations[props.orderType].messageId} values={{
              gainingSpid: <SpIdCell spId={props.gainingSpId} />,
              loosingSpid: <SpIdCell spId={props.loosingSpId} labelColor="bg-warning" />
            }} />
          </span>
        </p>
      </CardBody>
    </Card>
  );
}
