import React from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

export default function Footer() {
  return (
    <footer className="mt-3" id="footer-main">
      <Container fluid>
        <Row>
          <Col xs="12" md="6">
            <div className="copyright text-muted d-flex justify-content-center justify-content-md-start">
             {isMixNetworkUrl ? "Copyright" : "©"} {new Date().getFullYear()}
              <div className="ml-1">
                <FormattedMessage id={isMixNetworkUrl ? "footer.copyright.mixNetwork" : "footer.copyright"}/>
              </div>
              {!isMixNetworkUrl && (
                <Link
                  className="font-weight-bold ml-1"
                  to="/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <FormattedMessage id="footer.portControl" /> {process.env.REACT_APP_VERSION}
              </Link>
              )}
            </div>
          </Col>
          <Col xs="12" md="6">
            <Nav className="nav-footer justify-content-center justify-content-md-end">
              <NavItem>
              <NavLink href={isMixNetworkUrl ? "https://mixnetworks.com/about/" : "https://numhub.com/about"} target="_blank">
                  <FormattedMessage id="footer.aboutus" />
                </NavLink>
              </NavItem>
              <NavItem>
              <NavLink href={isMixNetworkUrl ? "https://mixnetworks.com/privacy-policy/" : "https://numhub.com/privacy-policy"} target="_blank">
                  <FormattedMessage id="footer.privacyPolicy" />
                </NavLink>
              </NavItem>
              {!isMixNetworkUrl && (
                <>
                  <NavItem>
                    <NavLink href="https://numhub.com/dpa" target="_blank">
                      <FormattedMessage id="footer.dpa" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="https://numhub.com/porting-saas-license" target="_blank">
                      <FormattedMessage id="footer.SaaS Agreement" />
                      </NavLink>
                  </NavItem>
                </>
              )}
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
