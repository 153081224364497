import React, { useEffect, useState } from "react";
import { handleError } from "services/util/ApiUtil";
import { useParams, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { AuditApi } from "services/apis/AuditApi";
import { NpacAuditReply, DefaultNpacAuditReply } from "services/apis/types/audit/NpacAuditReply";
import AuditNumbersCard from "./AuditNumbersCard";

export default function AuditDetails() {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);
  const [auditDetails, setAuditDetails] = useState<NpacAuditReply>(DefaultNpacAuditReply);
  let { id }: any = useParams();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (id) {
      AuditApi.details(id)
        .then(result => {
          if (isMounted.current) {
            setAuditDetails(result);
          }
        })
        .catch(error => handleError(error))
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  }, [id, isMounted]);

  return (
    <>
      <BreadcrumbHeader>
        <Link to={"/Audits"}>
          <FormattedMessage id="audits.title" />
        </Link>
        <span>{auditDetails.name}</span>
      </BreadcrumbHeader>
      <Container fluid>
        {showLoadingIndicator ? (
          <LoadingIndicator white />
        ) : (
          <>
            <Row>
              <Col md="12">
                {/* TODO add dynamic class based on status */}
                <Card className="bg-gradient-orange">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-white text-muted mb-0">
                          <FormattedMessage id="orders.orderDetails.status" />
                        </CardTitle>
                        <span className="h2 font-weight-bold text-white mb-0">{auditDetails.status}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-white rounded-circle shadow">
                          {/* TODO add dynamic icon based on status */}
                          <i className="far fa-handshake" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-white">
                        {/* TODO */}
                        {/* <FormattedMessage id={AuditStatusInfo[auditDetails.status].messageId} /> */}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <AuditNumbersCard phoneNumbers={auditDetails.phoneNumbers} />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}
