import React, { useEffect, useState } from "react";
import { handleError } from "services/util/ApiUtil";
import { useParams, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { QueryApi } from "services/apis/QueryApi";
import { NpacQueryResultDto, DefaultNpacQueryResultDto } from "services/apis/types/query/NpacQueryResultDto";
import QueryDetailsTable from "./QueryDetailsTable";

export default function QueryDetails() {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);
  const [queryDetails, setQueryDetails] = useState<NpacQueryResultDto>(DefaultNpacQueryResultDto);
  let { id }: any = useParams();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (id) {
      QueryApi.details(id)
        .then(result => {
          if (isMounted.current) {
            setQueryDetails(result);
          }
        })
        .catch(error => handleError(error))
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  }, [id, isMounted]);

  return (
    <>
      <BreadcrumbHeader>
        <Link to={"/QuerySV"}>
          <FormattedMessage id="querySv.title" />
        </Link>
        <span>{queryDetails.name}</span>
      </BreadcrumbHeader>
      <Container>
        {showLoadingIndicator ? (
          <LoadingIndicator white />
        ) : (
          <>
            <Row></Row>
            <Row>
              <Col md="12">
                <QueryDetailsTable numberDetails={queryDetails.numberDetails} />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}
