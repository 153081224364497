import React from "react";
import { LocalStorageInfo } from "./LocalStorageInfo";
import { AppContextUtil } from "./AppContextUtil";
import { NotificationDto } from "services/apis/types/notification/NotificationDto";

type AppContextWrapper = {
  appContext: AppContext;
  setAppContext: (newAppContext: AppContext) => unknown;
};

export type AppContext = {
  localStorageInfo: LocalStorageInfo;
  notifications?: NotificationDto[];
  initialized: boolean;
  showSessionExpiredError: boolean;
  previousUrl: string,
  isLoggedOut: boolean;
};

export function GetAppContext(): AppContext {
  const info: LocalStorageInfo = AppContextUtil.getFromLocalStorage();

  return {
    localStorageInfo: info,
    initialized: false,
    showSessionExpiredError: false,
    previousUrl: '',
    isLoggedOut: false
  };
}

export const AppContext = React.createContext<AppContextWrapper>({
  appContext: GetAppContext(),
  setAppContext: () => {}
});
