import { DpcSsnInfo, DefaultDpcSsnInfo } from "../block/DpcSsnInfo";
import { OptionalDataInfo, DefaultOptionalDataInfo } from "../number/OptionalDataInfo";
import { SvType } from "../block/SvType";

export type NetworkProfileDto = {
  profileId: string;
  spId: string;
  description: string;
  lrn: string;
  lata: string;
  endUserLocation: string;
  endUserLocationType: string;
  billingId: string;
  svType: keyof typeof SvType;
  networkData: DpcSsnInfo;
  optionalData: OptionalDataInfo;
};

export const DefaultNetworkProfileDto: NetworkProfileDto = {
  profileId: "",
  spId: "",
  lata: "",
  lrn: "",
  endUserLocation: "",
  endUserLocationType: "",
  billingId: "",
  networkData: DefaultDpcSsnInfo,
  optionalData: DefaultOptionalDataInfo,
  description: "",
  svType: SvType.Wireline
};
