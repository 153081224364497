import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import HistoryReportTable from "./HistoryReportTable";
import { NumberApi } from "services/apis/NumberApi";
import { AppContext } from "services/appContext/AppContext";
import { handleError } from "services/util/ApiUtil";
import { PhoneNumberType } from "services/apis/types/number/PhoneNumberType";
import { authorize } from "services/authorization/AuthorizationService";
import CloseButton from "components/framework/modals/CloseButton";
import { PermissionType } from "services/authorization/PermissionType";
import { CnamInfo } from "services/apis/types/number/CnamInfo";
import CnamDetails from "./CnamDetails";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

type Props = {
  showModal: boolean;
  setShowModal: (isOpen: boolean) => void;
  phoneNumber: string;
  phoneNumberType: keyof typeof PhoneNumberType;
};

export default function NumberHistoryModal(props: Props) {
  const [cnam, setCnam] = useState<CnamInfo>();
  const { appContext } = React.useContext(AppContext);
  const [showCnam] = useState(authorize(PermissionType.CNAM));
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const intl = useIntl();

  const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

  useEffect(() => {
    if (props.phoneNumberType !== PhoneNumberType.TollFree && showCnam) {
      let isMounted = true;

      if (showCnam) {
        NumberApi.cnam(props.phoneNumber)
          .then((result) => {
            if (isMounted) {
              setCnam(result);
            }
          })
          .catch((error) => handleError(error));
      }

      return () => {
        isMounted = false;
      };
    }
  }, [props.phoneNumber, props.phoneNumberType, appContext, showCnam, intl, triggerRefresh]);

  return (
    <Modal
      className="modal-dialog-centered"
      size="xl"
      isOpen={props.showModal}
      toggle={() => props.setShowModal(!props.showModal)}>
      <div className="modal-header">
        <Row className="align-items-center col-lg-12">
          <Col className="pl-0" lg="1" md="2">
            <div className="avatar rounded-circle bg-primary">
              <i className="fas fa-phone fa-lg" />
            </div>
          </Col>
          <Col className="pl-0" lg="11" md="11">
            <h3 className="mb-0">{props.phoneNumber}</h3>
            {showCnam ? (
              <>
                {cnam && (
                  <CnamDetails
                    cnam={cnam}
                    phoneNumber={props.phoneNumber}
                    modifyCallback={() => setTriggerRefresh((p) => !p)}
                  />
                )}
              </>
            ) : (
              <span>
                <a href={isMixNetworkUrl ? "mailto:support@mixnetworks.com" : "mailto:lnp@atlc.com"}>
                  <FormattedMessage id="explore.historyReport.table.enableCnam" />
                </a>
              </span>
            )}
          </Col>
        </Row>
        <CloseButton close={() => props.setShowModal(false)} />
      </div>
      {authorize(PermissionType.History) ? (
        <HistoryReportTable
          telephoneNumber={props.phoneNumber}
          phoneNumberType={props.phoneNumberType}
        />
      ) : (
        <div className="ml-3">
          <span>
            <a href={isMixNetworkUrl ? "mailto:support@mixnetworks.com" : "mailto:lnp@atlc.com"}>
              <FormattedMessage id="explore.historyReport.table.enableHistory" />
            </a>
          </span>
        </div>
      )}
    </Modal>
  );
}
