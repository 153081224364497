import React, { useCallback, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { FormattedMessage } from "react-intl";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import PortInValidationSection from "./PortInValidationSection";
import { Link, useLocation } from "react-router-dom";
import PortInProposalSection from "./PortInProposalSection";
import PortInHandlers from "./PortInHandlers";
import { OrderRequestType } from "services/apis/types/port/OrderRequestType";


export default function PortIn() {

  const location = useLocation();
  
  // state, and handler methods used by PortIn
  const {
    // TODO : the following state assessors could be moved to a 'PortInContext' - 
    // as these variables are shared between components
    orderRequestType,
    setOrderRequestType,
    setExcelTemplateAccounts,
    excelTemplateAccounts,
    accountModels,
    showCustomerDetails,
    showLoadingIndicator,
    portProposals,
    spidLogos,
    spidLogosRetrieved,
    setValidationErrorMessages,
    validationErrorMessages,

    validateNumbers,
    fetchSpidLogos,
    checkAndImportAccountFromPreviousOrder,
    discardPreviousData,
  } = PortInHandlers();

  
  // loading spid logos
  useEffect(() => {
    if (portProposals?.orders.length && accountModels?.length > 0) {
      fetchSpidLogos(portProposals);
    }
  }, [accountModels, portProposals, fetchSpidLogos]);


  // in the case when an order has been passed as a parameter to this component
  // convert order/import as a new account
  useEffect(()=> {
    if (location && location?.state && (location?.state as any)["previousOrder"] && excelTemplateAccounts.length === 0) {
      checkAndImportAccountFromPreviousOrder(location);
    }
  },[location, excelTemplateAccounts.length, checkAndImportAccountFromPreviousOrder]);
  

  const showNumberInputAndValidationStep = useCallback(()=> {
    const accountsAndCardsLoaded = accountModels?.some((accountModel) => accountModel?.orderCards?.length > 0);
      return !accountsAndCardsLoaded || !spidLogosRetrieved;
      // eslint-disable-next-line
  },[excelTemplateAccounts.length, accountModels, spidLogosRetrieved]);


  const validateNumbersAsync = async(numberInput: string, orderRequestType:OrderRequestType, isSuppressDuplicateTnValidation:boolean ) => {
    let inputStr = numberInput;
    if(inputStr.includes('\n' || ',')) {
      inputStr = inputStr.replace(/\n/g, ",");
    }
    return validateNumbers(inputStr,orderRequestType, isSuppressDuplicateTnValidation);
  };

  return (
    <>
      <BreadcrumbHeader>
        <Link to={"/Orders"}>
          <FormattedMessage id="orders.title" />
        </Link>
        {showNumberInputAndValidationStep() ? (
          <FormattedMessage id="orders.portIn.title" />
        ) : (
          <>
            <span className="cursor-pointer" onClick={discardPreviousData}>
              <FormattedMessage id="orders.portIn.title" />
            </span>
            <span className="pc-breadcrumb-item">
              <FormattedMessage id="orders.portIn.stepTwo.breadcrumbTitle" />
            </span>
          </>
        )}
      </BreadcrumbHeader>
      <Container fluid>
        <Row className="card-wrapper">
          {showNumberInputAndValidationStep() ? (
            <Col lg="12">
              <PortInValidationSection
                handleSubmit={validateNumbersAsync}
                setValidationErrorMessages={setValidationErrorMessages}
                portProposals={portProposals}
                validationMessages={validationErrorMessages}
                orderRequestType={orderRequestType}
                setOrderRequestType={setOrderRequestType}
                setExcelTemplateAccounts={setExcelTemplateAccounts}
                isShowLoadingIndicator={showLoadingIndicator}
              />
              {showLoadingIndicator && <LoadingIndicator white />}
            </Col>
          ) : (
            <Col lg="12">
              <>
                {accountModels?.length > 0 ? (
                  <PortInProposalSection
                    validationMessages={validationErrorMessages}
                    setValidationErrorMessages={setValidationErrorMessages}
                    spidLogos={spidLogos}
                    accountModels={accountModels}
                    portProposals={portProposals}
                    orderRequestType={orderRequestType}
                    showCustomerDetails={showCustomerDetails}
                    backToStepOne={discardPreviousData}
                  />
                ):<></>}
              </>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}