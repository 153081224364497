import React from "react";
import { Link } from "react-router-dom";

type Props = {
  urlRoutePath: string;
  orderId: string;
  tenant: string;
  value: string;
}

export default function OrderLink(props: Props) {

  return (
    <span className="pc-text-link">
        <Link to={`${props.urlRoutePath}/${props.orderId}`}>  {props.value ? props.value : props.orderId}</Link>
    </span>
  )
}