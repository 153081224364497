import React, { useState } from "react";
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import PortOutCard from "./PortOutCard";
import { PortOutModel } from "components/orders/types/PortOutModel";
import {
  showInfoNotification,
  showErrorNotification
} from "components/framework/notification/NotificationUtil";
import { PortApi } from "services/apis/PortApi";
import { NewOutportModel } from "services/apis/types/port/NewOutportModel";
import { useHistory } from "react-router-dom";
import { handleError, getErrors } from "services/util/ApiUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import ErrorList from "components/framework/errorHandling/ErrorList";
import { ApiError } from "components/common/types/ApiError";
import { ApiErrorType } from "components/common/types/ApiErrorType";

type Props = {
  portOutModel: PortOutModel;
  setPortOutModel: React.Dispatch<React.SetStateAction<PortOutModel>>;
};

export default function PortOutProposalSection(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useIsMounted();
  const [apiErrors, setApiErrors] = useState<ApiError[]>([]);

  const createOrder = async () => {
    setShowLoadingIndicator(true);

    if (props.portOutModel.hasErrors) {
      showErrorNotification(intl.formatMessage({ id: "orders.portIn.stepTwo.hasErrors" }));
      setShowLoadingIndicator(false);
    } else {
      const newOutports: NewOutportModel[] = mapPortOutModelToNewOutportDto(props.portOutModel);

      PortApi.portOut(newOutports)
        .then(() => {
          history.push("/Orders");
          showInfoNotification(
            intl.formatMessage({
              id: "orders.portIn.stepTwo.orderCreatedSuccess"
            })
          );
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            setApiErrors(getErrors(error.fieldErrors).map((error) => {
              return { message: error, type: ApiErrorType.Danger };
            }));
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Card>
      <CardHeader>
        <h5 className="h3 mb-0">
          <FormattedMessage id="orders.portOut.stepTwo.title" />
        </h5>
      </CardHeader>
      <CardBody>
        {apiErrors && apiErrors.length > 0 && (
          <Row className="mb-3">
            <Col lg="12">
              <ErrorList errors={apiErrors} />
            </Col>
          </Row>
        )}
        <Row>
          <PortOutCard portOutModel={props.portOutModel} setPortOutModel={props.setPortOutModel} />
        </Row>
        <Row className="float-right pc-btn-card">
          <Col lg="12">
            <Button color="primary" onClick={createOrder} disabled={showLoadingIndicator}>
              {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="orders.portOut.stepTwo.createButton" />
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

const mapPortOutModelToNewOutportDto = (portOutModel: PortOutModel): NewOutportModel[] => {
  return [
    {
      newSpId: portOutModel.newSpId,
      phoneNumbers: portOutModel.phoneNumbers,
      dueDate: portOutModel.dueDate.toDate(),
      clientReference: portOutModel.clientReference,
      reject: portOutModel.authorized === "NO",
      rejectionReason: portOutModel.rejectionReason,
      svType: "wireline"
    }
  ];
};
