import React, { useState, FormEvent, useCallback, useContext, useEffect } from "react";
import { Modal, Form, Button, Row, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { ManageContext } from "../Manage";
import { UserApi } from "services/apis/UserApi";
import { CompanyInfo } from "services/apis/types/company/CompanyInfo";
import { Claim } from "components/manage/types/Claim";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import PasswordFormInput from "components/framework/forms/PasswordFormInput";
import { AddError, Errors, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { User, DefaultUser } from "components/manage/types/User";
import { ManageAction } from "components/manage/types/ManageAction";
import { ClaimDto } from "services/apis/types/user/ClaimDto";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import CloseButton from "components/framework/modals/CloseButton";
import { userZoneInfos, userLocales } from "components/common/types/UserInfo";
import { Permissions } from "services/authorization/Permissions";
import Authorize from "components/framework/authorization/Authorize";
import { PermissionType } from "services/authorization/PermissionType";
import { authorize } from "services/authorization/AuthorizationService";
import { ClaimTypeUri } from "../types/ClaimTypeUri";
import { ClaimType } from "../types/ClaimType";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { getUniqueElements } from "services/util/ArrayUtil";

type Props = {
  closeModal: () => void;
  availableCompanies: CompanyInfo[];
  user?: User;
  manageAction: ManageAction;
};

export default function UserModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [user, setUser] = useState<User>(props.user ? props.user : DefaultUser);
  const [roleClaims, setRoleClaims] = useState<Claim[]>([]);
  const [claimsLoaded, setClaimsLoaded] = useState(false);
  const [showUserLoadingIndicator, setShowUserLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const manageContext = useContext(ManageContext);
  const appContext = AppContextAccessor.getAppContext();
  const closeModal = useCallback(props.closeModal, []);

  useEffect(() => {
    if (
      props.availableCompanies.length > 0 &&
      props.availableCompanies.findIndex((x) => x.id === user.companyId) === -1
    ) {
      setUser((prevState) => {
        return { ...prevState, companyId: props.availableCompanies[0].id };
      });
    }
  }, [props.availableCompanies, user.companyId]);

  useEffect(() => {
    async function SetClaims() {
      let allAssignableClaims: ClaimDto[] = await UserApi.getAllClaims();
      let userClaims: ClaimDto[] = user.id ? await UserApi.getUserClaims(user.id) : [];

      allAssignableClaims = getUniqueClaimValues(allAssignableClaims);

      let roleClaimsModel = allAssignableClaims
        .filter((x) => x.type === ClaimTypeUri[ClaimType.role])
        .map(
          (x): Claim => ({
            ...x,
            checked: userClaims.findIndex((y) => y.value === x.value) > -1,
            displayName: Permissions[x.value],
            disabled: false
          })
        );

      let loggedInUser = appContext.localStorageInfo.user;
      if (
        !authorize(PermissionType.Admin) &&
        authorize(PermissionType.CompanyAdmin) &&
        loggedInUser
      ) {
        if (loggedInUser.userId === user.id) {
          roleClaimsModel = roleClaimsModel.map((x): Claim => ({ ...x, disabled: true }));
        } else {
          let loggedInUserPermissions = loggedInUser.permissions;
          let loggedInUserSelectedProfilePermissions = appContext.localStorageInfo.selectedProfile
            ? appContext.localStorageInfo.selectedProfile.permissions
            : [];

          let allPermissionsLoggedInUser = loggedInUserPermissions.concat(
            loggedInUserSelectedProfilePermissions
          );

          roleClaimsModel = roleClaimsModel.map(
            (x): Claim => ({
              ...x,
              disabled:
                allPermissionsLoggedInUser.findIndex((y) => y === x.value) === -1 ||
                x.value === PermissionType.Admin ||
                x.value === PermissionType.CompanyAdmin
            })
          );
        }

        setUserCompany(loggedInUser.companyId);
      }

      if (isMounted.current) {
        setRoleClaims(roleClaimsModel);
        setClaimsLoaded(true);
      }
    }

    function setUserCompany(loggedInUserCompanyId: string) {
      if (user.id === "") {
        setUser((prevState) => {
          return { ...prevState, companyId: loggedInUserCompanyId };
        });
      }
    }

    SetClaims();
  }, [
    isMounted,
    props.manageAction,
    props.user,
    setErrors,
    appContext.localStorageInfo.selectedProfile,
    appContext.localStorageInfo.user,
    user.id
  ]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const errors = validate(user);
    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      saveUser();
    }
  };

  const claimToggleHandler = (claimValue: string, checked: boolean) => {
    setRoleClaims((prevState) => {
      const stateClaim = prevState.find((x) => x.value === claimValue);
      if (stateClaim) {
        stateClaim.checked = checked;
      }
      return [...prevState];
    });
  };

  function saveUser() {
    setShowUserLoadingIndicator(true);

    let userDto = getUser(user, roleClaims);

    if (props.manageAction === ManageAction.Add) {
      UserApi.add({ userName: user.email, ...userDto })
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "manage.user.edit.successNotificationMessage" })
          );
          manageContext.userCallback();
          closeModal();
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowUserLoadingIndicator(false);
          }
        });
    } else if (props.manageAction === ManageAction.Modify) {
      UserApi.modify(user.id, userDto)
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "manage.user.edit.successNotificationMessage" })
          );
          manageContext.userCallback();
          closeModal();
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowUserLoadingIndicator(false);
          }
        });
    }
  }

  return (
    <Authorize userPermissions={[PermissionType.Admin, PermissionType.CompanyAdmin]}>
      <Modal className="modal-dialog-centered modal-lg" isOpen={true}>
        <div className="modal-header">
          <h5 className="modal-title">
            {!props.user && <FormattedMessage id="manage.user.edit.createModalTitle" />}
            {props.user && <FormattedMessage id="manage.user.edit.modifyModalTitle" />}
          </h5>
          <CloseButton close={props.closeModal} />
        </div>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <div className="modal-body row">
            <div className="col-lg-8">
              <Row>
                <TextFormInput
                  formGroupClassName="col-lg-6"
                  labelTranslationId="manage.user.edit.email"
                  value={user.email}
                  handleInputChange={(value: string) => setUser({ ...user, email: value })}
                  errorHandler={getErrorHandler(nameOf<User>("email"))}
                />
              </Row>
              <Row>
                <PasswordFormInput
                  className="col-lg-6"
                  labelTranslationId="manage.user.edit.password"
                  value={user.password}
                  handleInputChange={(value: string) => setUser({ ...user, password: value })}
                  errorHandler={getErrorHandler(nameOf<User>("password"))}
                />
                <PasswordFormInput
                  className="col-lg-6"
                  labelTranslationId="manage.user.edit.confirmPassword"
                  value={user.confirmPassword}
                  handleInputChange={(value: string) =>
                    setUser({ ...user, confirmPassword: value })
                  }
                  errorHandler={getErrorHandler(nameOf<User>("confirmPassword"))}
                />
              </Row>
              <Row>
                <TextFormInput
                  formGroupClassName="col-lg-6"
                  labelTranslationId="manage.user.edit.givenName"
                  value={user.givenName}
                  handleInputChange={(value: string) => setUser({ ...user, givenName: value })}
                  errorHandler={getErrorHandler(nameOf<User>("givenName"))}
                />
                <TextFormInput
                  formGroupClassName="col-lg-6"
                  labelTranslationId="manage.user.edit.familyName"
                  value={user.familyName}
                  handleInputChange={(value: string) => setUser({ ...user, familyName: value })}
                  errorHandler={getErrorHandler(nameOf<User>("familyName"))}
                />
              </Row>
              <Row>
                <DropdownFormInput
                  className="col-lg-6"
                  required
                  labelTranslationId="manage.user.edit.zoneInfo"
                  value={user.zoneInfo}
                  handleInputChange={(value: string) => setUser({ ...user, zoneInfo: value })}
                  errorHandler={getErrorHandler(nameOf<User>("zoneInfo"))}
                  options={userZoneInfos}
                />
                <DropdownFormInput
                  className="col-lg-6"
                  required
                  labelTranslationId="manage.user.edit.locale"
                  value={user.locale}
                  handleInputChange={(value: string) => setUser({ ...user, locale: value })}
                  errorHandler={getErrorHandler(nameOf<User>("locale"))}
                  options={userLocales}
                />
              </Row>
              {authorize(PermissionType.Admin) ? (
                <Row>
                  <DropdownFormInput
                    className="col-lg-6"
                    required
                    labelTranslationId="manage.user.edit.companyId"
                    value={user.companyId}
                    handleInputChange={(value: string) => {
                      setUser({ ...user, companyId: value });
                    }}
                    errorHandler={getErrorHandler(nameOf<User>("companyId"))}
                    options={props.availableCompanies.map((x) => {
                      return { key: x.name, value: x.id };
                    })}
                  />
                </Row>
              ) : (
                <></>
              )}
            </div>
            {!claimsLoaded ? (
              <LoadingIndicator small />
            ) : (
              <>
                {roleClaims.length > 0 && (
                  <div className="col-lg-4">
                    {Array.from(new Set(roleClaims.map((x) => x.group))).map((group) => (
                      <div key={group}>
                        <Label className="form-control-label">{group}</Label>
                        {roleClaims
                          .filter((claim) => claim.group === group)
                          .map((claim) => (
                            <CheckboxFormInput
                              key={claim.value}
                              value={claim.value}
                              label={claim.displayName}
                              handleInputChange={(checked: boolean) =>
                                claimToggleHandler(claim.value, checked)
                              }
                              checked={claim.checked}
                              disabled={claim.disabled}
                            />
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="modal-footer">
            <Button color="link" type="button" onClick={() => props.closeModal()}>
              <FormattedMessage id="manage.user.edit.cancelButton" />
            </Button>
            <Button
              color="primary"
              type="submit"
              className="ml-auto"
              disabled={showUserLoadingIndicator}>
              {showUserLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="manage.user.edit.submitButton" />
            </Button>
          </div>
        </Form>
      </Modal>
    </Authorize>
  );
}

function getUser(user: User, roleClaims: Claim[]) {
  return {
    email: user.email,
    password: user.password,
    givenName: user.givenName,
    familyName: user.familyName,
    zoneInfo:
      userZoneInfos.findIndex((x) => x.value === user.zoneInfo) > -1
        ? user.zoneInfo
        : userZoneInfos[0].value,
    locale:
      userLocales.findIndex((x) => x.value === user.locale) > -1
        ? user.locale
        : userLocales[0].value,
    claims: getClaims(user, roleClaims)
  };
}

function getClaims(user: User, roleClaims: Claim[]) {
  let userClaims = roleClaims
    .filter((x) => x.checked)
    .map((x) => {
      return {
        type: x.type,
        value: x.value,
        spId: x.spId,
        group: x.group
      };
    });

  userClaims.push({
    type: ClaimTypeUri[ClaimType.groupsid],
    spId: "",
    group: "",
    value: user.companyId
  });

  if (userClaims.find((x) => x.value === PermissionType.CompanyAdmin)) {
    userClaims.push({
      type: ClaimTypeUri[ClaimType.primarygroupsid],
      spId: "",
      group: "",
      value: user.companyId
    });
  }

  return userClaims;
}

const getUniqueClaimValues = (allAssignableClaims: ClaimDto[]): ClaimDto[] => {
  return getUniqueElements(allAssignableClaims.map((x) => x.value)).map((value) => {
    let claim = allAssignableClaims.find((y) => y.value === value);
    return {
      type: claim ? claim.type : "",
      value: value,
      group: claim ? claim.group : "",
      spId: ""
    };
  });
};

const validate = (user: User) => {
  const errors: Errors = {};

  if (!user.email) {
    AddError(errors, nameOf<User>("email"), "manage.user.edit.email.requiredError");
  }
  if (user.id.length === 0 && !user.password) {
    AddError(errors, nameOf<User>("password"), "manage.user.edit.password.requiredError");
  }
  if (user.id.length === 0 && !user.confirmPassword) {
    AddError(
      errors,
      nameOf<User>("confirmPassword"),
      "manage.user.edit.password.confirmRequiredError"
    );
  }
  if (user.password && user.password !== user.confirmPassword) {
    AddError(
      errors,
      nameOf<User>("confirmPassword"),
      "manage.user.edit.password.passwordMatchError"
    );
    AddError(errors, nameOf<User>("password"), "manage.user.edit.password.passwordMatchError");
  }

  return errors;
};
