import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Button, Row, Col } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { OrderCardModel } from "components/orders/types/OrderCardModel";
import DeleteOrderCardModal from "./DeleteOrderCardModal";
import PortCardHeader from "../PortCardHeader";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { getTranslation } from "translations/TranslationService";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { OrderHandlerType, OrderHandlerTypesList } from "services/apis/types/port/OrderHandlerType";
import PortInCardLrnInput from "./PortInCardLrnInput";
import { NetworkProfileDto } from "services/apis/types/networkProfile/NetworkProfileDto";
import { OrderRequestType } from "services/apis/types/port/OrderRequestType";
import { AppContext } from "services/appContext/AppContext";
import { deepCloneWithCircular } from "services/util/ArrayUtil";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;

type Props = {
  isDelete: () => void;
  orderRequestType: OrderRequestType;
  spidLogo?: string;
  showOrderHandler: boolean;
  csrRequested: boolean;
  orderCard: OrderCardModel;
  activeAccountOrderCards: OrderCardModel[];
  setOrderCards: (orderCards: OrderCardModel[]) => void;
  showStatusIcon: boolean;
  disabled: boolean;
};

export default function PortInCard(props: Props) {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [orderHandlerOptions] = useState(OrderHandlerTypesList);
    const { activeAccountOrderCards, orderCard, setOrderCards } = props;
    const { appContext } = useContext(AppContext);
    const [selectedProfile] = useState(()=> appContext.localStorageInfo.selectedProfile);  
    const [isVpop] = useState(()=> selectedProfile?.external);
    const [isOrderOnly] = useState(()=> props.orderRequestType === OrderRequestType.OrderOnly);
    const [originalCardState, setOriginalCardState] = useState<string>();
    const [cardLoaded, setCardLoaded] = useState(false);
    const getCurrentOrderCard = useCallback(()=> {
        const newOrderCards = activeAccountOrderCards;

        const currentOrder = newOrderCards.find(
            (x) =>
                x.loosingSpid === orderCard.loosingSpid &&
                x.lata === orderCard.lata &&
                x.lrn === orderCard.lrn &&
                x.portToOrginal === orderCard.portToOrginal
        );

        return currentOrder;
    },[activeAccountOrderCards, orderCard]);

    const setOrderHandler = useCallback((value: string) => {
        const newOrderCards = activeAccountOrderCards;
        const currentOrder = getCurrentOrderCard();
        if (currentOrder) {
            currentOrder.orderHandler = value as OrderHandlerType;
        }

        setOrderCards(newOrderCards);
    }, [activeAccountOrderCards, setOrderCards, getCurrentOrderCard]);

    const intl = useIntl();

    const setportToOrginal = (value: boolean) => {
        const newOrderCards = props.activeAccountOrderCards;
        const currentOrder = newOrderCards.find(
            (x) =>
                x.loosingSpid === props.orderCard.loosingSpid &&
                x.lata === props.orderCard.lata &&
                x.lrn === props.orderCard.lrn &&
                x.portToOrginal === props.orderCard.portToOrginal
        );
        if (currentOrder) {
            currentOrder.portToOrginal = value;
        }

        props.setOrderCards(newOrderCards);
    };

    const setSelectedNetworkProfile = (profileId: string) => {
        const newOrderCards = props.activeAccountOrderCards;
        const currentOrder = newOrderCards.find(
            (x) =>
                x.loosingSpid === props.orderCard.loosingSpid &&
                x.lata === props.orderCard.lata &&
                x.lrn === props.orderCard.lrn &&
                x.portToOrginal === props.orderCard.portToOrginal
        );
        if (currentOrder) {
            currentOrder.numbers.forEach((number) => {
                const newProfile = number.availableProfiles.find((x) => x.profileId === profileId);
                if (newProfile) number.selectedProfile = newProfile;
            });
        }

        props.setOrderCards(newOrderCards);
    };

    const handleSelectedNetworkProfileEdit = (editedProfile: NetworkProfileDto) => {
        const newOrderCards = props.activeAccountOrderCards;
        const currentOrder = newOrderCards.find(
            (x) =>
                x.loosingSpid === props.orderCard.loosingSpid &&
                x.lata === props.orderCard.lata &&
                x.lrn === props.orderCard.lrn &&
                x.portToOrginal === props.orderCard.portToOrginal
        );
        if (currentOrder) {
            currentOrder.numbers.forEach((number) => {
                number.selectedProfile = editedProfile;
                const index = number.availableProfiles.findIndex(
                    (x) => x.profileId === editedProfile.profileId
                );
                if (index > -1) {
                    number.availableProfiles[index] = editedProfile;
                }
            });
        }

        props.setOrderCards(newOrderCards);
    };

    const deleteOrder = () => {
        props.setOrderCards(
            props.activeAccountOrderCards.filter(
                (x) =>
                    x.loosingSpid !== props.orderCard.loosingSpid ||
                    (x.lata !== props.orderCard.lata &&
                        x.lrn === props.orderCard.lrn &&
                        x.portToOrginal === props.orderCard.portToOrginal
                    )
            )
        );
    };

  const {csrRequested} = props;
  const canBeCsrRequested = useCallback(()=> 
    [OrderRequestType.CSROnly, OrderRequestType.PreOrderAndOrder].some(o=> o === props.orderRequestType && selectedProfile?.allowCsr), 
    [props.orderRequestType, selectedProfile]
  );
  
  const isCsrRequested = useMemo(()=> csrRequested === true && canBeCsrRequested(), [csrRequested, canBeCsrRequested]);

  const validateAndSelectHandle = useCallback(()=> {
        var currentOrder = getCurrentOrderCard();
        if (currentOrder && currentOrder.orderHandler !== OrderHandlerType.SelectOne) {
            setOrderHandler(currentOrder.orderHandler);
            return;
        }

        // exit if vpop or orderonly order - handler will be previously set as ATL by default
        if (isVpop || isOrderOnly) {
            return;
        }

        if ((isCsrRequested)) {
            setOrderHandler(OrderHandlerType.PortingDotCom);
            return;
        }
        
        setOrderHandler('');
        // eslint-disable-next-line
    },[isCsrRequested]);

    const CircularJSON = require('circular-json');

    //populating initial values
    useEffect(() => {
        const currentCard = getCurrentOrderCard();
        const clonedCard = deepCloneWithCircular(currentCard);
        const serializedValue = CircularJSON.stringify(clonedCard);
        setOriginalCardState(serializedValue);
        validateAndSelectHandle();
        // eslint-disable-next-line
    },[]);

    // checking if state has loaded - if loaded then marking card as 'loaded'
    useEffect(() => {
        const currentCard = getCurrentOrderCard();
        const stateHasNotChanged = CircularJSON.stringify(getCurrentOrderCard()) === originalCardState;
        if ((originalCardState?.length ?? 0) < 10 || stateHasNotChanged) {
            if (stateHasNotChanged) {
                setCardLoaded(true);
            }
            
            return;
        }// eslint-disable-next-line
    },[originalCardState, cardLoaded])


    // once the card has loaded - setting the handler if csr requested has been enabled/disabled
    useEffect(() => {
        if (cardLoaded) {
            setOrderHandler(csrRequested === false ? '' : OrderHandlerType.PortingDotCom);
        }
    },
        // eslint-disable-next-line
        [csrRequested]);


    return (
        <Card>
            {!isMixNetworkUrl && <PortCardHeader
                logo={props.spidLogo}
                orderCardStatus={props.orderCard.status}
                showStatusIcon={props.showStatusIcon}
                united={props.orderCard.united}
                name={props.orderCard.loosingProviderName}
                spId={props.orderCard.loosingSpid}
            />}
            <CardBody>
                <Row>
                    <Col className="d-flex flex-column">
                        {!isMixNetworkUrl && <>
                            {props.orderCard.showNetworkDetails && (
                                <PortInCardLrnInput
                                    orderCard={props.orderCard}
                                    setSelectedProfile={setSelectedNetworkProfile}
                                    handleSelectedProfileEdit={handleSelectedNetworkProfileEdit}
                                    disabled={props.disabled}
                                />
                            )}
                            {props.showOrderHandler && (
                                <DropdownFormInput
                                    labelTranslationId="orders.portIn.stepTwo.orderHandler"
                                    required
                                    value={props.orderCard.orderHandler as string}
                                    options={orderHandlerOptions}
                                    handleInputChange={setOrderHandler}
                                    disabled={isCsrRequested || props.disabled}
                                />
                            )}
                        </>}
                        <TextAreaFormInput
                            value={props.orderCard.numbers.map((x) => x.number).join(", ")}
                            disabled
                            labelTranslationId= {!isMixNetworkUrl ? "orders.portIn.stepTwo.portingNumbers" : ""}
                            rows={5}
                            fixedSize
                        />
                        {!isMixNetworkUrl && <CheckboxFormInput
                            label={getTranslation(intl, "orders.portIn.stepTwo.portToOrginal")}
                            value={`port-to-original${props.orderCard.loosingSpid}-${props.orderCard.lata}-${props.orderCard.lrn}-${props.orderCard.portToOrginal}`}
                            checked={props.orderCard.portToOrginal}
                            handleInputChange={setportToOrginal}
                            disabled={appContext.localStorageInfo.selectedProfile?.external === true || props.disabled}
                        />}
                        {showDeleteModal && (
                            <DeleteOrderCardModal
                                closeModal={() => setShowDeleteModal(false)}
                                deleteOrder={deleteOrder}
                                isDelete={props.isDelete}
                            />
                        )}
                        <Button
                            className="btn-neutral"
                            color="default"
                            onClick={() => setShowDeleteModal(true)}
                            size="sm"
                            disabled={!!props.orderCard.orderId || props.disabled}>
                            <i className="fa fa-trash"></i> <FormattedMessage id="orders.portIn.stepTwo.delete" />
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}