import React, { useMemo, useState, useContext, useCallback, useEffect } from "react";
import { Card, CardBody, Button, CardHeader, Input, Row, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import DropzoneArea from "../DropzoneArea";
import { getUniqueElements } from "services/util/ArrayUtil";
import classNames from "classnames";
import { usePastedNumberFormatter } from "services/customHooks/usePastedNumberFormatter";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { OrderRequestType, OrderRequestTypes } from "services/apis/types/port/OrderRequestType";
import { AppContext } from "services/appContext/AppContext";
import { ExcelTemplateAccount } from "../../types/ExcelTemplateAccount";
import { ApiError } from "components/common/types/ApiError";
import { PortProposalDto } from "services/apis/types/port/PortProposalDto";
import ErrorList from "components/framework/errorHandling/ErrorList";
import { OrderApi } from "services/apis/OrderApi";
import { SvRequestStatus } from "components/orders/types/SvRequestStatus";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

type Props = {
  portProposals: PortProposalDto | undefined;
  setValidationErrorMessages: React.Dispatch<React.SetStateAction<ApiError[]>>;
  validationMessages: ApiError[];
  orderRequestType: OrderRequestType;
  setOrderRequestType: (orderRequestType: OrderRequestType) => void;
  handleSubmit: (numberInputValue: string, orderRequestType: OrderRequestType, isSuppressDuplicateTnValidation: boolean) => void;
  setExcelTemplateAccounts: React.Dispatch<React.SetStateAction<Array<ExcelTemplateAccount>>>;
  isShowLoadingIndicator: boolean;
};

export default function PortInValidationSection(props: Props) {
  const [inputValue, setInputValue] = useState("");
  const [hugeInputValues, setHugeInputValues] = useState(false);
  const [focusedSearchInput, setFocusedSearchInput] = useState(false);
  const { appContext } = useContext(AppContext);
  const [currentStatusValue, setCurrentStatusValue] = useState(true);
  const [ isDisableValidationButton, setIsDisableValidationButton] = useState(false)
  const [isSuppressDuplicateTnValidation, setIsSuppressDuplicateTnValidation] = useState(false);
  const getSelectedProfile = useCallback(()=> appContext.localStorageInfo.selectedProfile,[appContext.localStorageInfo.selectedProfile]);
  const onPaste = usePastedNumberFormatter(inputValue, (formattedValue) =>
    setInputValue(formattedValue)
  );
  const noOfPhoneNumbers = useMemo((): number =>{
    return props.portProposals?.orders?.reduce((prev,curr)=> prev + curr.numbers.length, 0) ?? 0;
  },[props.portProposals]);

  const handleDropzoneResult = (result: string[]) => {
    setInputValue((previousState) =>
      getUniqueElements([...previousState.split(",").filter((x) => x), ...result]).join(",")
    );
  };

  const handleExcelTemplateAccounts = (accounts: Array<ExcelTemplateAccount>) => {
    props.setExcelTemplateAccounts((prev) => [...prev, ...accounts]);
  };

  useEffect(()=> {
    var defaultOrderRequestType = getSelectedProfile()?.nonSpIdCompany 
      ? OrderRequestType.CSROnly : OrderRequestType.OrderOnly;
    props.setOrderRequestType(defaultOrderRequestType);
    // eslint-disable-next-line
  },[])

  const orderTypesList = useCallback(()=> {
    const list = isMixNetworkUrl ? 
    [{key: OrderRequestTypes.PreOrderAndOrder, value: OrderRequestType.PreOrderAndOrder}] : 
    Object.keys(OrderRequestType).map((x) => {
      return {
        key: OrderRequestTypes[x as keyof typeof OrderRequestType],
        value: OrderRequestType[x as keyof typeof OrderRequestType]
      };
    });

    let availableOrderTypes: Array<{key:string; value:OrderRequestType}> = [...list];

    // restricting order types basing on tenant/tenant permissions 
    if (getSelectedProfile()?.nonSpIdCompany && availableOrderTypes?.length > 0) { //nonSpidCompany(wholesale tenant)
      availableOrderTypes = [...availableOrderTypes.filter(ot=> ot.value === OrderRequestType.CSROnly)]; 
    }
    else if (!getSelectedProfile()?.allowCsr && availableOrderTypes?.length > 0){ 
      availableOrderTypes = [...availableOrderTypes.filter(ot=> ot.value !== OrderRequestType.CSROnly)];
    }
    
    return availableOrderTypes;
  },[getSelectedProfile]);

  useEffect(() => {
    if (props.validationMessages.length > 0) {
      if(props.portProposals && props.portProposals.notPortable && props.validationMessages.findIndex(msg => msg.message.includes('is already active in order')) > -1) {
        setIsSuppressDuplicateTnValidation(true)
        const currentStatus = props.portProposals.currentStatus;
        if(currentStatus !==  SvRequestStatus.Old || SvRequestStatus.Active || SvRequestStatus.Canceled) {
         setCurrentStatusValue(true);
        }
        if(props.portProposals.gainingSpidIsExternal || (props.portProposals.gainingSpidIsExternal && currentStatusValue)) {
          setIsDisableValidationButton(true)
        }
      } else {
        setIsSuppressDuplicateTnValidation(false)
      }
    }
    // eslint-disable-next-line
  }, [props.validationMessages, props.portProposals])


  useEffect(() => {
    const hugeInput = inputValue.split(',');
    const hugeInputValue = hugeInput.length > 10000;
    setHugeInputValues(hugeInputValue)
  }, [inputValue])
  
  return (
    <Card>
      <CardHeader>
        <h5 className="h3 mb-0">
          <FormattedMessage id="orders.portIn.stepOne.orderStepOneTitle" />
        </h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg="5">
            <Input
              className={classNames("input-group-merge pc-search-container mb-0 h-180", {
                focused: focusedSearchInput
              })}
              rows="6"
              type="textarea"
              value={inputValue}
              onChange={(e) => setInputValue(e.currentTarget.value)}
              onFocus={() => setFocusedSearchInput(true)}
              onBlur={() => setFocusedSearchInput(false)}
              onPaste={onPaste}
            />
            <label className="form-control-label">
              <FormattedMessage id="orders.portIn.stepOne.enterNumbersMessage" />
            </label>
          </Col>
          <Col lg="2" className="d-flex align-items-center justify-content-center h-180">
            <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue text-uppercase">
              <FormattedMessage id="orders.portIn.stepOne.or" />
            </span>
          </Col>
          <Col lg="5">
            <DropzoneArea
              addNumbersToTextAreaHandler={handleDropzoneResult}
              saveExcelTemplateAccounts={handleExcelTemplateAccounts}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-between mb-2">
          <a
            className="btn text-primary download-template-btn mt-1 align-self-end"
            href={`${process.env.PUBLIC_URL}/CSR-Template.xlsx`}>
            <FormattedMessage id="orders.portIn.stepOne.downloadTemplate" />
          </a>
          <div className="d-flex mt-1 flex-direction-column">
            {!appContext.localStorageInfo.selectedProfile?.external && (
              <DropdownFormInput
                lg
                required
                labelTranslationId="orders.portIn.stepOne.orderRequestType"
                labelClassName="text-right"
                className="d-flex flex-direction-column"
                value={(props.orderRequestType as unknown) as string}
                handleInputChange={(value: string) =>
                  props.setOrderRequestType((value as unknown) as OrderRequestType)
                }
                options={orderTypesList()}
              />
            )}
            <Button color="primary" className="mr-0" disabled={isDisableValidationButton || hugeInputValues || props.isShowLoadingIndicator} onClick={() => props.handleSubmit(inputValue, props.orderRequestType, isSuppressDuplicateTnValidation)}>
              <FormattedMessage id="orders.portIn.stepOne.validateNumbers" />
            </Button>
            {noOfPhoneNumbers > 0 && (
              <label className="form-control-label text-primary text-right">
                <FormattedMessage
                  id="orders.portIn.stepOne.numberDetected"
                  values={{ number: noOfPhoneNumbers }}
                />
              </label>
            )}
          </div>
        </div>
        <div className="d-flex flex-direction-column mx-1 mt-4">
          <ErrorList errors={props.validationMessages} duplicateNumbers={isSuppressDuplicateTnValidation} portProposals={props.portProposals} currentStatusValue = {currentStatusValue}
            onDismiss={(error)=> props.setValidationErrorMessages(()=> [...props.validationMessages?.filter(m=> m.message !== error.message)])}
          /> 
        </div>
      </CardBody>
    </Card>
  );
}

