import translations from "translations";
import { AuthenticationInfo, DefaultAuthenticationInfo } from "./AuthenticationInfo";
import { SpIdProfileDto } from "services/apis/types/account/SpIdProfileDto";
import { LocalStorageUser } from "services/appContext/LocalStorageUser";
import { DefaultOrderDetails, Orders } from "./Orders";
import { DefaultWorkQueueDetails, WorkQueue } from "./WorkQueue";

export type LocalStorageInfo = {
  authenticationInfo: AuthenticationInfo;
  language: keyof typeof translations;
  selectedProfile?: SpIdProfileDto;
  user?: LocalStorageUser;
  orders?: Orders;
  workQueue?: WorkQueue;
  hasTokenIdentifier?: boolean;
};

export const DefaultLocalStorageInfo: LocalStorageInfo = {
  authenticationInfo: DefaultAuthenticationInfo,
  language: "en",
  orders: DefaultOrderDetails,
  workQueue: DefaultWorkQueueDetails,
  hasTokenIdentifier: false
};
