import FormateDate from "components/framework/date/FormateDate";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CardHeader, Tooltip } from "reactstrap";
import { ServiceProviderApi } from "services/apis/ServiceProviderApi";
import { ServiceProviderProfileResult } from "../types/ServiceProviderProfileResult";
import { SvType } from "services/apis/types/block/SvType";
import { authorize } from "services/authorization/AuthorizationService";
import { PermissionType } from "services/authorization/PermissionType";
import { TradingPartnerProfile } from "services/apis/types/tpp/TradingPartnerProfile";
import { isSpid } from "services/util/StringUtil";
import { getUserSpId } from "services/util/SpidUtil";

type Props = {
  serviceProviderResult: ServiceProviderProfileResult;
  triggerRefreshLogo: boolean;
  createMode: boolean;
  readOnlyMode: boolean;
  tpp?: TradingPartnerProfile;
  setReadOnlyMode: (value: boolean) => void;
};

export default function TradingPartnerProfileCardHeader(props: Props) {
  const [defaultIcon, setDefaultIcon] = useState(false);
  const [logo, setLogo] = useState<string>();
  const [verifiedDateTooltipOpen, setVerifiedDateTooltipOpen] = useState(false);
  const [userIsAdmin] = useState(authorize(PermissionType.Admin));

  const [userHasAccess] = useState(authorize(PermissionType.NetworkWrite) &&  (props.serviceProviderResult.spId === getUserSpId()));
  const editMode = !props.readOnlyMode && !props.createMode;

  useEffect(() => {
    if (props.serviceProviderResult.spId) {
      ServiceProviderApi.getLogo(props.serviceProviderResult.spId)
        .then((x) => {
          if (x instanceof Blob) {
            setLogo(URL.createObjectURL(x));
          }
        })
        .catch(() => setDefaultIcon(true));
    } else {
      setDefaultIcon(true);
    }
  }, [props.serviceProviderResult.spId, props.triggerRefreshLogo]);

  return (
    <CardHeader className="d-flex">
      <div className="d-flex flex-basis-33 mr-2">
        <div>
          {defaultIcon ? (
            <div className="mt-2 avatar rounded-circle bg-primary">
              <i className="fas fa-building fa-lg"></i>
            </div>
          ) : (
            <div className="pc-logo mt-2">
              <img src={logo} alt="" onError={() => setDefaultIcon(true)} />
            </div>
          )}
        </div>
        <div className="flex-grow-1 mx-2 align-items-center">
          {props.createMode && (
            <h5 className="modal-title">
              <FormattedMessage id="tppModal.createTradingPartnerProfile" />
            </h5>
          )}
          {!props.createMode && (
            <>
              <div>
                <div className="flex-basis-33 mr-2">
                  <div className="d-flex"></div>
                  <div>
                    {editMode ? (
                      <>
                        <div className="mb-0">
                          <FormattedMessage id="tppModal.modifyTradingPartnerProfile" />
                        </div>
                        <h5 className="mb-0 text-gray">
                          {props.serviceProviderResult.spId} - {props.serviceProviderResult.name}
                        </h5>
                      </>
                    ) : (
                      <>
                        <div className="d-flex align-items-center">
                          <div className="mb-0 mr-1">{props.serviceProviderResult.name}</div>
                          {props.serviceProviderResult.contactInformation?.verifiedDate && (
                            <i className="fa fa-badge-check text-success" id="verifiedDateBadge">
                              <Tooltip
                                placement="right"
                                isOpen={verifiedDateTooltipOpen}
                                target="verifiedDateBadge"
                                toggle={() => setVerifiedDateTooltipOpen(!verifiedDateTooltipOpen)}>
                                <FormattedMessage
                                  id="tppModal.verifiedBadgeLabel"
                                  values={{
                                    date: (
                                      <FormateDate
                                        utcDate={
                                          props.serviceProviderResult.contactInformation?.verifiedDate
                                        }
                                      />
                                    )
                                  }}
                                />
                              </Tooltip>
                            </i>
                          )}
                        </div>
                        <h5 className="mb-0 text-gray">
                          <FormattedMessage
                            id={
                              isSpid(props.serviceProviderResult.spId)
                                ? "tppModal.spid"
                                : "tppModal.respOrg"
                            }
                            values={{ item: props.serviceProviderResult.spId }}
                          />
                        </h5>
                        <h5 className="mb-0 text-gray">
                          {Object.keys(SvType).find((x) => SvType[x] === props.serviceProviderResult.type)}
                        </h5>
                        {props.tpp?.united && (
                          <h5 className="mb-0 text-success">
                            <FormattedMessage id="tppModal.united" />
                          </h5>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex-basis-33 mr-2">
        { props.tpp && props.tpp.status && <div className="flex-basis-33 mr-2 tpp-status">
          <h5 className="ml-3 pt-3 text-white">
            <FormattedMessage id="tppModal.status" />
          </h5>
          <div className="ml-3 text-white">
            {<FormattedMessage id={props.tpp.status}/>}
          </div>
        </div>}
      </div>
      <div className="flex-basis-33 mr-2">
        <div className="mr-2">
          {(userIsAdmin || userHasAccess) && props.readOnlyMode && (
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => props.setReadOnlyMode(false)}>
              <i className="fa fa-xs fa-edit mr-4 cursor-pointer" data-toggle="tooltip" data-placement="top" title="Modify" />
            </button>
          )}
        </div>
      </div>
    </CardHeader>
  );
}
