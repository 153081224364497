export type CompanyDto = {
  parentId: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  primaryContactEmail: string;
  notificationEmail: string;
  billingId: string;
  nonSpId: boolean;
  csrEnabled: boolean;
  tenantId: string;
  clientType:string;
};

export const DefaultCompanyDto = {
  parentId: "",
  name: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipCode: "",
  primaryContactEmail: "",
  notificationEmail: "",
  billingId: "",
  nonSpId: false,
  csrEnabled: false,
  tenantId: "",
  clientType:""
};
