import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { IntlShape, useIntl } from "react-intl";
import { getValueOrEmpty, getYesOrNo } from "services/util/StringUtil";
import { TradingPartner, TradingPartnerAdmin } from "services/apis/types/number/TradingPartner";
import ReactTable from "components/framework/table/ReactTable";
import { TradingPartnerActionCell } from "components/common/actions/TradingPartnerActionCell";
import SpIdCell from "components/common/SpIdCell";
import VerifiedCell from "components/common/VerfiedCell";
import StatusCell from "components/common/StatusCell";
import ValidateCell from "components/common/ValidateCell";
import { authorize } from "services/authorization/AuthorizationService";
import { PermissionType } from "services/authorization/PermissionType";
import { TradingPartnerApi } from "services/apis/TradingPartnerApi";
import { TradingPartnerAdminTableColumnIdentifiers } from "../types/TradingPartnerColumnIdentifier";
import { handleError } from "services/util/ApiUtil";
import { AppContext } from "services/appContext/AppContext";
import DefaultYesNoColumnFilter from "components/framework/table/DefaultYesNoColumnFilter";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { TppListFilter, DefaultTppListFilter } from "services/apis/types/tpp/TppListFilter";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import { TppSpidList } from "services/apis/types/tpp/TppSpidList";
import TppStatusColumnFilter from "components/framework/table/TppStatusColumnFilter";
import TppSpidColumnFilter from "components/framework/table/TppSpidColumnFilter";

type ColumnFilterSort = {
  isSorted?: boolean;
  isSortedDesc?: boolean;
}
type Props = {
  search: string;
};

export default function TradingPartnerAdminTable(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [tradingPartnerDetails, setTradingPartnerDetails] = useState<TradingPartnerAdmin[]>();
  const [userIsAdmin] = useState(authorize(PermissionType.Admin));
  const { appContext } = useContext(AppContext);
  const isMounted = useIsMounted();
  const intl = useIntl();
  const [searchKey, setSearchKey] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<TppListFilter>(DefaultTppListFilter);
  const [isAppStartUp, setIsAppStartUp] = useState(false);
  const [tppSpidList, setTppList] = useState<TppSpidList>();
  const [orderByColumn, setOrderByColumn] = useState("");
  const [orderDirection, setOrderDirection] = useState("");
  const [columnFilterSort, setColumnFilterSort] = useState<{ [key: string]: ColumnFilterSort }>({});

  const getTppSpidList = () => {
    setShowLoadingIndicator(true);
    TradingPartnerApi.getSpidList()
      .then((result) => {
        if (result) {
          setTppList(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
  };

  const queryString = new URLSearchParams(
    Object.fromEntries(
      Object.entries(selectedFilter).filter(([_, v]) => v !== "" && v !== "Any")
    )
  ).toString();

  const resetAllFilters = useCallback(() => {
    if (isMounted.current) {
      setSelectedFilter(DefaultTppListFilter);
    }
  }, [isMounted]);

  const toggleRefresh = useCallback(() => {
    if (isMounted.current) {
      setTriggerRefresh((x) => !x);
    }
  }, [isMounted]);

  const areFiltersmodified = useCallback((): boolean => {
    const queryString = new URLSearchParams(
      Object.fromEntries(Object.entries(selectedFilter).filter(([_, v]) => v !== "" && v !== "Any"))
    ).toString();
    return queryString ? true : false;
  }, [selectedFilter]);

  const updateHiddenColumns = useCallback((columns) => {
    const excludedColumnsForWholesale = [TradingPartnerAdminTableColumnIdentifiers.RequestingSpId];
    if (!userIsAdmin) {
      return columns.filter(column => column.identifier && !excludedColumnsForWholesale.includes(column.identifier))
    };
    return columns;
  }, [userIsAdmin]);

  const columnFilter = useCallback(
    (rows: any[], accessor: any, filterValues: any) => {
      if (accessor[0] === "requestingSpid") {
        if (selectedFilter["tenant"] !== filterValues)
          setSelectedFilter((prevState) => ({
            ...prevState,
            ...{
              tenant: filterValues
            }
          }));
      } else if (accessor[0] === "targetSpid") {
        if (selectedFilter["tradingSpid"] !== filterValues)
          setSelectedFilter((prevState) => ({
            ...prevState,
            ...{
              tradingSpid: filterValues
            }
          }));
      } else if (selectedFilter[accessor[0]] !== filterValues) {
        setSelectedFilter((prevState) => ({
          ...prevState,
          ...{
            [accessor[0]]: filterValues
          }
        }));
      }
      return rows;
    },
    [selectedFilter]
  );

  const getTppAdminList = (
    currentPage: number,
    pageSize: number,
    searchKey: string,
    orderByColumn: string,
    orderDirection: string,
    filterValues?: string
  ) => {
    TradingPartnerApi.getTppAdminList(
      currentPage,
      pageSize,
      searchKey ? searchKey : "",
      orderByColumn,
      orderDirection,
      filterValues ? filterValues : ""
    )
      .then((result) => {
        if (isMounted && result) {
          if (result.rows) {
            setTradingPartnerDetails(result.rows);
            setCount(result.totalRows);
          }
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
  };

  useEffect(() => {
    if (isMounted.current && isAppStartUp) {
      setTradingPartnerDetails(undefined);
      const queryString = new URLSearchParams(
        Object.fromEntries(
          Object.entries(selectedFilter).filter(([_, v]) => v !== "" && v !== "Any")
        )
      ).toString();
      getTppAdminList(pageIndex + 1, pageSize, searchKey ? searchKey : "", orderByColumn, orderDirection, queryString.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, pageIndex, pageSize]);

  useEffect(() => {
    if (isMounted.current && isAppStartUp) {
      setTradingPartnerDetails(undefined);
      if (pageIndex !== 0) setPageIndex(0);
      else {
        const queryString = new URLSearchParams(
          Object.fromEntries(
            Object.entries(selectedFilter).filter(([_, v]) => v !== "" && v !== "Any")
          )
        ).toString();
        getTppAdminList(pageIndex + 1, pageSize, searchKey ? searchKey : "", orderByColumn, orderDirection, queryString.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, selectedFilter]);

  const columns = useMemo(() => {
    const columnsList = getColumns(intl, columnFilter, toggleRefresh, tppSpidList);
    return updateHiddenColumns(columnsList);
  }, [intl, updateHiddenColumns, columnFilter, toggleRefresh, tppSpidList]);

  const handlePageChange = (pageIndex) => {
    setPageIndex(pageIndex);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event);
    setPageIndex(0);
  };

  useEffect(() => {
    if (triggerRefresh) {
      setSelectedFilter(DefaultTppListFilter);
      if (pageIndex !== 0) setPageIndex(0);
      setShowLoadingIndicator(true);
      setTriggerRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, triggerRefresh]);

  useEffect(() => {
    if (isMounted.current) {
      getTppSpidList();
      setShowLoadingIndicator(true);
      setIsAppStartUp(true);
      setSearchKey("");
      if (pageIndex !== 0 || pageSize !== 10 || areFiltersmodified()) {
        if (pageIndex !== 0) setPageIndex(0);
        if (pageSize !== 10) setPageSize(10);
        if (areFiltersmodified()) resetAllFilters();
      } else getTppAdminList(1, 10, "", orderByColumn, orderDirection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, appContext.localStorageInfo.selectedProfile, triggerRefresh]);

  useEffect(() => {
    if (isMounted.current && props.search !== searchKey) {
      resetAllFilters();
      setSearchKey(props.search);
      setShowLoadingIndicator(true);
      setIsAppStartUp(true);
      if (pageIndex !== 0 || pageSize !== 10 || areFiltersmodified()) {
        if (pageIndex !== 0) setPageIndex(0);
        if (pageSize !== 10) setPageSize(10);
        if (areFiltersmodified()) resetAllFilters();
      } else getTppAdminList(1, 10, props.search, orderByColumn, orderDirection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, props.search, searchKey, resetAllFilters]);


  //Handle Event for sorting
  const handleColumnSorted = useCallback(
    (column) => {
      const columnSortOption = columnFilterSort[column.identifier];

      if (!columnSortOption || !columnSortOption.isSorted) {
        // Column is not sorted or in an unsorted state, set it to ascending
        setColumnFilterSort((prevSortOptions) => ({
          ...prevSortOptions,
          [column.identifier]: {
            isSorted: true,
            isSortedDesc: false,
          },
        }));
        setOrderDirection("asc"); // Set order direction
        setOrderByColumn(column.identifier); // Set orderByColumn
        getTppAdminList(pageIndex + 1, pageSize, props.search, column.identifier, "asc", queryString); // Use pageSize
      } else if (columnSortOption.isSorted && !columnSortOption.isSortedDesc) {
        // Column is sorted in ascending order, toggle to descending
        setColumnFilterSort((prevSortOptions) => ({
          ...prevSortOptions,
          [column.identifier]: {
            isSorted: true,
            isSortedDesc: true,
          },
        }));
        setOrderDirection("desc"); // Set order direction
        setOrderByColumn(column.identifier); // Set orderByColumn
        getTppAdminList(pageIndex + 1, pageSize, props.search, column.identifier, "desc", queryString); // Use pageSize
      } else {
        // Column is sorted in descending order, toggle to unsorted
        setColumnFilterSort((prevSortOptions) => ({
          ...prevSortOptions,
          [column.identifier]: {
            isSorted: false,
            isSortedDesc: false,
          },
        }));
        setOrderDirection(""); // Clear order direction
        setOrderByColumn(""); // Clear orderByColumn
        getTppAdminList(pageIndex + 1, pageSize, props.search, "", "", queryString);
      }
    },
    [columnFilterSort, getTppAdminList, pageIndex, pageSize, props.search, queryString]
  );

  return showLoadingIndicator ? (
    <LoadingIndicator white />
  ) : (
    <ReactTable
      titleComponent={getTitleComponent(intl.formatMessage({ id: "tradingPartnersAdmin.table.title" }))}
      identifier={TableIdentifiers.TradingPartner}
      data={tradingPartnerDetails}
      columns={columns}
      showExport={false}
      useColumnFilters
      onRefresh={toggleRefresh}
      hideSearch={true}
      queryCount={count}
      queryPageSize={pageSize}
      queryPageIndex={pageIndex}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      handleResetFilterChange={resetAllFilters}
      autoResetFilters={false}
      handleColumnSorted={handleColumnSorted}
      columnSortOptions={columnFilterSort}
    />
  );
}

const getTitleComponent = (title) => {
  return (
    <div className="flex-fill">
      <h3 className="mb-2">{title}</h3>
    </div>
  );
};

const getColumns = (
  intl: IntlShape,
  customfilter: (x: any, y: any, z: any) => void,
  toggleRefresh,
  tppSpidList
) => [
    {
      Header: intl.formatMessage({ id: "tradingPartners.table.reqSpId" }),
      accessor: "requestingSpid",
      identifier: TradingPartnerAdminTableColumnIdentifiers.RequestingSpId,
      spidsFilterList: tppSpidList,
      Filter: TppSpidColumnFilter,
      filter: customfilter,
      Cell: (cell: any) => <SpIdCell spId={cell.cell.row.original.requestingSpid} />
    },
    {
      Header: intl.formatMessage({ id: "tradingPartners.table.spId" }),
      accessor: "targetSpid",
      identifier: TradingPartnerAdminTableColumnIdentifiers.TargetSpId,
      spidsFilterList: tppSpidList,
      Filter: TppSpidColumnFilter,
      filter: customfilter,
      Cell: (cell: any) => <SpIdCell spId={cell.cell.row.original.targetSpid} isTpp={true} />
    },
    {
      Header: intl.formatMessage({ id: "tradingPartners.table.serviceProvider" }),
      accessor: "serviceProvider",
      identifier: TradingPartnerAdminTableColumnIdentifiers.ServiceProvider,
      Cell: (cell: any) => getValueOrEmpty(cell.cell.row.original.serviceProvider)
    },
    {
      Header: intl.formatMessage({ id: "tradingPartners.table.verified" }),
      accessor: "isVerified",
      identifier: TradingPartnerAdminTableColumnIdentifiers.IsVerified,
      Filter: DefaultYesNoColumnFilter,
      filter: customfilter,
      Cell: (cell: any) => (
        <VerifiedCell
          id={cell.cell.row.original.targetSpid}
          isVerified={cell.cell.row.original.isVerified}
          verifiedDate={cell.cell.row.original.verifiedDate}
        />
      )
    },
    {
      Header: intl.formatMessage({ id: "tradingPartners.table.touchlessPreOrder" }),
      accessor: "isTouchlessPreorder",
      identifier: TradingPartnerAdminTableColumnIdentifiers.IsTouchlessPreOrder,
      Filter: DefaultYesNoColumnFilter,
      filter: customfilter,
      Cell: (cell: any) => <ValidateCell value={cell.cell.row.original.isTouchlessPreorder} />
    },
    {
      Header: intl.formatMessage({ id: "tradingPartners.table.vpopCustomer" }),
      accessor: "vpopCustomer",
      identifier: TradingPartnerAdminTableColumnIdentifiers.VpopCustomer,
      Filter: DefaultYesNoColumnFilter,
      filter: customfilter,
      Cell: (cell: any) => <ValidateCell value={cell.cell.row.original.vpopCustomer} />
    },
    {
      Header: intl.formatMessage({ id: "tradingPartners.table.ixcRequired" }),
      accessor: "ixcRequired",
      identifier: TradingPartnerAdminTableColumnIdentifiers.IxcRequired,
      Filter: DefaultYesNoColumnFilter,
      filter: customfilter,
      Cell: (cell: any) => getYesOrNo(cell.cell.row.original.ixcRequired)
    },
    {
      Header: intl.formatMessage({ id: "tradingPartners.table.status" }),
      accessor: "status",
      identifier: TradingPartnerAdminTableColumnIdentifiers.Status,
      Filter: TppStatusColumnFilter,
      filter: customfilter,
      Cell: (cell: any) => (
        <StatusCell
          tppId={cell.cell.row.original.id}
          status={cell.cell.row.original.status}
          spId={cell.cell.row.original.targetSpid}
          serviceProvider={cell.cell.row.original.serviceProvider}
        />
      )
    },
    {
      Header: intl.formatMessage({ id: "tradingPartners.table.verifiedDate" }),
      accessor: "verifiedDate",
      identifier: TradingPartnerAdminTableColumnIdentifiers.VerifiedDate,
      Cell: (cell: any) => getValueOrEmpty(cell.cell.row.original.verifiedDate)
    },
    {
      Header: intl.formatMessage({ id: "tradingPartners.table.actions" }),
      identifier: TradingPartnerAdminTableColumnIdentifiers.Actions,
      Cell: (cell: any) => (
        <TradingPartnerActionCell
          action={cell.cell.row.original.status}
          tppId={cell.cell.row.original.id}
          spId={cell.cell.row.original.targetSpid}
          onActionExecuted={toggleRefresh}
        />
      )
    }
  ];
