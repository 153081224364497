import React, { useState } from "react";
import { DefaultNumberCnamUpsertModel } from "./NumberCnamUpsertModel";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { AccountType, AccountTypes } from "services/apis/types/port/AccountType";
import TextFormInput from "components/framework/forms/TextFormInput";
import { Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { NumberApi } from "services/apis/NumberApi";
import { NumberCnamUpsert } from "services/apis/types/number/NumberCnamUpsert";
import { handleFieldOrApiErrors } from "services/util/ApiUtil";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";

type Props = {
  phoneNumber: string;
  cnamName?: string;
  modifyCallback: () => void;
  cancelCallback: () => void;
};

export default function CnamModify(props: Props) {
  const [cnamModel, setCnamModel] = useState(DefaultNumberCnamUpsertModel);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();

  const handleSubmit = () => {
    setShowLoadingIndicator(true);
    const cnam: NumberCnamUpsert =
      cnamModel.accountType === AccountType.Business
        ? { businessName: cnamModel.businessName }
        : { firstName: cnamModel.firstName, lastName: cnamModel.lastName };

    NumberApi.updateCnam(props.phoneNumber, cnam)
      .then(() => {
        //wait a second because of external API delay
        setTimeout(() => {
          showInfoNotification(
            intl.formatMessage({ id: "explore.cnamModal.successNotificationMessage" })
          );
          props.modifyCallback();
        }, 1000);
      })
      .catch((error) => {
        handleFieldOrApiErrors(error);
        setShowLoadingIndicator(false);
      });
  };
  return (
    <div className="d-flex">
      <DropdownFormInput
        className="mr-2"
        required
        value={(cnamModel.accountType as unknown) as string}
        handleInputChange={(value: string) =>
          setCnamModel({
            ...cnamModel,
            accountType: parseInt(value)
          })
        }
        options={Object.keys(AccountTypes).map((x) => {
          return {
            key: AccountTypes[x as keyof typeof AccountType],
            value: AccountType[(x as unknown) as number]
          };
        })}
      />
      {cnamModel.accountType === AccountType.Business ? (
        <TextFormInput
          formGroupClassName="mr-2"
          value={cnamModel.businessName}
          handleInputChange={(value: string) => setCnamModel({ ...cnamModel, businessName: value })}
        />
      ) : (
        <>
          <TextFormInput
            formGroupClassName="mr-2"
            value={cnamModel.firstName}
            handleInputChange={(value: string) => setCnamModel({ ...cnamModel, firstName: value })}
          />
          <TextFormInput
            formGroupClassName="mr-2"
            value={cnamModel.lastName}
            handleInputChange={(value: string) => setCnamModel({ ...cnamModel, lastName: value })}
          />
        </>
      )}
      <Button
        size="sm"
        color="link"
        type="button"
        className="form-control-sm"
        onClick={props.cancelCallback}>
        <FormattedMessage id="explore.cnamModal.cancelButton" />
      </Button>
      <Button
        size="sm"
        color="primary"
        type="button"
        className="form-control-sm"
        disabled={showLoadingIndicator}
        onClick={handleSubmit}>
        {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
        <FormattedMessage id="explore.cnamModal.submitButton" />
      </Button>
    </div>
  );
}
