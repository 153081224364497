import React, { useContext } from "react";
import IconWithTooltip from "./IconWithTooltip";
import { useIntl } from "react-intl";
import { AppContext } from "services/appContext/AppContext";

type Props = {
    order: any;
    columnValue: string;
    orderInfo?: any
};

export default function DuplicateTNNotifier(props: Props) {
    const intl = useIntl();
    const { appContext } = useContext(AppContext);
    const userSpIdProfiles = appContext.localStorageInfo.user?.spIdProfiles.map(x => x.spId);

    let spidOfOtherOrders = '';
    if (props.orderInfo && props.orderInfo.order && props.orderInfo.order.tnsInOtherOrders) {
        const orders = Array.prototype.concat.apply([], Object.values(props.orderInfo.order.tnsInOtherOrders));
        const unique = orders.filter((uniqueNumber, index) => {
            return uniqueNumber && orders.indexOf(uniqueNumber) === index;
        });

        spidOfOtherOrders = unique
            .filter(order => typeof order === 'string' && order.length >= 4)
            .map(order => order.substring(0, 4)).toString();
    }

    const duplicateInfo = () => {
        if (userSpIdProfiles?.includes(spidOfOtherOrders)) {
          return props.order?.duplicateOrders
            ? intl.formatMessage({ id: 'orders.orderDetails.portingNumber.table.duplicateNotificationMessage' }, { orders: props.order.duplicateOrders.toString() })
            : intl.formatMessage({ id: 'orders.orderDetails.portingNumber.table.duplicateNotificationMessage' }, { orders: Object.values(props.orderInfo.order.tnsInOtherOrders[props.order]).toString() });
        } else {
          return props.order?.duplicateOrders
            ? intl.formatMessage({ id: 'orders.orderDetails.portingNumber.table.duplicateNotificationMessage' }, { orders: props.order.duplicateOrders.toString() })
            : intl.formatMessage({ id: 'orders.orderDetails.portingNumber.table.duplicateNotificationMessageForOtherOrg' });
        }
      };
      


    

    return (
        <>
            <div> 
                {props.columnValue === 'numberCount' ? props.order.numberCount : props.order ? props.order : ''}
                {
                    ((props.order.duplicateOrders &&
                    props.order.duplicateOrders.length > 0 )|| (props.orderInfo && props.orderInfo.order.tnsInOtherOrders && props.orderInfo.order.tnsInOtherOrders[props.order])) &&
                    <IconWithTooltip
                        className="ml-1 fas fa-exclamation-circle text-danger"
                        tooltip={duplicateInfo()}
                    />
                }
            </div>
        </>
    );
}
