import React from "react";
import DropdownInput from "../forms/DropdownInput";
import { DefaultYesNoFilterValues } from "services/util/TppFiltersUtil";

type Props = {
    column: {
        filterValue: string;
        preFilteredRows: Array<any>;
        setFilter: (value: string | undefined) => void;
        id: string;
    },
};

export default function DefaultYesNoColumnFilter(props: Props) {
    const options = React.useMemo(() => DefaultYesNoFilterValues, []);

    return (
        <DropdownInput
            sm
            onChange={(e) => {
                props.column.setFilter(e.target.value || undefined);
            }}
            value={props.column.filterValue}
            options={options}
        />
    );
}