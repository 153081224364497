import React from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";

const Navbar = (props) => {
  if (!props.navTitlesRoutes || props.navTitlesRoutes.length === 0) {
    return null; // or display a fallback UI, depending on your use case
  }

  return (
    <>
      <div className="inventory_navbar">
        {props.navTitlesRoutes.map((x) => {
          const checkActive = (match, location) => {
            if (!location) return false;
            const { pathname } = location;
            return pathname === x.routes; // Compare with the current element's route
          };
          return (
            <div key={x.routes}>
              <ul className="navbar_items">
                <li>
                  <NavLink className="navbar_items_link" isActive={checkActive} to={x.routes}>
                    {x.titles}
                  </NavLink>
                </li>
              </ul>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Navbar;
