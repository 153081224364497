export enum OrdersTableColumnIdentifiers {
  Selected = "",
  ProjectId = "projectId",
  DueDate = "dueDate",
  OrderType = "orderType",
  Status = "status",
  GainingSpid = "gainingSpid",
  LoosingSpid = "loosingSpid",
  CarrierName = "carrierName",
  NextWorkDate = "nextWorkDate",
  WorkedBy = "workedBy",
  NumberCount = "numberCount",
  Actions = "actions",
  AdminProjectId = "adminProjectId",
  ActivationDate = "activationDate"
}
