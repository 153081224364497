import React, { useState, FormEvent } from "react";
import { Form, Button, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { Errors, HasErrors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { NotificationDto } from "services/apis/types/webhooks/NotificationDto";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import { AddNotesModel, DefaultAddNotesModel } from "services/apis/types/order/AddNotesModel";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import {
  noteVisibilityDropdownOptions,
  NoteVisibility
} from "services/apis/types/order/NoteVisibility";
import { OrderApi } from "services/apis/OrderApi";

type Props = {
  orderId: string;
  triggerRefresh: () => void;
};

export default function NoteForm(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [noteModel, setNoteModel] = useState<AddNotesModel>(DefaultAddNotesModel);
  const [showSaveLoadingIndicator, setShowSaveLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const errors = validate(noteModel);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowSaveLoadingIndicator(true);
      OrderApi.addNote(props.orderId, noteModel)
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "orders.orderDetails.notes.createSuccessNotificationMessage" })
          );
          props.triggerRefresh();
          setNoteModel(DefaultAddNotesModel);
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowSaveLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <>
      <h5 className="modal-title mb-3">
        <FormattedMessage id="orders.orderDetails.notes.addTitle" />
      </h5>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Row>
          <TextAreaFormInput
            className="col-lg-12"
            value={noteModel.note}
            handleInputChange={(value: string) => setNoteModel({ ...noteModel, note: value })}
            errorHandler={getErrorHandler(nameOf<AddNotesModel>("note"))}
          />
        </Row>
        <div className="d-flex">
          <DropdownFormInput
            lg
            required
            className="flex-grow-1 pc-last-form-input"
            value={noteModel.isPrivateNote ? NoteVisibility.Private : NoteVisibility.Public}
            handleInputChange={(value: string) =>
              setNoteModel({ ...noteModel, isPrivateNote: value === NoteVisibility.Private })
            }
            errorHandler={getErrorHandler(nameOf<AddNotesModel>("isPrivateNote"))}
            options={noteVisibilityDropdownOptions}
          />
          <Button
            color="danger"
            className="pc-btn flex-grow-1 ml-2"
            disabled={showSaveLoadingIndicator}>
            {showSaveLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <i className="fas fa-plus-square mr-2" />
            <FormattedMessage id="orders.orderDetails.notes.submitButton" />
          </Button>
        </div>
      </Form>
    </>
  );
}

const validate = (noteModel: AddNotesModel) => {
  const errors: Errors = {};

  if (!noteModel.note) {
    AddError(errors, nameOf<NotificationDto>("name"), "orders.orderDetails.notes.required");
  }

  return errors;
};
