import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useIntl, IntlShape } from "react-intl";
import { handleError } from "services/util/ApiUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import SpIdCell from "components/common/SpIdCell";
import { useIsMounted } from "services/customHooks/useIsMounted";
import ReactTable from "components/framework/table/ReactTable";
import { getValueOrEmpty, undefinedIfEmpty } from "services/util/StringUtil";
import FormatDateTime from "components/framework/date/FormatDateTime";
import DateRangeColumnFilter, {
    dateRangefilterFunction
} from "components/framework/table/DateRangeColumnFilter";
import { WorkItemDto } from "services/apis/types/workQueue/WorkItemDto";
import { WorkQueueApi } from "services/apis/WorkQueueApi";
import { OrderActionCell } from "components/common/actions/OrderActionCell";
import { getFormattedUtcDate } from "services/util/DateUtil";
import { AppConfiguration } from "AppConfiguration";
import OrderLink from "components/orders/ordersList/orderTable/OrderLink";
import { getNumberStatus } from "services/apis/types/order/NumberStatus";
import ColumnsCustomViewSettings from "components/framework/table/ColumnsCustomViewSettings";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import { WorkQueueTableColumnIdentifiers } from "./types/WorkQueueTableColumnIdentifiers";
import { DataTableColumn } from "components/framework/table/types/DataTableColumn";
import RestoreTableColumnFilter, { restoreTableColumnFilterFunction } from "components/framework/table/RestoreTableColumnFilter";

type Props = {
    search: string;
    onToggleRefresh: any;
};

export default function WorkQueueTable(props: Props) {
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [workItems, setWorkItems] = useState<WorkItemDto[]>();
    const intl = useIntl();
    const isMounted = useIsMounted();
    const { onToggleRefresh } = props;
    const dueDateFilter = useCallback(dateRangefilterFunction, [dateRangefilterFunction]);
    const toggleRefresh = useCallback(() => {
        if (isMounted.current) {
            setTriggerRefresh((x) => {
                onToggleRefresh(x); // Call the callback with the current state value
                return !x;
            });
        }
    }, [isMounted, onToggleRefresh]);
    const updateAndSetWorkItems = useCallback((workItems: WorkItemDto[]) => {
        workItems.forEach(o => { 
            o.status = getNumberStatus(o.status); 
            
            // id will be used as an extra field when exporting data
            o.id = undefinedIfEmpty(o.projectId) ?? o.orderId}); 
        setWorkItems(workItems);
    }, []);

    const columns = useMemo(
        () => getColumns(intl, dueDateFilter, toggleRefresh),
        [intl, dueDateFilter, toggleRefresh]
    );

    const exportColumns = useMemo(()=> {
        var updColumns = [...columns];
        
        updColumns.splice(columns.findIndex(c=> c.accessor === WorkQueueTableColumnIdentifiers.Actions), 1);

        var idColumn = updColumns.find(c=> c.accessor === WorkQueueTableColumnIdentifiers.ProjectId);
        if (idColumn) {
            idColumn.accessor = WorkQueueTableColumnIdentifiers.Id;
            idColumn.identifier = WorkQueueTableColumnIdentifiers.Id;
        }
        
        return updColumns;
        
        },[columns]
    );

    const customViewSettingsColumnsMap = useMemo(()=> {
        return columns.map(c=> {
            return {
               accessor : c.accessor,
               identifier: c.identifier,
               notConfigurable: c.notConfigurable,
               Header: c.Header
            } as DataTableColumn
        });
    },[columns])

    useEffect(() => {
        setShowLoadingIndicator(true);
        WorkQueueApi.list(props.search.toUpperCase())
            .then((result) => {
                if (isMounted) {
                    updateAndSetWorkItems(result);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted) {
                    setShowLoadingIndicator(false);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, triggerRefresh, props.search]);

    return (
        <>
            {showLoadingIndicator ? (
                <LoadingIndicator white />
            ) : (
                <>
                    {workItems && (
                        <ReactTable
                            identifier={TableIdentifiers.WorkQueue}
                            title={intl.formatMessage({ id: "workQueue.order.table.tableTitle" })}
                            data={workItems}
                            columns={columns}
                            useColumnFilters
                            onRefresh={toggleRefresh}
                            showViewSettings
                            showExport
                            exportColumns={exportColumns}
                            customViewSettingsComponents={
                                <ColumnsCustomViewSettings 
                                    viewName={"Columns"} 
                                    tableIdentifier={TableIdentifiers.WorkQueue} 
                                    columns={customViewSettingsColumnsMap} 
                                />
                            }
                            filterMapper={filterMapper}
                        />
                    )}
                </>
            )}
        </>
    );
}

const getColumns = (
    intl: IntlShape,
    filter: (x: any, y: any, z: any) => void,
    onActionExecuted: () => void,
) => [
        {
            Header: intl.formatMessage({ id: "workQueue.order.table.id" }),
            accessor: WorkQueueTableColumnIdentifiers.ProjectId,
            identifier: WorkQueueTableColumnIdentifiers.ProjectId,
            notConfigurable: true,
            className: "padding-horizontal-8",
            Cell: (cell: any) => (
                <OrderLink urlRoutePath="/WorkQueue" orderId={cell.cell.row.original.orderId} value={cell.cell.value} tenant={cell.cell.row.original.tenant} />
            )
        },
        {
            Header: intl.formatMessage({ id: "workQueue.order.table.dueDate" }),
            accessor: WorkQueueTableColumnIdentifiers.DueDate,
            identifier: WorkQueueTableColumnIdentifiers.DueDate,
            className: "max-width-200 padding-horizontal-8",
            Filter: DateRangeColumnFilter,
            filter: filter,
            Cell: (cell: any) => (cell.cell.value ? <FormatDateTime utcDate={cell.cell.value} /> : "")
        },
        {
            Header: intl.formatMessage({ id: "workQueue.order.table.type" }),
            accessor: WorkQueueTableColumnIdentifiers.OrderType,
            identifier: WorkQueueTableColumnIdentifiers.OrderType,
            className: "padding-horizontal-8",
            Filter: RestoreTableColumnFilter,
            filter: restoreTableColumnFilterFunction,
            Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
        },
        {
            Header: intl.formatMessage({ id: "workQueue.order.table.status" }),
            accessor: WorkQueueTableColumnIdentifiers.Status,
            identifier: WorkQueueTableColumnIdentifiers.Status,
            className: "padding-horizontal-8",
            Filter: RestoreTableColumnFilter,
            filter: restoreTableColumnFilterFunction,
            Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
        },
        {
            Header: intl.formatMessage({ id: "workQueue.order.table.organization" }),
            accessor: WorkQueueTableColumnIdentifiers.Organization,
            identifier: WorkQueueTableColumnIdentifiers.Organization,
            className: "padding-horizontal-8",
            Filter: RestoreTableColumnFilter,
            filter: restoreTableColumnFilterFunction,
            Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
        },
        {
            Header: intl.formatMessage({ id: "workQueue.order.table.newSpId" }),
            accessor: WorkQueueTableColumnIdentifiers.GainingSpId,
            identifier: WorkQueueTableColumnIdentifiers.GainingSpId,
            className: "padding-horizontal-8",
            Filter: RestoreTableColumnFilter,
            filter: restoreTableColumnFilterFunction,
            Cell: (cell: any) => <SpIdCell spId={cell.cell.row.original.gainingSpId} />
        },
        {
            Header: intl.formatMessage({ id: "workQueue.order.table.oldSpId" }),
            accessor: WorkQueueTableColumnIdentifiers.LoosingSpId,
            identifier: WorkQueueTableColumnIdentifiers.LoosingSpId,
            className: "padding-horizontal-8",
            Filter: RestoreTableColumnFilter,
            filter: restoreTableColumnFilterFunction,
            Cell: (cell: any) => (
                <SpIdCell spId={cell.cell.row.original.loosingSpId} labelColor="bg-warning" />
            )
        },
        {
            Header: intl.formatMessage({ id: "workQueue.order.table.nextWorkDate" }),
            accessor: WorkQueueTableColumnIdentifiers.NextWorkDate,
            identifier: WorkQueueTableColumnIdentifiers.NextWorkDate,
            className: "max-width-200 left-datepicker padding-horizontal-8",
            Filter: DateRangeColumnFilter,
            filter: filter,
            Cell: (cell: any) =>
                cell.cell.value ? (
                    <FormatDateTime utcDate={cell.cell.value} format={AppConfiguration.dateFormat} />
                ) : (
                    ""
                )
        },
        {
            Header: intl.formatMessage({ id: "workQueue.order.table.assignedTo" }),
            accessor: WorkQueueTableColumnIdentifiers.WorkedBy,
            identifier: WorkQueueTableColumnIdentifiers.WorkedBy,
            className: "padding-horizontal-8",
            Filter: RestoreTableColumnFilter,
            filter: restoreTableColumnFilterFunction,
            Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
        },
        {
            Header: intl.formatMessage({ id: "workQueue.order.table.tn" }),
            accessor: WorkQueueTableColumnIdentifiers.NumberCount,
            identifier: WorkQueueTableColumnIdentifiers.NumberCount,
            className: "padding-horizontal-8",
            Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
        },
        {
            Header: intl.formatMessage({ id: "workQueue.order.table.creationDate" }),
            accessor: WorkQueueTableColumnIdentifiers.CreationDate,
            identifier: WorkQueueTableColumnIdentifiers.CreationDate,
            className: "max-width-200 padding-horizontal-8",
            Filter: DateRangeColumnFilter,
            filter: filter,
            Cell: (cell: any) => (cell.cell.value ? <FormatDateTime utcDate={cell.cell.value} /> : "")
        },
        {
            Header: intl.formatMessage({ id: "orders.order.table.actions" }),
            accessor: WorkQueueTableColumnIdentifiers.Actions,
            identifier: WorkQueueTableColumnIdentifiers.Actions,
            className: "padding-horizontal-8",
            Cell: (cell: any) => (
                <OrderActionCell order={cell.cell.row.original} onActionExecuted={onActionExecuted} />
            )
        }
    ];

const filterMapper = (workItemDto: WorkItemDto) => {
    return {
        ...workItemDto,
        projectId: workItemDto.projectId || workItemDto.orderId,
        dueDate: getFormattedUtcDate(workItemDto.dueDate),
        nextWorkDate: getFormattedUtcDate(workItemDto.nextWorkDate),
        numberCount: workItemDto.numberCount.toString()
    };
};