import React, { useState, FormEvent, useCallback } from "react";
import { Modal, Form, Button, Row, FormGroup, Label, Input } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Moment } from "moment";
import { useErrors } from "services/customHooks/useErrors";
import { Disconnect, DefaultDisconnect } from "../types/Disconnect";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { HasErrors, Errors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { PortApi } from "services/apis/PortApi";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import CloseButton from "components/framework/modals/CloseButton";
import { nameOf } from "services/util/ObjectUtil";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import { usePastedNumberFormatter } from "services/customHooks/usePastedNumberFormatter";
import classNames from "classnames";
import InputError from "components/framework/errorHandling/InputError";
import { validateNumberInputValue } from "services/validators/ValidatePhoneNumbers";
import { ValidationResult } from "services/validators/ValidationResult";

type Props = {
  closeModal: () => void;
};

export default function DisconnectModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [disconnect, setDisconnect] = useState<Disconnect>(DefaultDisconnect);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const [focusedSearchInput, setFocusedSearchInput] = useState(false);

  const closeModal = useCallback(props.closeModal, []);
  const onPaste = usePastedNumberFormatter(disconnect.phoneNumbers, (formattedValue) =>
    setDisconnect({ ...disconnect, phoneNumbers: formattedValue })
  );

  const phoneNumbersErrorsErrorHandler = getErrorHandler(nameOf<Disconnect>("phoneNumbers"));
  const phoneNumbersErrors = phoneNumbersErrorsErrorHandler.getErrors();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const errors = validateDisconnect(disconnect);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowLoadingIndicator(true);

      PortApi.disconnect(
        disconnect.phoneNumbers.split(","),
        disconnect.disconnectDate.toDate(),
        disconnect.releaseDate.toDate()
      )
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "orders.disconnect.successNotificationMessage" })
          );
          closeModal();
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id="orders.disconnect.title" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          <Row>
            <FormGroup className="mb-2 col-lg-12">
              <Label className="form-control-label">
                <FormattedMessage id="orders.disconnect.numberRange" />
              </Label>
              <Input
                className={classNames("input-group-merge pc-search-container mb-0 h-180", {
                  focused: focusedSearchInput,
                  "pc-invalid-input": phoneNumbersErrors && phoneNumbersErrors.length > 0
                })}
                rows="6"
                type="textarea"
                value={disconnect.phoneNumbers}
                onChange={(e) => {
                  phoneNumbersErrorsErrorHandler.clear();
                  setDisconnect({ ...disconnect, phoneNumbers: e.currentTarget.value });
                }}
                onFocus={() => setFocusedSearchInput(true)}
                onBlur={() => setFocusedSearchInput(false)}
                onPaste={onPaste}
                placeholder={intl.formatMessage({
                  id: "orders.disconnect.numberRange.placeholder"
                })}
              />
              <InputError errors={phoneNumbersErrors} />
            </FormGroup>
          </Row>
          <Row>
            <DateTimeFormInput
              className="col-lg-12"
              labelTranslationId="orders.disconnect.disconnectDate"
              value={disconnect.disconnectDate}
              handleInputChange={(value: Moment) =>
                setDisconnect({ ...disconnect, disconnectDate: value })
              }
              errorHandler={getErrorHandler(nameOf<Disconnect>("disconnectDate"))}
            />
          </Row>
          <Row>
            <DateTimeFormInput
              className="col-lg-12"
              labelTranslationId="orders.disconnect.disconnectTime"
              value={disconnect.disconnectDate}
              handleInputChange={(value: Moment) =>
                setDisconnect({ ...disconnect, disconnectDate: value })
              }
              isTimeInput
              errorHandler={getErrorHandler(nameOf<Disconnect>("disconnectDate"))}
            />
          </Row>
          <Row>
            <DateTimeFormInput
              className="col-lg-12"
              labelTranslationId="orders.disconnect.releaseDate"
              value={disconnect.releaseDate}
              handleInputChange={(value: Moment) =>
                setDisconnect({ ...disconnect, releaseDate: value })
              }
              errorHandler={getErrorHandler(nameOf<Disconnect>("releaseDate"))}
            />
          </Row>
          <Row>
            <DateTimeFormInput
              className="col-lg-12"
              labelTranslationId="orders.disconnect.releaseTime"
              value={disconnect.releaseDate}
              handleInputChange={(value: Moment) =>
                setDisconnect({ ...disconnect, releaseDate: value })
              }
              isTimeInput
              errorHandler={getErrorHandler(nameOf<Disconnect>("releaseDate"))}
            />
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={() => props.closeModal()}>
            <FormattedMessage id="orders.disconnect.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className="ml-auto" disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="orders.disconnect.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

const validateDisconnect = (disconnect: Disconnect) => {
  const errors: Errors = {};

  const validationResult = validateNumberInputValue(disconnect.phoneNumbers);

  if (validationResult !== ValidationResult.Valid) {
    AddError(errors, nameOf<Disconnect>("phoneNumbers"), "orders.disconnect.numberRange.invalid");
  }

  if (!disconnect.disconnectDate) {
    AddError(
      errors,
      nameOf<Disconnect>("disconnectDate"),
      "orders.disconnect.disconnectDate.required"
    );
  }

  return errors;
};
