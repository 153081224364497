import React, { useState, FormEvent,  RefObject, useEffect, ReactElement } from "react";
import classnames from "classnames";
import { DataTableColumn } from "./types/DataTableColumn";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, TabContent, TabPane, NavItem, NavLink, Nav } from "reactstrap";
import CloseButton from "../modals/CloseButton";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import { FormattedMessage, useIntl } from "react-intl";
import {CustomViewSettingBase} from "./CustomViewSettingsBase";
import {
  UpdateAccountDto,
} from "services/apis/types/account/UpdateAccountDto";
import { AccountApi } from "services/apis/AccountApi";
import { IdentityService } from "services/identity/IdentityService";
import { handleFieldOrApiErrors } from "services/util/ApiUtil";
import { ApiError } from "services/apis/types/ApiError";
import {  populateAccountDtoWithUiSettings } from "services/uiSettings/UiSettingsService";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { showInfoNotification } from "../notification/NotificationUtil";
import { getTranslation } from "translations/TranslationService";
import {useRefresh} from "services/customHooks/useRefresh";
type Props = {
  tableIdentifier: TableIdentifiers;
  columns: Array<DataTableColumn>;
  children?: any[];
  closeModal: () => void;
};

export default function TableSettingsModal(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [customViewChilds, addCustomViewChilds] = useState<Array<CustomViewSettingBase>>([]);

  const isMounted = useIsMounted();
  const intl = useIntl();

  const customViewRefs : Array<RefObject<CustomViewSettingBase | undefined>> = [];
  
  const refresh = useRefresh();
  
  useEffect(()=>{
    // cache custom view childs
    var children = props.children;
    React.Children.map(children, (childComponent, idx)=> {
      addCustomViewChilds((prev)=> [...prev, childComponent as CustomViewSettingBase]);
    });
  },[props.children]);

  
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    const customViewsSettings : Array<[string, any[]]> = [];
    customViewRefs.forEach((ref)=>{
      const settings = (ref.current as CustomViewSettingBase).getSettings() as [string, any[]];
      customViewsSettings.push(settings);
    });
    
    var user = AppContextAccessor.getAppContext().localStorageInfo?.user;
    var accountDto = populateAccountDtoWithUiSettings(user, props.tableIdentifier, customViewsSettings);//[spidUiSettings, columnsUiSettings]);

    updateAccountSettings(accountDto);

  }

  

  const getTabId = (idx)=> "tab"+idx; 

  const getViewName = (component: ReactElement) : string=>{
    var viewName = (component).props.viewName as string;
    return viewName;
  }

  const createAndSaveRef = (obj:CustomViewSettingBase) => {
    var ref = React.createRef<CustomViewSettingBase>();
    customViewRefs.push(ref);
    return ref;
  }

  const handleTabSelection = (e, state, index) => {
    e.preventDefault();
    setSelectedTabIndex(index);
  };

  const getTabStyle = (tabIndex: Number) => {
    return classnames("mb-sm-3 mb-md-0", { active: selectedTabIndex === tabIndex})
  };

  const updateAccountSettings = (account: UpdateAccountDto | undefined) => {
     AccountApi.updateInfo(account as UpdateAccountDto)
        .then(async () => {
          await IdentityService.fetchUserInfo();
          showInfoNotification(getTranslation(intl, "table.settingsModal.successMessage"));
        })
        .catch((error: ApiError) => {
          handleFieldOrApiErrors(error);
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
            
            props.closeModal();
            
            refresh();

          }
        });
  }
  
  return (
    <Modal isOpen={true} centered contentClassName="table-settings-modal-content">
      <ModalHeader>
        <FormattedMessage id="table.settingsModal.title" />
        <CloseButton close={props.closeModal} />
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody className={"table-settings-modal-body"}>
          <div className = "nav-wrapper">
            <Nav className="nav-fill flex-column flex-md-row" id="tabs-icons-text" pills role="tablist">
            {customViewChilds.map((component : CustomViewSettingBase, idx)=>(
              <NavItem key={idx} className="table-settings-modal-customviewsettings-tab">
                <NavLink
                key={"navLink" + idx} 
                aria-selected = {selectedTabIndex === idx } 
                onClick={(e)=>handleTabSelection(e, null, idx)}  
                role="tab" 
                href="#" 
                className={getTabStyle(idx)}> 
                {getViewName(component as unknown as React.ReactElement<CustomViewSettingBase>)}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          </div>
          <TabContent activeTab={"tab" + selectedTabIndex}>
            {customViewChilds.map((component, idx)=>(
            <TabPane 
              tabId={getTabId(idx)}
              className="pc-react-table-custom-view-settings-tab-content" 
              key={"tabPane" + idx}
              >
              {React.cloneElement(
                component as unknown as React.ReactElement<CustomViewSettingBase>, 
                {  
                  props: {tableIdentifier: props.tableIdentifier as TableIdentifiers},
                  ref: createAndSaveRef(component as CustomViewSettingBase)
                })}
            </TabPane>
          ))}
          </TabContent>
        </ModalBody>
        <ModalFooter className="mt-4">
          <Button color="link" type="button" onClick={props.closeModal}>
            <FormattedMessage id="table.settingsModal.cancel" />
          </Button>
          <Button color="primary" type="submit" disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="table.settingsModal.save" />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}


