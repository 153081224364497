import { useCallback } from "react";
import { OrderCardNumberModel } from "components/orders/types/OrderCardNumberModel";
import { DefaultPortInAccountModel, PortInAccountModel } from "components/orders/types/PortInAccountModel";
import { NetworkProfileDto } from "services/apis/types/networkProfile/NetworkProfileDto";
import { OrderNumber } from "services/apis/types/port/OrderNumber";
import { ApiError } from "components/common/types/ApiError";
import { ApiErrorType } from "components/common/types/ApiErrorType";
import { OrderProposal } from "services/apis/types/port/OrderProposal";
import { DefaultExcelTemplateLrnPtoGroup, ExcelTemplateLrnPtoGroup } from "components/orders/types/ExcelTemplateLrnPtoGroup";
import { DefaultOrderCardModel, OrderCardModel } from "components/orders/types/OrderCardModel";
import { ExcelTemplateAccount, getDueDateFromExcelAccount } from "components/orders/types/ExcelTemplateAccount";
import { PortProposalDto } from "services/apis/types/port/PortProposalDto";
import { AccountType } from "services/apis/types/port/AccountType";
import { useState } from "react";
import { deepCloneWithCircular, distinctFilter } from "services/util/ArrayUtil";
import moment from "moment";
import { OrderRequestType } from "services/apis/types/port/OrderRequestType";
import { OrderDto } from "services/apis/types/order/OrderDto";
import { AppConfiguration } from "AppConfiguration";
import { OrderHandlerType } from "services/apis/types/port/OrderHandlerType";
import { getUtcDate } from "services/util/DateUtil";
import cloneDeep from 'lodash/cloneDeep';


export default function usePortInAccountModelMapper(){

    const [validationErrorMessages, setValidationErrorMessages] = useState<ApiError[]>([]);
    
    const accountMatches = (orderRequestType: OrderRequestType, account : PortInAccountModel, account2 : PortInAccountModel) => {
        return orderRequestType === OrderRequestType.OrderOnly
            ?   account.csrRequested === account2.csrRequested &&
                account.dueDate === account2.dueDate &&
                account.projectId === account2.projectId
            
            :   account.csrRequested === account2.csrRequested &&
                account.dueDate === account2.dueDate &&
                account.projectId === account2.projectId &&
                account.customerDetails?.accountName === account2.customerDetails?.accountName &&
                account.customerDetails?.accountNumber === account2.customerDetails?.accountNumber &&
                account.customerDetails?.accountPin === account2.customerDetails?.accountPin &&
                account.customerDetails?.accountType === account2.customerDetails?.accountType &&
                account.customerDetails?.authorizedName === account2.customerDetails?.authorizedName &&
                account.customerDetails?.billingPhoneNumber === account2.customerDetails?.billingPhoneNumber &&
                account.customerDetails?.serviceAddress?.city === account2.customerDetails?.serviceAddress?.city &&
                account.customerDetails?.serviceAddress?.directionPrefix === account2.customerDetails?.serviceAddress?.directionPrefix &&
                account.customerDetails?.serviceAddress?.directionSuffix === account2.customerDetails?.serviceAddress?.directionSuffix &&
                account.customerDetails?.serviceAddress?.number === account2.customerDetails?.serviceAddress?.number &&
                account.customerDetails?.serviceAddress?.state === account2.customerDetails?.serviceAddress?.state &&
                account.customerDetails?.serviceAddress?.streetName === account2.customerDetails?.serviceAddress?.streetName &&
                account.customerDetails?.serviceAddress?.streetNameSuffix === account2.customerDetails?.serviceAddress?.streetNameSuffix &&
                account.customerDetails?.serviceAddress?.zipCode === account2.customerDetails?.serviceAddress?.zipCode
    };

    const sortAndMergeAccountModels = (orderRequestType: OrderRequestType, accounts: Array<PortInAccountModel>) : Array<PortInAccountModel>=> {
        const models = accounts.flatMap(a=> splitAccountModelsBySpid(a));
        const accountsXcheck = [...models].map(account=> {
            return { 
                account: deepCloneWithCircular(account), 
                accountsFound : models.filter(account2=> accountMatches(orderRequestType , account, account2))
            };
        });

        const cloneAccount = (a: PortInAccountModel)=> {  
            const dueDate = JSON.parse(JSON.stringify(a.dueDate))?._i;
            return {
                ...deepCloneWithCircular(a),
                dueDate: a.dueDate ? moment.utc(dueDate) : null
            } as PortInAccountModel
        };

        let merged : Array<PortInAccountModel> = accountsXcheck
            .map(a=> { return a.accountsFound.length === 1 
                        ? cloneAccount(a.account)
                        : { ...cloneAccount(a.account),
                            orderCards : a.accountsFound.flatMap(ac=> ac.orderCards).map(c => {
                                const clonedCard = cloneDeep({ ...c });
                                return clonedCard;
                              })
                        } as PortInAccountModel
            })
            .filter(distinctFilter);
            
        return merged;
    };
    
    const splitAccountModelsBySpid = (account: PortInAccountModel): Array<PortInAccountModel> => {
        
        const getCardsFn = (cards, spid)=> cards.filter(c=> c.loosingSpid === spid);
        
        const spids = account.orderCards.map(c=> c.loosingSpid);
        return spids.map(spid=> {
            
            const dueDateString = account.dueDate && account.dueDate?.format("YYYY-MM-DDTHH:mm:ss.SSSSZ");  
            const clonedAccount = deepCloneWithCircular({...account , orderCards: [], dueDate: dueDateString}) as PortInAccountModel;
            const dueDateObject = JSON.parse(JSON.stringify(clonedAccount.dueDate));
            if(dueDateObject && dueDateObject._i) {
                const originalDate = new Date(dueDateObject._i)
                const formattedDate: any = originalDate.toISOString();
                clonedAccount.dueDate = formattedDate;
            }
            const clonedCards =  getCardsFn(account.orderCards, spid).map(card=> deepCloneWithCircular(card) as OrderCardModel);
            clonedAccount.orderCards = [...clonedCards];
            clonedAccount.dueDate = clonedAccount.dueDate ? moment.utc(clonedAccount.dueDate) : null;

           return clonedAccount;
        });
    }
    
    const addLrnValidationError = (lrn: string, number: string): void=> {
        setValidationErrorMessages((prev) =>
            [...prev, 
            {
                message: `LRN ${lrn} for TN ${number} is not available. Default LRN selected.`,
                type: ApiErrorType.Danger
            }].filter(distinctFilter) as ApiError[]
        );
    };

    const mapToOrderCardModels = (
            orderProposal: OrderProposal,
            lrnProfiles: Record<string, NetworkProfileDto>,
            lrnGroups: ExcelTemplateLrnPtoGroup[]
        ) : Array<OrderCardModel> => {

        return lrnGroups && lrnGroups
        .filter((group)=> orderProposal.numbers.some((number) => group.phoneNumbers.includes(number.number)))
        .map(group=> 
            { 
                const availableProfiles = (orderNumber: OrderNumber)=> orderNumber.availableProfiles?.map((profileIdGuid) => lrnProfiles[profileIdGuid]);
                const profileForExcelLrn =(orderNumber: OrderNumber, lrn: string)=> availableProfiles(orderNumber)?.find((profile) => profile.lrn === lrn);
                
                return {
                        ...DefaultOrderCardModel,
                        lrn: group.lrn,
                        lata: orderProposal.lata,
                        loosingProviderName: orderProposal.currentProviderName,
                        loosingSpid: orderProposal.currentSpId,
                        united: orderProposal.united,
                        portToOrginal: group.portToOrginal.toLowerCase() === "y",
                        numbers: orderProposal.numbers
                            .filter((number) => group.phoneNumbers.includes(number.number))
                            .map((orderNumber) => {
                                
                                if (profileForExcelLrn(orderNumber, group.lrn) && group.lrn !=="") {
                                    addLrnValidationError(group.lrn, orderNumber.number);  
                                }

                                return {
                                        number: orderNumber.number,
                                        availableProfiles: availableProfiles(orderNumber),
                                        selectedProfile: profileForExcelLrn(orderNumber, group.lrn)
                                            ? profileForExcelLrn(orderNumber, group.orderHandler)
                                            : availableProfiles(orderNumber)?.length
                                                ? availableProfiles(orderNumber)[0]
                                                : undefined
                                } as OrderCardNumberModel
                            }),
                        showNetworkDetails: orderProposal.showNetworkDetails,
                        orderHandler: group.orderHandler
                }});
    };

    const accountContainsOrderNumber = (account: ExcelTemplateAccount, orderNumber:OrderNumber) => 
        account.lrnPtoGroupNumbers.some((group) =>
            group.phoneNumbers.some((number) => number === orderNumber.number)) ?? false; 

    const mapToPortInAccountModel = (
            account: ExcelTemplateAccount  | undefined, 
            proposal: PortProposalDto  | undefined
        ) : PortInAccountModel => {
           let newAccount = {
            ...DefaultPortInAccountModel,
            projectId: account?.projectId,
            dueDate: account ? getDueDateFromExcelAccount(account) : undefined,
            customerDetails: {
                accountName: account?.accountName,
                accountNumber: account?.accountNumber,
                accountPin: account?.accountPin,
                accountType:
                    account?.accountType?.toLowerCase() === "b" || account?.accountType?.toLowerCase() === "business"
                    ? AccountType.Business
                    : AccountType.Residential,
                authorizedName: account?.authorizedName,
                billingPhoneNumber: account?.billingPhoneNumber,
                serviceAddress: {
                    city: account?.city,
                    addressLine2: account?.addressLine2,
                    directionPrefix: account?.directionPrefix,
                    directionSuffix: account?.directionSuffix,
                    number: account?.number,
                    state: account?.state,
                    streetName: account?.streetName,
                    streetNameSuffix: account?.streetNameSuffix,
                    zipCode: account?.zipCode
                }
            },
            csrRequested: account?.csrRequest
        } as PortInAccountModel;

        if (account?.lrnPtoGroupNumbers) {
            newAccount.orderCards = (proposal?.orders
                .filter((order) => order.numbers.some((orderNumber) => accountContainsOrderNumber(account, orderNumber)))
                .flatMap((order) => 
                    mapToOrderCardModels(order, proposal.profiles, account?.lrnPtoGroupNumbers)
                ) ?? []);
        }
        
        return newAccount;
    };

    const findNewPortInAccount = (
            accounts: Array<ExcelTemplateAccount> | undefined, 
            proposalDto: PortProposalDto
        ) : PortInAccountModel | undefined=> {

        const proposalsWithNewOrderNumbers = proposalDto?.orders.filter((orderProposal) => 
                orderProposal.numbers.some((orderNumber) => { 
                    return !accounts?.some(a=> accountContainsOrderNumber(a, orderNumber)); // checking if number is not present in any other account
                }));
        
        let newLrnGroup = proposalsWithNewOrderNumbers?.length 
            ?   {
                    ...DefaultExcelTemplateLrnPtoGroup,
                    phoneNumbers: proposalsWithNewOrderNumbers.flatMap(on=> on.numbers).flatMap(n=> n.number)
                }
            : undefined;                 
        
        return newLrnGroup
            ? {
                ...DefaultPortInAccountModel,
                orderCards: proposalsWithNewOrderNumbers.flatMap(
                    (proposal)=> mapToOrderCardModels(
                        proposal, 
                        proposalDto.profiles, 
                        [newLrnGroup as ExcelTemplateLrnPtoGroup]))
              } as PortInAccountModel 
            : undefined;
    };

    return { 
                mapToModel : (
                        excelAccounts: Array<ExcelTemplateAccount> | undefined,
                        portProposalDto: PortProposalDto | undefined,
                        orderRequestType: OrderRequestType
                ): Array<PortInAccountModel> => {

                    let accountModels : Array<PortInAccountModel> = [];
                    
                    // occurs when data has been entered and/or imported, and also when order proposals have been returned from the api
                    if (excelAccounts?.length) {
                        accountModels = excelAccounts?.map((account)=> mapToPortInAccountModel(account, portProposalDto));
                    }

                    // occurs when order proposals have been fetched via the api
                    if (portProposalDto) {
                        let newAccountModel = findNewPortInAccount(excelAccounts, portProposalDto);
                        if (newAccountModel) {
                            accountModels = accountModels ? [...accountModels, newAccountModel] : [newAccountModel];
                        }

                        // numbers / account models breakout
                        accountModels = sortAndMergeAccountModels(orderRequestType, accountModels);
                    }
                    
                    return accountModels ?? [];
                }, 
                
                mapOrderToAccount: useCallback((order: OrderDto): ExcelTemplateAccount => {
                    return {
                        lrnPtoGroupNumbers:[{
                            lrn: order.numbers[0]?.lrn || "",
                            portToOrginal: "N",
                            phoneNumbers: order.numbers.map(n => n.phoneNumber),
                            orderHandler: order.metadata.handledBy as OrderHandlerType,
                        } as ExcelTemplateLrnPtoGroup],
                        dueDate: getUtcDate(order.desiredDueDate).format(AppConfiguration.dateFormat),
                        accountName: order.customerDetails.accountName,
                        accountNumber: order.customerDetails.accountName,
                        accountPin: order.customerDetails.accountPin,
                        accountType: order.customerDetails.accountType.toString(),
                        authorizedName: order.customerDetails.authorizedName,
                        billingPhoneNumber: order.customerDetails.billingPhoneNumber,
                        city: order.customerDetails.serviceAddress.city,
                        directionPrefix: order.customerDetails.serviceAddress.directionPrefix,
                        directionSuffix: order.customerDetails.serviceAddress.directionSuffix,
                        number: order.customerDetails.serviceAddress.number,
                        state: order.customerDetails.serviceAddress.state,
                        streetName: order.customerDetails.serviceAddress.streetName,
                        streetNameSuffix: order.customerDetails.serviceAddress.streetNameSuffix,
                        zipCode: order.customerDetails.serviceAddress.zipCode,
                        handler: order.metadata.handledBy as OrderHandlerType
                    } as unknown as ExcelTemplateAccount
                },[]),

                apiErrors : validationErrorMessages
    };
}
