import ActionDropdown from "../common/ActionDropdown";
import React, { useState } from "react";
import ActionDropdownItem from "../common/ActionDropdownItem";
import NetworkProfileDeleteModal from "./NetworkProfileDeleteModal";
import NetworkProfileModal from "./NetworkProfileModal";
import { ManageAction } from "components/manage/types/ManageAction";
import { NetworkProfileDto } from "services/apis/types/networkProfile/NetworkProfileDto";

type Props = {
  networkProfile: NetworkProfileDto;
};

export default function NetworkProfileActionsCell(props: Props) {
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);

  return (
    <>
      <ActionDropdown actionsOpen={actionsOpen} actionsToggle={() => setActionsOpen(prevState => !prevState)}>
        <ActionDropdownItem
          handleAction={() => setShowModifyModal(true)}
          iconClassName="fa fa-edit"
          labelId="network.networkProfile.table.actions.modify"
        />
        <ActionDropdownItem
          handleAction={() => setShowDeleteModal(true)}
          iconClassName="fa fa-trash-alt"
          labelId="network.networkProfile.table.actions.delete"
        />
      </ActionDropdown>
      {showModifyModal && (
        <NetworkProfileModal
          manageAction={ManageAction.Modify}
          networkProfile={props.networkProfile}
          closeModal={() => setShowModifyModal(false)}
        />
      )}
      {showDeleteModal && (
        <NetworkProfileDeleteModal
          id={props.networkProfile.profileId}
          lrn={props.networkProfile.lrn}
          closeModal={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
}
