import React, { useState, FormEvent, useContext, useEffect } from "react";
import { Form, Button, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { Errors, HasErrors, AddError } from "components/framework/errorHandling/ErrorUtil";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import { OrderApi } from "services/apis/OrderApi";
import { TasksModel, DefaultTasksModel } from "components/orders/orderDetails/tasks/TasksModel";
import TextFormInput from "components/framework/forms/TextFormInput";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import { Moment } from "moment";
import { AppContext } from "services/appContext/AppContext";
import { UserEmailDto } from "services/apis/types/user/UserEmailDto";
import { UserApi } from "services/apis/UserApi";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";

type Props = {
  taskId: string;
  triggerRefresh: () => void;
};

export default function TaskForm(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const { appContext } = useContext(AppContext);
  const [taskModel, setTaskModel] = useState<TasksModel>(getDefaultModel(appContext));
  const [showSaveLoadingIndicator, setShowSaveLoadingIndicator] = useState(false);
  const [users, setUsers] = useState<UserEmailDto[]>();
  const [usersLoaded, setUsersLoaded] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!usersLoaded) {
      UserApi.getUsersInOrganization()
        .then((result) => {
          if (isMounted.current) {
            setUsers(result);
          }
        })
        .catch((error) => handleError(error))
        .finally(() => {
          if (isMounted.current) {
            setUsersLoaded(true);
          }
        });
    }
  }, [isMounted, usersLoaded]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const errors = validate(taskModel);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowSaveLoadingIndicator(true);
      OrderApi.addTask(props.taskId, {
        ...taskModel,
        nextWorkDate: taskModel.nextWorkDate.toDate()
      })
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "orders.orderDetails.tasks.createSuccessNotificationMessage" })
          );
          props.triggerRefresh();
          setTaskModel(getDefaultModel(appContext));
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowSaveLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <>
      <h5 className="modal-title mb-3">
        <FormattedMessage id="orders.orderDetails.tasks.addTitle" />
      </h5>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Row>
          <TextFormInput
            formGroupClassName="col-lg-12"
            labelTranslationId="orders.orderDetails.tasks.title"
            value={taskModel.title}
            handleInputChange={(value: string) => setTaskModel({ ...taskModel, title: value })}
            errorHandler={getErrorHandler(nameOf<TasksModel>("title"))}
          />
        </Row>
        <Row>
          <TextAreaFormInput
            className="col-lg-12"
            labelTranslationId="orders.orderDetails.tasks.description"
            value={taskModel.description}
            handleInputChange={(value: string) =>
              setTaskModel({ ...taskModel, description: value })
            }
            errorHandler={getErrorHandler(nameOf<TasksModel>("description"))}
          />
        </Row>
        <Row>
          <DateTimeFormInput
            className="col-lg-12"
            labelTranslationId="orders.orderDetails.tasks.nextWorkDate"
            value={taskModel.nextWorkDate}
            showModalOnTop
            handleInputChange={(value: Moment) =>
              setTaskModel({ ...taskModel, nextWorkDate: value })
            }
            errorHandler={getErrorHandler(nameOf<TasksModel>("nextWorkDate"))}
          />
        </Row>
        <div className="d-flex">
          {usersLoaded && users ? (
            <DropdownFormInput
              lg
              required
              className="flex-grow-1 pc-last-form-input"
              value={taskModel.assignedTo}
              handleInputChange={(value: string) =>
                setTaskModel({ ...taskModel, assignedTo: value })
              }
              errorHandler={getErrorHandler(nameOf<TasksModel>("assignedTo"))}
              options={users.map((x) => {
                return { key: x.email, value: x.email };
              })}
            />
          ) : (
            <LoadingIndicator small className="pc-inline-loading-indicator align-self-center" />
          )}

          <div className="align-self-end">
            <Button
              color="info"
              className="pc-btn flex-grow-1 ml-2"
              disabled={showSaveLoadingIndicator}>
              {showSaveLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="orders.orderDetails.tasks.submitButton" />
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

const validate = (taskModel: TasksModel) => {
  const errors: Errors = {};

  if (!taskModel.title) {
    AddError(errors, nameOf<TasksModel>("title"), "orders.orderDetails.tasks.title.required");
  }

  if (!taskModel.description) {
    AddError(
      errors,
      nameOf<TasksModel>("description"),
      "orders.orderDetails.tasks.description.required"
    );
  }

  if (!taskModel.nextWorkDate) {
    AddError(
      errors,
      nameOf<TasksModel>("nextWorkDate"),
      "orders.orderDetails.tasks.nextWorkDate.required"
    );
  }

  return errors;
};

const getDefaultModel = (appContext: AppContext) => {
  return {
    ...DefaultTasksModel,
    assignedTo: appContext?.localStorageInfo?.user?.email ?? DefaultTasksModel.assignedTo
  };
};
