export enum OrderRequestType {
  OrderOnly = "OrderOnly",
  PreOrderAndOrder = "PreOrderAndOrder",
  CSROnly = "CSROnly"
}

export const OrderRequestTypes: Record<keyof typeof OrderRequestType, string> = {
  OrderOnly: "Order only",
  PreOrderAndOrder: "PreOrder & Order",
  CSROnly: "CSR Only"
};
