import React, { useState, FormEvent, useCallback, useContext } from "react";
import { Modal, Form, Button, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { Errors, HasErrors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { isValidLrn } from "services/validators/ValidationUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInput from "components/framework/forms/TextFormInput";
import {
  DefaultOptionalDataInfo,
  OptionalDataInfo
} from "services/apis/types/number/OptionalDataInfo";
import { nameOf } from "services/util/ObjectUtil";
import { DefaultDpcSsnInfo, DpcSsnInfo } from "services/apis/types/block/DpcSsnInfo";
import { ManageAction } from "components/manage/types/ManageAction";
import { useIsMounted } from "services/customHooks/useIsMounted";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import { NetworkExplorerContext } from "../NetworkExplorer";
import {
  NetworkProfileDto,
  DefaultNetworkProfileDto
} from "services/apis/types/networkProfile/NetworkProfileDto";
import CloseButton from "components/framework/modals/CloseButton";
import { NetworkProfileApi } from "services/apis/NetworkProfileApi";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { SvType } from "services/apis/types/block/SvType";

type Props = {
  closeModal: () => void;
  manageAction?: ManageAction;
  submitCallback?: (networkProfile: NetworkProfileDto) => void;
  networkProfile?: NetworkProfileDto;
};

export default function NetworkProfileModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [networkProfile, setNetworkProfile] = useState<NetworkProfileDto>(
    props.networkProfile ? props.networkProfile : DefaultNetworkProfileDto
  );
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const networkExplorerContext = useContext(NetworkExplorerContext);
  const intl = useIntl();
  const isMounted = useIsMounted();

  const closeModal = useCallback(props.closeModal, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    const errors = validateNetworkProfile(networkProfile);

    if (HasErrors(errors)) {
      setErrors(errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);
      }
    } else if (props.submitCallback) {
      props.submitCallback(networkProfile);
    } else {
      const apiFunction =
        props.manageAction === ManageAction.Add ? NetworkProfileApi.add : NetworkProfileApi.modify;
      apiFunction({ ...networkProfile })
        .then(() => {
          showInfoNotification(
            intl.formatMessage({
              id:
                props.manageAction === ManageAction.Add
                  ? "network.networkProfile.edit.createSuccessNotificationMessage"
                  : "network.networkProfile.edit.modifySuccessNotificationMessage"
            })
          );
          networkExplorerContext.networkProfileActionCallback();
          closeModal();
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  const validateNetworkProfile = (block: NetworkProfileDto) => {
    const errors: Errors = {};

    if(!block.lata) {
      AddError(errors, nameOf<NetworkProfileDto>("lata"), "network.networkProfile.edit.lata.invalid");
    }

    if (!isValidLrn(block.lrn)) {
      AddError(errors, nameOf<NetworkProfileDto>("lrn"), "network.networkProfile.edit.lrn.invalid");
    }

    if (!block.description) {
      AddError(errors, nameOf<NetworkProfileDto>("description"), "network.networkProfile.edit.description.invalid");
    }

    return errors;
  };

  return (
    <Modal className="modal-dialog-centered modal-lg" isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          {props.manageAction === ManageAction.Add && (
            <FormattedMessage id="network.networkProfile.edit.createModalTitle" />
          )}
          {props.manageAction === ManageAction.Modify && (
            <FormattedMessage id="network.networkProfile.edit.modifyModalTitle" />
          )}
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <div className="ml-3">
        <h5 className="danger_text"> <FormattedMessage id="network.networkProfile.edit.requirednotifcation" /></h5>
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-4"
              required
              labelTranslationId="network.networkProfile.edit.lata"
              value={networkProfile.lata}
              handleInputChange={(value: string) =>
                setNetworkProfile({ ...networkProfile, lata: value })
              }
              errorHandler={getErrorHandler(nameOf<NetworkProfileDto>("lata"))}
            />
            <TextFormInput
              formGroupClassName="col-lg-4"
              required
              labelTranslationId="network.networkProfile.edit.lrn"
              value={networkProfile.lrn}
              handleInputChange={(value: string) =>
                setNetworkProfile({ ...networkProfile, lrn: value })
              }
              errorHandler={getErrorHandler(nameOf<NetworkProfileDto>("lrn"))}
            />
            <DropdownFormInput
              className="col-lg-4"
              required
              labelTranslationId="network.block.edit.svType"
              value={networkProfile.svType}
              handleInputChange={(value: string) =>
                setNetworkProfile({ ...networkProfile, svType: value })
              }
              errorHandler={getErrorHandler(nameOf<NetworkProfileDto>("svType"))}
              options={Object.keys(SvType).map((x) => {
                return { key: x, value: SvType[x] };
              })}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.endUserLocation"
              value={networkProfile.endUserLocation}
              handleInputChange={(value: string) =>
                setNetworkProfile({ ...networkProfile, endUserLocation: value })
              }
              errorHandler={getErrorHandler(nameOf<NetworkProfileDto>("endUserLocation"))}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.endUserLocationType"
              value={networkProfile.endUserLocationType}
              handleInputChange={(value: string) =>
                setNetworkProfile({ ...networkProfile, endUserLocationType: value })
              }
              errorHandler={getErrorHandler(nameOf<NetworkProfileDto>("endUserLocationType"))}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.billingId"
              value={networkProfile.billingId}
              handleInputChange={(value: string) =>
                setNetworkProfile({ ...networkProfile, billingId: value })
              }
              errorHandler={getErrorHandler(nameOf<NetworkProfileDto>("billingId"))}
            />
          </Row>
          <Row>
            <TextAreaFormInput
              className="col-lg-12"
              required
              labelTranslationId="network.networkProfile.edit.description"
              value={networkProfile.description}
              handleInputChange={(value: string) =>
                setNetworkProfile({ ...networkProfile, description: value })
              }
              errorHandler={getErrorHandler(nameOf<NetworkProfileDto>("description"))}
            />
          </Row>
          <h4>
            <FormattedMessage id="network.networkProfile.edit.networkData.header" />
          </h4>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.networkData.classDpc"
              value={networkProfile.networkData ? networkProfile.networkData.classDpc : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  networkData: networkProfile.networkData
                    ? { ...networkProfile.networkData, classDpc: value }
                    : { ...DefaultDpcSsnInfo, classDpc: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, DpcSsnInfo>("networkData", "classDpc")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.networkData.classSsn"
              value={networkProfile.networkData ? networkProfile.networkData.classSsn : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  networkData: networkProfile.networkData
                    ? { ...networkProfile.networkData, classSsn: value }
                    : { ...DefaultDpcSsnInfo, classSsn: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, DpcSsnInfo>("networkData", "classSsn")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.networkData.lidbDpc"
              value={networkProfile.networkData ? networkProfile.networkData.lidbDpc : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  networkData: networkProfile.networkData
                    ? { ...networkProfile.networkData, lidbDpc: value }
                    : { ...DefaultDpcSsnInfo, lidbDpc: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, DpcSsnInfo>("networkData", "lidbDpc")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.networkData.lidbSsn"
              value={networkProfile.networkData ? networkProfile.networkData.lidbSsn : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  networkData: networkProfile.networkData
                    ? { ...networkProfile.networkData, lidbSsn: value }
                    : { ...DefaultDpcSsnInfo, lidbSsn: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, DpcSsnInfo>("networkData", "lidbSsn")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.networkData.isvmDpc"
              value={networkProfile.networkData ? networkProfile.networkData.isvmDpc : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  networkData: networkProfile.networkData
                    ? { ...networkProfile.networkData, isvmDpc: value }
                    : { ...DefaultDpcSsnInfo, isvmDpc: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, DpcSsnInfo>("networkData", "isvmDpc")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.networkData.isvmSsn"
              value={networkProfile.networkData ? networkProfile.networkData.isvmSsn : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  networkData: networkProfile.networkData
                    ? { ...networkProfile.networkData, isvmSsn: value }
                    : { ...DefaultDpcSsnInfo, isvmSsn: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, DpcSsnInfo>("networkData", "isvmSsn")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.networkData.cnamDpc"
              value={networkProfile.networkData ? networkProfile.networkData.cnamDpc : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  networkData: networkProfile.networkData
                    ? { ...networkProfile.networkData, cnamDpc: value }
                    : { ...DefaultDpcSsnInfo, cnamDpc: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, DpcSsnInfo>("networkData", "cnamDpc")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.networkData.cnamSsn"
              value={networkProfile.networkData ? networkProfile.networkData.cnamSsn : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  networkData: networkProfile.networkData
                    ? { ...networkProfile.networkData, cnamSsn: value }
                    : { ...DefaultDpcSsnInfo, cnamSsn: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, DpcSsnInfo>("networkData", "cnamSsn")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.networkData.wsmscDpc"
              value={networkProfile.networkData ? networkProfile.networkData.wsmscDpc : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  networkData: networkProfile.networkData
                    ? { ...networkProfile.networkData, wsmscDpc: value }
                    : { ...DefaultDpcSsnInfo, wsmscDpc: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, DpcSsnInfo>("networkData", "wsmscDpc")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.networkData.wsmscSsn"
              value={networkProfile.networkData ? networkProfile.networkData.wsmscSsn : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  networkData: networkProfile.networkData
                    ? { ...networkProfile.networkData, wsmscSsn: value }
                    : { ...DefaultDpcSsnInfo, wsmscSsn: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, DpcSsnInfo>("networkData", "wsmscSsn")
              )}
            />
          </Row>
          <h4>
            <FormattedMessage id="network.networkProfile.edit.optionalData.header" />
          </h4>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.optionalData.altSpId"
              value={networkProfile.optionalData ? networkProfile.optionalData.altSpId : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  optionalData: networkProfile.optionalData
                    ? { ...networkProfile.optionalData, altSpId: value }
                    : { ...DefaultOptionalDataInfo, altSpId: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, OptionalDataInfo>("optionalData", "altSpId")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.optionalData.lastAltSpId"
              value={networkProfile.optionalData ? networkProfile.optionalData.lastAltSpId : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  optionalData: networkProfile.optionalData
                    ? { ...networkProfile.optionalData, lastAltSpId: value }
                    : { ...DefaultOptionalDataInfo, lastAltSpId: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, OptionalDataInfo>("optionalData", "lastAltSpId")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.optionalData.altBillingId"
              value={networkProfile.optionalData ? networkProfile.optionalData.altBillingId : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  optionalData: networkProfile.optionalData
                    ? { ...networkProfile.optionalData, altBillingId: value }
                    : { ...DefaultOptionalDataInfo, altBillingId: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, OptionalDataInfo>("optionalData", "altBillingId")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.optionalData.altUserLocationType"
              value={
                networkProfile.optionalData ? networkProfile.optionalData.altUserLocationType : ""
              }
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  optionalData: networkProfile.optionalData
                    ? { ...networkProfile.optionalData, altUserLocationType: value }
                    : { ...DefaultOptionalDataInfo, altUserLocationType: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, OptionalDataInfo>("optionalData", "altUserLocationType")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.optionalData.altUserLocationValue"
              value={
                networkProfile.optionalData ? networkProfile.optionalData.altUserLocationValue : ""
              }
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  optionalData: networkProfile.optionalData
                    ? { ...networkProfile.optionalData, altUserLocationValue: value }
                    : { ...DefaultOptionalDataInfo, altUserLocationValue: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, OptionalDataInfo>("optionalData", "altUserLocationValue")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.optionalData.voiceUri"
              value={networkProfile.optionalData ? networkProfile.optionalData.voiceUri : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  optionalData: networkProfile.optionalData
                    ? { ...networkProfile.optionalData, voiceUri: value }
                    : { ...DefaultOptionalDataInfo, voiceUri: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, OptionalDataInfo>("optionalData", "voiceUri")
              )}
            />
          </Row>
          <Row>
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.optionalData.mmsUri"
              value={networkProfile.optionalData ? networkProfile.optionalData.mmsUri : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  optionalData: networkProfile.optionalData
                    ? { ...networkProfile.optionalData, mmsUri: value }
                    : { ...DefaultOptionalDataInfo, mmsUri: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, OptionalDataInfo>("optionalData", "mmsUri")
              )}
            />
            <TextFormInput
              formGroupClassName="col-lg-6"
              labelTranslationId="network.networkProfile.edit.optionalData.smsUri"
              value={networkProfile.optionalData ? networkProfile.optionalData.smsUri : ""}
              handleInputChange={(value: string) =>
                setNetworkProfile({
                  ...networkProfile,
                  optionalData: networkProfile.optionalData
                    ? { ...networkProfile.optionalData, smsUri: value }
                    : { ...DefaultOptionalDataInfo, smsUri: value }
                })
              }
              errorHandler={getErrorHandler(
                nameOf<NetworkProfileDto, OptionalDataInfo>("optionalData", "smsUri")
              )}
            />
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={() => props.closeModal()}>
            <FormattedMessage id="network.networkProfile.edit.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className="ml-auto" disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="network.networkProfile.edit.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
