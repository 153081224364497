import React from "react";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

export default function FeedbackButton() {
  return (
    <span className="btn-inner--icon">
      <a href={isMixNetworkUrl ? "mailto:support@mixnetworks.com" : "mailto:lnp@atlc.com"} className="btn btn-primary btn-icon-only rounded-circle">
        <i className="fas fa-envelope"></i>
      </a>
    </span>
  );
}
