import React from "react";
import { Modal, Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import CloseButton from "./CloseButton";

type Props = {
  title: string;
  children: React.ReactNode;
  confirmButtonText?: string;
  showLoadingIndicator?: boolean;
  hideConfirmButton?: boolean;
  confirmCallback: () => void;
  closeModal: () => void;
};

export default function ConfirmationModal(props: Props) {
  const intl = useIntl();
  const confirmButtonText = props.confirmButtonText
    ? props.confirmButtonText
    : intl.formatMessage({ id: "confirmationModal.confirmButton" });

  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true} toggle={props.closeModal}>
      <div className="modal-header pb-0">
        <h6 className="modal-title" id="modal-title-default">
          {props.title}
        </h6>
        <CloseButton close={props.closeModal} />
      </div>
      <div className="modal-body pb-0">{props.children}</div>
      <div className="modal-footer">
        <Button color="link" type="button" onClick={props.closeModal}>
          <FormattedMessage id="confirmationModal.cancelButton" />
        </Button>
        {!props.hideConfirmButton && (
          <Button
            color="danger"
            type="button"
            className="ml-auto"
            onClick={props.confirmCallback}
            disabled={props.showLoadingIndicator}>
            {props.showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            {confirmButtonText}
          </Button>
        )}
      </div>
    </Modal>
  );
}
