import React, { useState, useContext, FormEvent, useEffect } from 'react';
import { Modal, Form, Button } from 'reactstrap';
import Datetime, { TimeConstraints } from 'react-datetime';
import moment, { Moment } from 'moment';
import { FormattedMessage } from 'react-intl'; 
import CloseButton from "components/framework/modals/CloseButton";
import { AppContext } from 'services/appContext/AppContext';
import { KeyValuePair } from 'services/types/KeyValuePair';
import Select from 'react-select';
import { OrderDto } from 'services/apis/types/order/OrderDto';
import { userZoneInfos } from "components/manage/user/UserTimeZones";
import { Link } from 'react-router-dom';

type Props = {
  closeModal: () => void;
  onSubmit: (selectedTime: string, selectedTimeZone: string, actionSelected) => void;
  isOrderDashboard?: boolean;
  order?: OrderDto;
  actionSelected?: string;
  selectedActivationTime?: string;
  selectedActivationTimeZone?: string
};

const timeConstraints: TimeConstraints = {
  hours: { min: 0, max: 23, step: 1 },
  minutes: { min: 0, max: 59, step: 10 }, // Set step to 10 for 10-minute intervals
  seconds: { min: 0, max: 59, step: 0 },
};

const autoActivationconv = (value: Moment | string | null) => {
  if (!value) {
    return null;
  }
  return moment.isMoment(value) ? value : moment(value, 'M/D/YYYY h:mm:ss A', true);
};

const calculateInitialTime = () => {
  const now = moment();
  const roundedMinutes = Math.ceil(now.minutes() / 10) * 10;
  return now.minutes(roundedMinutes).seconds(0);
}

export default function AutoActivationModal(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [selectedTime, setSelectedTime] = useState<Moment | null>(
    props.order?.autoActivation ? autoActivationconv(props.order?.autoActivation) : props.selectedActivationTime ? autoActivationconv(moment(props.selectedActivationTime)) : calculateInitialTime()
  );
  const { appContext } = useContext(AppContext);
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(
    props.order?.activationTimeZone ? props.order.activationTimeZone : props.selectedActivationTimeZone? props.selectedActivationTimeZone : appContext.localStorageInfo.user?.zoneInfo || 'UTC'
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);
    props.onSubmit(
      selectedTime?.format('hh:mm:ss A') || '',
      selectedTimeZone,
      props.actionSelected
    );
    setSelectedTime(autoActivationconv(selectedTime))
    handleCloseModal();
  };

  const handleTimeChange = (value: Moment | string) => {
      setSelectedTime(autoActivationconv(value));
  };

  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption.value);
  };

  const handleCloseModal = () => {
    setShowLoadingIndicator(false);
    setSelectedTime(null);
    props.closeModal();
  };

  
  const timezoneOptions = userZoneInfos.map((zone) => ({
    value: zone.value,
    label: zone.key,
  }));

  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id="orders.portIn.stepTwo.autoActivationTime" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          <div>
            <Datetime
              onChange={handleTimeChange}
              value={selectedTime || undefined}
              input={false}
              dateFormat={false}
              timeConstraints= {timeConstraints}
            />
          </div>
          <div className="mt-3">
            <h5 className="modal-title mb-2">
              <FormattedMessage id="orders.portIn.stepTwo.timeZone"></FormattedMessage>
            </h5>
            <Select
              value={{ value: selectedTimeZone, label: selectedTimeZone }}
              onChange={handleTimeZoneChange}
              options={timezoneOptions}
              className="time-zone-select"
              styles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                  padding: 0,
                }),
              }}
            />
          </div>
          {props.isOrderDashboard && (
            <div className="display-8 text-red">
              <FormattedMessage id="orders.autoActivation.confirmationModal.text" />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={props.closeModal}>
            {showLoadingIndicator && (
              <i className="fas fa-spinner fa-spin mr-2" />
            )}
            <FormattedMessage id="manage.serviceProvider.create.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className="ml-auto">
            <FormattedMessage id="manage.serviceProvider.create.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}