import React, { useRef, useState, useEffect } from "react";
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter, useExpanded } from "react-table";
import { lowerCaseIncludes } from "services/util/StringUtil";
import {
  Card,
  CardFooter,
  CardHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table,
  CardBody,
  Input,
  Dropdown,
  Tooltip
} from "reactstrap";
import DefaultColumnFilter from "./DefaultColumnFilter";
import { FormattedMessage, useIntl } from "react-intl";
import ExportButtons from "./ExportButtons";
import classnames from "classnames";
import { useUiTableSettings } from "./useUiTableSettings";
import TableSettingsModal from "./TableSettingsModal";
import { getTranslation } from "translations/TranslationService";
import { usePrint } from "./usePrint";
import IconWithTooltip from "components/common/IconWithTooltip";
import { useCallback } from "react";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import { RestoreFilterService } from "services/restoreFilters/RestoreFilterService";
import DropDown from "components/framework/table/DropDown";
import { OrderNumberActionConfigurations } from "components/orders/orderDetails/configuration/OrderNumberActionConfigurations";
import Icon from "components/common/Icon";
import TopColumnFilter from "./TopColumnFilter";
import { any } from "glamor";
import VerticalLoader from "components/common/VerticalLoader";

const emptyData = [];

export default function ReactTable({
  showViewSettings = false,
  identifier = undefined,
  children = undefined,
  title = undefined,
  titleComponent = undefined,
  showOrderOptions = undefined,
  columns,
  data,
  hasExpandedData = false,
  showExport = false,
  showCopy = true,
  showPrint = true,
  exportColumns = undefined,
  hideSearch = false,
  filterMapper = undefined,
  useColumnFilters = false,
  initialPageSize = undefined,
  onRefresh = undefined,
  maxHeight = undefined,
  customViewSettingsComponents = undefined,
  hiddenColumns = undefined,
  queryPageSize = 10,
  queryPageIndex = 0,
  queryCount = 0,
  handlePageChange = undefined,
  handlePageSizeChange = undefined,
  handleResetFilterChange = undefined,
  handleColumnSorted = undefined,
  handleSearchFilterKeywordChange = undefined,
  columnSortOptions = {},
  autoResetFilters = undefined,
  isCheckAutoActivation = false,
  toggleAutoActivationCheckbox = undefined,
  handleSearch= undefined,
  ...props

}) {
  const isLoading = data === undefined && !props.noContent;
  const printRef = useRef(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [manualSearchTerm, setManualSearchTerm] = useState(undefined);
  let tableHooks = [useGlobalFilter, useSortBy];
  const intl = useIntl();
  const manualPagination = handlePageChange !== undefined && handlePageSizeChange !== undefined;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [searchData, setSearchData] = useState(null);

  if (hasExpandedData) {
    tableHooks.push(useExpanded)
  }

  tableHooks.push(usePagination);

  if (useColumnFilters) {
    tableHooks = [useFilters, ...tableHooks];
  }

  const canFiltersRestore = identifier === TableIdentifiers.Orders || identifier === TableIdentifiers.WorkQueue;

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  );

  const resetFilters = () => {
    setAllFilters([]);
    setGlobalFilter("");
    if (identifier === TableIdentifiers.TradingPartner) {
      if (handleResetFilterChange) {
        handleResetFilterChange();
      }
    }
    if (canFiltersRestore) {
      RestoreFilterService.clearFilter(identifier);
      if (handleResetFilterChange) {
        handleResetFilterChange(identifier);
      }
      
    }
  };


  const filteredResults = useRef([]);

  const globalFilter = useCallback((rows, _accessors, value) => {
    const filteredParentIds = new Set();
    const childIdToParentMap = new Map();

    rows.forEach(row => {
        if (row.originalSubRows) {
            row.originalSubRows.forEach(subRow => childIdToParentMap.set(subRow.id, row.id));
        }
    });

    const rowMatchesFilter = (row) => {
        const filterable = filterMapper ? filterMapper(row) : row;
        return _accessors.some(accessor => lowerCaseIncludes(value, filterable[accessor]));
    };

    return rows.filter(row => {
        const matches = rowMatchesFilter(row.original);

        if (matches) {
            filteredParentIds.add(row.id);
            if (row.originalSubRows) {
                row.originalSubRows.forEach(subRow => filteredParentIds.add(subRow.id));
            }
        } else if (row.originalSubRows) {
            const subRowMatches = row.originalSubRows.some(subRow => {
                const subRowMatches = rowMatchesFilter(subRow);
                if (subRowMatches) {
                    filteredParentIds.add(row.id);
                    filteredParentIds.add(subRow.id);
                }
                return subRowMatches;
            });
            return subRowMatches;
        }

        return matches;
    }).filter(row => {
        if (filteredParentIds.has(row.id)) {
            return true;
        }
        const parentId = childIdToParentMap.get(row.id);
        return parentId ? filteredParentIds.has(parentId) : false;
    });
}, [filterMapper]);



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    setAllFilters,
    setHiddenColumns
  } = useTable(
    {
      columns,
      globalFilter: globalFilter,
      data: data ?? emptyData,
      autoResetFilters: autoResetFilters,
      defaultColumn,
      manualPagination: manualPagination,
      ...(manualPagination && { pageCount: Math.ceil(queryCount / queryPageSize) }),
      initialState: {
        pageIndex: manualPagination ? queryPageIndex : 0,
        pageSize: manualPagination ? queryPageSize : initialPageSize ?? 10,
        identifier: identifier
      }
    },
    ...tableHooks
  );

  const { userHiddenColumnsAccessors } = useUiTableSettings(showViewSettings, identifier, columns);
  const { onAfterPrint, onBeforePrint } = usePrint(setHiddenColumns, userHiddenColumnsAccessors);
  const [nonUserHiddenColumns] = useState(hiddenColumns);

  useEffect(() => {
    if (userHiddenColumnsAccessors?.length > 0)
      setHiddenColumns(userHiddenColumnsAccessors);

    if (nonUserHiddenColumns?.length > 0)
      setHiddenColumns(nonUserHiddenColumns);

  }, [setHiddenColumns, userHiddenColumnsAccessors, nonUserHiddenColumns]);

  const onPageChange = ((page) => {
    if (manualPagination) {
      handlePageChange(page);
    }
  });

  const onPageSizeChange = ((pageSize) => {
    if (manualPagination) {
      handlePageSizeChange(pageSize); gotoPage(0);
    }
    else {
      setPageSize(pageSize)
    }
    if (canFiltersRestore) {
      RestoreFilterService.setPageSize(identifier, pageSize)
    }
  });


  const anyFilters =
    headerGroups.filter((x) => x.headers.filter((column) => column.filter).length).length > 0;

  const hasPagination = (manualPagination && (queryCount > queryPageSize || queryCount > Math.min(...pageSizes)))
    || (!manualPagination && (rows.length > state.pageSize || rows.length > Math.min(...pageSizes)))
  let hasFilterRestore = false;

  if (canFiltersRestore) {
    hasFilterRestore = RestoreFilterService.hasFilter(identifier);
  }

  if (canFiltersRestore) {
    state.pageSize = RestoreFilterService.getPageSize(identifier, state.pageSize);
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const [dropdownItems, setDropdownItems] = useState([]);

  useEffect(() => {
    setDropdownItems(props.orderActions && props.orderActions.length
      ? props.orderActions.map((action, index) => (
        <>
          <DropdownItem key={index} onClick={() => props.handleActionSelected(action)}>
            <span>
              <Icon
                className={`${OrderNumberActionConfigurations[action].Number.iconClass} mr-2 cursor-pointer`}
                title={action}
              />
            </span>
            {action}
          </DropdownItem>
        </>
      ))
      : <DropdownItem disabled />
      )
    // eslint-disable-next-line 
  }, [props.orderActions])

  return (
    <Card className="mb-0" style={{ maxHeight: maxHeight }}>
      {children && (
        <CardHeader className="p-3">
          <Row className="align-items-center">
            <Col xs="6">{title && <h3 className="mb-0">{title}</h3>}</Col>
            <Col className="text-right" xs="6">
              {children}
            </Col>
          </Row>
        </CardHeader>
      )}
      {((title && !children) || !hideSearch || titleComponent) && (
        <div className="d-flex p-3">

              {(title && !children)
                ? (<div className="flex-fill d-flex align-items-center">
                      <h3 className="mb-0">{title}</h3>
                  </div>) 
                : titleComponent && 
                    (<>{titleComponent}</>)
              }

          <div className="d-flex align-items-center mr-3">
            {((anyFilters && !canFiltersRestore) || hasFilterRestore) && (
              <IconWithTooltip
                className="fas fa-times-circle cursor-pointer mx-2"
                onClick={resetFilters}
                tooltip={getTranslation(intl, "table.clearFilters")}
              />
            )}
            {onRefresh && (
              <IconWithTooltip
                className="fas fa-sync-alt cursor-pointer mx-2"
                onClick={onRefresh}
                tooltip={getTranslation(intl, "table.refresh")}
              />
            )}
            {showViewSettings && identifier && (
              <>
                <IconWithTooltip
                  className="fas fa-cog cursor-pointer mx-2"
                  onClick={() => setShowSettingsModal(true)}
                  tooltip={getTranslation(intl, "table.viewSettings")}
                />

                {showSettingsModal && (
                  <TableSettingsModal
                    tableIdentifier={identifier}
                    columns={columns}
                    closeModal={() => setShowSettingsModal(false)}
                    children={customViewSettingsComponents}
                  >
                  </TableSettingsModal>
                )}
              </>
            )}
            {(toggleAutoActivationCheckbox && (
              <>
                <Input
                  className="fas cursor-pointer mx-2"
                  id="autoActivationCheckbox"
                  type="checkbox"
                  onChange={() => toggleAutoActivationCheckbox(!isCheckAutoActivation)}
                  checked={isCheckAutoActivation}
                  style={{ position: 'relative', zIndex: 1, marginTop: 0, marginLeft: 0, transform: 'scale(1.25)' }}
                  onMouseEnter={() => setTooltipOpen(true)}
                  onMouseLeave={() => setTooltipOpen(false)}
                />
                {tooltipOpen && (
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="autoActivationCheckbox"
                    toggle={() => setTooltipOpen(!tooltipOpen)}
                  >
                    {getTranslation(intl, "table.autoActivationFilters")}
                  </Tooltip>
                )}
            </>
            )
            )}
          </div>

          {((props.showDropDown && page.length > 0) || props.noContent) && (
              <DropDown
                options={props.options}
                onInputChange={props.onInputChange}
                selectedValue={props.selectedValue}
                handleSelect={props.handleSelect}
              />
          )}

          {showExport && page.length > 0 && (
            <div className="d-flex align-items-center ml-2 mr-3">
              <ExportButtons
                data={data}
                columns={exportColumns ?? columns}
                hasExpandedData={hasExpandedData}
                printRef={printRef}
                onAfterPrint={onAfterPrint}
                onBeforePrint={onBeforePrint}
                showCopy={showCopy}
                showPrint={showPrint}
              />
            </div>
          )}

          {!hideSearch && (
            <div className="ml-2 mt-2">
              <Input
                bsSize="sm"
                type="text"
                value={manualSearchTerm || state.globalFilter || ""}
                placeholder={"Search"}
                onChange={(e) => {
                  if (handleSearchFilterKeywordChange === undefined) {
                    setGlobalFilter(e.target.value || undefined);
                  }
                  else {
                    setManualSearchTerm(e.target.value || undefined);
                    handleSearchFilterKeywordChange(e.target.value || undefined);
                  }

                  gotoPage(0);
                }}
              />
            </div>
          )}
        </div>
      )}
      {props.hasCustomFilters && <TopColumnFilter options={props.options} onSearch={handleSearch}/>}
      {showOrderOptions && (
        <>
          <hr className="my-0" />
          <div className="d-flex p-3 order-options">
            <div className="flex-fill d-flex align-items-center">
              <h3 className="mb-0 text-primary" onClick={props.handleSelectAll}> {props.selectedCount ? intl.formatMessage({ id: "table.order.unSelect" }, { orderCount: props.selectedCount }) : intl.formatMessage({ id: "table.order.selectAll" })}</h3>
            </div>
            {props.orderActions && <div className="d-flex align-items-center mr-3">
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} disabled={props.orderActions && props.orderActions.length === 0}>
                <DropdownToggle className="order-actions-dropdown"
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    padding: 0
                  }}>
                  <i className="fa fa-bars fa-fw" aria-hidden="true" ></i>
                </DropdownToggle>
                <DropdownMenu right>
                  {dropdownItems}
                </DropdownMenu>
              </Dropdown>
            </div>}
          </div>
        </>
      )}
      <div
        className={`table-responsive ${classnames({
          "pc-overflow-x-auto": !anyFilters
        })}`}
        ref={printRef}>
        <Table {...getTableProps()} className="table">
          <thead className="thead-light">
           {handleColumnSorted === undefined && headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={classnames("p-3", column?.className, {
                      "print-only": userHiddenColumnsAccessors.includes(column.id)
                    })}>
                    <div className={classnames({ "pc-table-header": anyFilters })}>
                          <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fas fa-sort-down fa-lg" />
                                ) : (
                                  <i className="fas fa-sort-up fa-lg" />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                      <div>{column.canFilter && column.filter && column.render("Filter")}</div>
                    </div>
                  </th>
                ))}
              </tr>
            ))
           }
           {handleColumnSorted && headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={classnames("p-3", column?.className, {
                      "print-only": userHiddenColumnsAccessors.includes(column.id)
                    })}>
                    <div className={classnames({ "pc-table-header": anyFilters })}>
                          <div {...column.getHeaderProps(column.getSortByToggleProps())} onClick={()=>handleColumnSorted && handleColumnSorted(column)}>
                            {column.render("Header")}
                            <span>
                              {columnSortOptions && columnSortOptions[column.identifier]?.isSorted ? (
                                columnSortOptions && columnSortOptions[column.identifier]?.isSortedDesc ? (
                                  <i className="fas fa-sort-down fa-lg" />
                                ) : (
                                  <i className="fas fa-sort-up fa-lg" />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                      <div>{column.canFilter && column.filter && column.render("Filter")}</div>
                    </div>
                  </th>
                ))}
              </tr>
            ))
           }
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(
              (row) =>
                prepareRow(row) || (
                  <tr {...row.getRowProps()} className={props.selectedOrders && props.selectedOrders.findIndex(x => x.orderId === row.original.orderId) > -1 ? 'selected-row' : ''}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps()}
                          className={classnames("p-3", cell.column.className, {
                            "print-only": userHiddenColumnsAccessors.includes(cell.column.id)
                          })}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                )
            )}
          </tbody>
        </Table>
      </div>
      {isLoading ? (
        <CardBody className="text-center">
          {/* <FormattedMessage id="table.isLoading" /> */}
          <VerticalLoader/>
        </CardBody>
      ) : hasPagination ? (
        <CardFooter className="py-4">
          <Row className="align-items-center">
            <Col>
              <UncontrolledDropdown size="sm">
                <DropdownToggle caret color="secondary">
                  <FormattedMessage id="table.showItems" values={{ pageSize: manualPagination ? queryPageSize : state.pageSize }} />
                </DropdownToggle>
                <DropdownMenu>
                  {pageSizes.map((pageSize) => (
                    <DropdownItem key={pageSize} onClick={() => onPageSizeChange(pageSize)}>
                      <FormattedMessage id="table.showItems" values={{ pageSize: pageSize }} />
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col className="text-center">
              <FormattedMessage
                id="table.pagination"
                values={{
                  page: <b>{manualPagination ? queryPageIndex + 1 : state.pageIndex + 1}</b>,
                  numberOfPages: <b>{pageOptions.length}</b>
                }}
              />
            </Col>
            <Col>
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0">
                <PaginationItem className={!canPreviousPage ? "disabled" : ""}>
                  <PaginationLink onClick={() => { gotoPage(0); onPageChange(0) }} tabIndex="-1">
                    <i className="fas fa-angle-double-left" />
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={!canPreviousPage ? "disabled" : ""}>
                  <PaginationLink onClick={() => { previousPage(); onPageChange(queryPageIndex - 1) }} tabIndex="-1">
                    <i className="fas fa-angle-left" />
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={!canNextPage ? "disabled" : ""}>
                  <PaginationLink onClick={() => { nextPage(); onPageChange(queryPageIndex + 1) }}>
                    <i className="fas fa-angle-right" />
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={!canNextPage ? "disabled" : ""}>
                  <PaginationLink onClick={() => { gotoPage(pageCount - 1); onPageChange(pageCount - 1) }}>
                    <i className="fas fa-angle-double-right" />
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>
        </CardFooter>
      ) : (
        rows.length <= 0 && (
          <CardBody className="py-4 text-center">
            {props.noContent ? <FormattedMessage id="table.noPooledNumbers" /> : <FormattedMessage id="table.noData" /> }
          </CardBody>
        )
      )}
    </Card>
  );
}

const pageSizes = [10, 20, 50, 100];
