import React from "react";
import { Modal, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import CloseButton from "components/framework/modals/CloseButton";

type Props = {
  closeModal: () => void;
}

export default function SupplementWarningModal(props: Props) {

  const handleCloseWarningModal = function() {
    props.closeModal();
  }
  
  return (
    <>
      <Modal className="modal-dialog-centered modal-md" isOpen={true} centered backdrop="static">
        <div className="modal-header pb-0">
          <h6 className="modal-title">Warning</h6>
          <CloseButton close={props.closeModal} />
        </div>
        <div className="modal-body pb-0">
          <p>
          <FormattedMessage id={"orders.order.actions.supplementwarningmodal"}/>
          </p>
        </div>
        <div className="modal-footer">
          <Button color="danger" type="button" className="ml-auto"  onClick={handleCloseWarningModal}>
            <FormattedMessage id="orders.orderDetails.portingNumber.table.close" />
          </Button>
        </div>
      </Modal>
    </>
  );
}