import React, { useState, FormEvent, useCallback } from "react";
import { Modal, Form, Button, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { NumberStatusDto } from "services/apis/types/order/NumberStatusDto";
import { useErrors } from "services/customHooks/useErrors";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { HasErrors, Errors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { PortApi } from "services/apis/PortApi";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import { Moment } from "moment";
import { ModifyModel } from "services/apis/types/port/ModifyModel";
import { isPhoneNumber, isRange, getValueOrEmpty } from "services/util/StringUtil";
import { DisconnectModify, DefaultDisconnectModify } from "../types/DisconnectModify";
import { OrderApi } from "services/apis/OrderApi";
import { DefaultDisconnectModifyModel } from "services/apis/types/port/DisconnectModifyModel";
import PhoneNumberInput from "components/framework/forms/PhoneNumberInput";

type Props = {
  orderId: string;
  tenant?: string;
  numberStatus?: NumberStatusDto;
  closeModal: () => void;
};

export default function DisconnectModifyModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [disconnectModify, setDisconnectModify] = useState<DisconnectModify>({
    ...DefaultDisconnectModify,
    phoneNumberRange: props.numberStatus
      ? props.numberStatus.phoneNumber
      : DefaultDisconnectModify.phoneNumberRange,
    disconnectModifyModel: {
      ...DefaultDisconnectModifyModel
    }
  });
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const closeModal = useCallback(props.closeModal, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const errors = validateModify(disconnectModify, props.numberStatus);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowLoadingIndicator(true);

      const model = {
        modifyType: disconnectModify.disconnectModifyModel.modifyType,
        portDirection: disconnectModify.disconnectModifyModel.portDirection,
        disconnectDate: disconnectModify.disconnectModifyModel.disconnectDate.toDate(),
        releaseDate: disconnectModify.disconnectModifyModel.releaseDate.toDate(),
        orderId: props.orderId
      };

      const api = props.numberStatus
        ? PortApi.modify(disconnectModify.phoneNumberRange, model)
        : OrderApi.modify(props.orderId, getValueOrEmpty(props.tenant), model);

      api
        .then(() => {
          showInfoNotification(
            intl.formatMessage({
              id: props.numberStatus
                ? "orders.modify.edit.numberSuccessNotificationMessage"
                : "orders.modify.edit.orderSuccessNotificationMessage"
            })
          );
          closeModal();
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered modal-sm" isOpen={true}>
        <div className="modal-header">
          <h5 className="modal-title">
            <FormattedMessage id="orders.modify.edit.title" />
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.closeModal()}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <div className="modal-body">
            <Row>
              {props.numberStatus ? (
                <PhoneNumberInput
                  formGroupClassName="col-lg-12"
                  labelTranslationId="orders.modify.edit.tnRange"
                  placeholderTranslationId="orders.modify.edit.tnRange.placeholder"
                  value={disconnectModify.phoneNumberRange}
                  handleInputChange={(value: string) =>
                    setDisconnectModify({ ...disconnectModify, phoneNumberRange: value })
                  }
                  errorHandler={getErrorHandler(nameOf<DisconnectModify>("phoneNumberRange"))}
                />
              ) : (
                <TextFormInput
                  formGroupClassName="col-lg-12"
                  labelTranslationId="orders.modify.edit.orderId"
                  value={props.orderId}
                  readonly
                />
              )}
            </Row>
            <Row>
              <DateTimeFormInput
                className="col-lg-12"
                labelTranslationId="orders.modify.edit.disconnectDate"
                value={disconnectModify.disconnectModifyModel.disconnectDate}
                handleInputChange={(value: Moment) =>
                  setDisconnectModify({
                    ...disconnectModify,
                    disconnectModifyModel: {
                      ...disconnectModify.disconnectModifyModel,
                      disconnectDate: value
                    }
                  })
                }
                errorHandler={getErrorHandler(
                  nameOf<DisconnectModify, ModifyModel>("disconnectModifyModel", "disconnectDate")
                )}
              />
            </Row>
            <Row>
              <DateTimeFormInput
                className="col-lg-12"
                labelTranslationId="orders.modify.edit.disconnectTime"
                value={disconnectModify.disconnectModifyModel.disconnectDate}
                handleInputChange={(value: Moment) =>
                  setDisconnectModify({
                    ...disconnectModify,
                    disconnectModifyModel: {
                      ...disconnectModify.disconnectModifyModel,
                      disconnectDate: value
                    }
                  })
                }
                isTimeInput
                errorHandler={getErrorHandler(
                  nameOf<DisconnectModify, ModifyModel>("disconnectModifyModel", "disconnectDate")
                )}
              />
            </Row>
            <Row>
              <DateTimeFormInput
                className="col-lg-12"
                labelTranslationId="orders.modify.edit.releaseDate"
                value={disconnectModify.disconnectModifyModel.releaseDate}
                handleInputChange={(value: Moment) =>
                  setDisconnectModify({
                    ...disconnectModify,
                    disconnectModifyModel: {
                      ...disconnectModify.disconnectModifyModel,
                      releaseDate: value
                    }
                  })
                }
                errorHandler={getErrorHandler(
                  nameOf<DisconnectModify, ModifyModel>("disconnectModifyModel", "releaseDate")
                )}
              />
            </Row>
            <Row>
              <DateTimeFormInput
                className="col-lg-12"
                labelTranslationId="orders.modify.edit.releaseTime"
                value={disconnectModify.disconnectModifyModel.releaseDate}
                handleInputChange={(value: Moment) =>
                  setDisconnectModify({
                    ...disconnectModify,
                    disconnectModifyModel: {
                      ...disconnectModify.disconnectModifyModel,
                      releaseDate: value
                    }
                  })
                }
                isTimeInput
                errorHandler={getErrorHandler(
                  nameOf<DisconnectModify, ModifyModel>("disconnectModifyModel", "releaseDate")
                )}
              />
            </Row>
          </div>
          <div className="modal-footer">
            <Button color="link" type="button" onClick={() => props.closeModal()}>
              <FormattedMessage id="orders.modify.edit.cancelButton" />
            </Button>
            <Button
              color="primary"
              type="submit"
              className="ml-auto"
              disabled={showLoadingIndicator}>
              {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="orders.modify.edit.submitButton" />
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

const validateModify = (modify: DisconnectModify, numberStatus?: NumberStatusDto) => {
  const errors: Errors = {};

  if (numberStatus) {
    if (!modify.phoneNumberRange) {
      AddError(
        errors,
        nameOf<DisconnectModify>("phoneNumberRange"),
        "orders.modify.edit.tnRange.required"
      );
    } else if (!isPhoneNumber(modify.phoneNumberRange) && !isRange(modify.phoneNumberRange)) {
      AddError(
        errors,
        nameOf<DisconnectModify>("phoneNumberRange"),
        "orders.modify.edit.tnRange.invalid"
      );
    }
  }

  return errors;
};
