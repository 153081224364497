import { SvRequestStatus } from "components/orders/types/SvRequestStatus";
import { SvRequestStatusConfiguration } from "components/orders/types/SvRequestStatusConfiguration";

export const SvRequestStatusConfigurations: Record<
  SvRequestStatus,
  SvRequestStatusConfiguration
> = {
  Pending: {
    backgroundColor: "bg-gradient-green",
    iconClass: "far fa-clock",
    messageId: "orders.orderDetails.pendingSvStatusDescription"
  },
  ActionRequired: {
    iconClass: "far fa-exclamation-triangle",
    backgroundColor: "bg-gradient-danger",
    messageId: "orders.orderDetails.attentionRequiredSvStatusDescription"
  },
  ActivatePending: {
    iconClass: "far fa-check",
    backgroundColor: "bg-gradient-success",
    messageId: "orders.orderDetails.activatePendingRequiredSvStatusDescription"
  },
  Active: {
    iconClass: "fad fa-check-double",
    backgroundColor: "bg-gradient-blue",
    messageId: "orders.orderDetails.activatedSvStatusDescription"
  },
  Authorized: {
    backgroundColor: "bg-gradient-green",
    iconClass: "far fa-check",
    messageId: "orders.orderDetails.authorizedSvStatusDescription"
  },
  CancelPending: {
    iconClass: "far fa-ban",
    backgroundColor: "bg-gradient-gray-dark",
    messageId: "orders.orderDetails.cancelPendingSvStatusDescription"
  },
  Canceled: {
    iconClass: "far fa-ban",
    backgroundColor: "bg-gradient-gray-dark",
    messageId: "orders.orderDetails.canceledSvStatusDescription"
  },
  Conflict: {
    iconClass: "far fa-handshake",
    backgroundColor: "bg-gradient-orange",
    messageId: "orders.orderDetails.conflictSvStatusDescription"
  },
  DisconnectPending: {
    iconClass: "far fa-ban",
    backgroundColor: "bg-gradient-gray-dark",
    messageId: "orders.orderDetails.disconnectPendingSvStatusDescription"
  },
  Failed: {
    iconClass: "far fa-times-octagon",
    backgroundColor: "bg-gradient-red",
    messageId: "orders.orderDetails.failedSvStatusDescription"
  },
  Old: {
    iconClass: "far fa-exclamation-triangle",
    backgroundColor: "bg-gradient-gray-dark",
    messageId: "orders.orderDetails.oldSvStatusDescription"
  },
  PartialFailure: {
    iconClass: "far fa-times-octagon",
    backgroundColor: "bg-gradient-orange",
    messageId: "orders.orderDetails.partialFailureSvStatusDescription"
  },
  Sending: {
    backgroundColor: "bg-gradient-info",
    iconClass: "far fa-file-import",
    messageId: "orders.orderDetails.sendingSvStatusDescription"
  },
  PreOrderPending: {
    backgroundColor: "bg-gradient-info",
    iconClass: "far fa-clock",
    messageId: "orders.orderDetails.preOrderPendingSvStatusDescription"
  },
  ReceivedFoc: {
    backgroundColor: "bg-gradient-info",
    iconClass: "fab fa-get-pocket",
    messageId: "orders.orderDetails.receivedFocSvStatusDescription"
  },
  Rejected: {
    backgroundColor: "bg-gradient-danger",
    iconClass: "far fa-ban",
    messageId: "orders.orderDetails.rejectedSvStatusDescription"
  },
  Submitted: {
    backgroundColor: "bg-gradient-info",
    iconClass: "fal fa-file-check",
    messageId: "orders.orderDetails.submittedSvStatusDescription"
  },
  CSRPending: {
    backgroundColor: "bg-gradient-info",
    iconClass: "far fa-clock",
    messageId: "orders.orderDetails.csrPendingSvStatusDescription"
  },
  CSRRequested: {
    backgroundColor: "bg-gradient-info",
    iconClass: "far fa-clock",
    messageId: "orders.orderDetails.csrRequestedSvStatusDescription"
  },
  CSRInvalid: {
    backgroundColor: "bg-gradient-info",
    iconClass: "far fa-clock",
    messageId: "orders.orderDetails.csrInvalidSvStatusDescription"
  },
  CSRModified: {
    backgroundColor: "bg-gradient-info",
    iconClass: "far fa-clock",
    messageId: "orders.orderDetails.csrModifiedSvStatusDescription"
  },
  CSREscalated: {
    backgroundColor: "bg-gradient-warning",
    iconClass: "far fa-clock",
    messageId: "orders.orderDetails.csrEscalatedSvStatusDescription"
  },
  CSRApproved: {
    backgroundColor: "bg-gradient-info",
    iconClass: "far fa-clock",
    messageId: "orders.orderDetails.csrApprovedSvStatusDescription"
  },
  CSRReceived: {
    backgroundColor: "bg-gradient-success",
    iconClass: "far fa-clock",
    messageId: "orders.orderDetails.csrReceivedSvStatusDescription"
  }
};
