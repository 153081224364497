import React, { useState } from "react";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { FormattedMessage } from "react-intl";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import QuerySvTable from "./QuerySvTable";
import CreateQueryModal from "./CreateQueryModal";
import Authorize from "components/framework/authorization/Authorize";
import { PermissionType } from "services/authorization/PermissionType";

export default function QuerySv() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dropdownToggle = () => setShowDropdown((prevState) => !prevState);

  const handleCreateDropdownItemButtonClick = () => {
    setShowModal(true);
    setShowDropdown(false);
  };

  return (
    <>
      <BreadcrumbHeader>
        <FormattedMessage id="querySv.title" />
      </BreadcrumbHeader>
      <Container className="mb-5">
        <Row className="align-items-center">
          <Col className="offset-md-3">
            <h1 className="display-3 text-blue">
              <FormattedMessage id="querySv.title" />
            </h1>
          </Col>
          <Col>
            <Authorize spidPermissions={[PermissionType.SOAWrite]}>
              <div className="pc-create-dropdown">
                <Dropdown isOpen={showDropdown} toggle={dropdownToggle}>
                  <DropdownToggle className="cursor-pointer pb-0 pt-0v" tag="span">
                    <button className="btn pc-btn-audit">
                      <i className="fas fa-plus-square mr-2" />
                      <FormattedMessage id="querySv.create" />
                    </button>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-lg dropdown-menu-white bg-dark pc-no-shadow text-white">
                    <Row className="shortcuts px-4">
                      <Col
                        className="shortcut-item cursor-pointer"
                        onClick={() => handleCreateDropdownItemButtonClick()}
                        xs="4">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue">
                          <i className="fa fa-check-double" />
                        </span>
                        <small>
                          <FormattedMessage id="querySv.create.query" />
                        </small>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Authorize>
          </Col>
        </Row>
      </Container>
      <Container>
        <QuerySvTable triggerRefresh={triggerRefresh} searchValue={""} />
        {showModal && (
          <CreateQueryModal
            closeModal={() => setShowModal(false)}
            triggerRefresh={() => setTriggerRefresh((p) => !p)}
          />
        )}
      </Container>
    </>
  );
}
