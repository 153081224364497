import React, { useState, FormEvent, useCallback, useContext, useEffect } from "react";
import { Modal, Form, Button, Row, FormGroup } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { CompanyApi } from "services/apis/CompanyApi";
import { ManageAction } from "components/manage/types/ManageAction";
import { ManageContext } from "../Manage";
import { CompanyInfo } from "services/apis/types/company/CompanyInfo";
import { DefaultCompany, Company } from "components/manage/types/Company";
import { HasErrors, AddError, Errors } from "components/framework/errorHandling/ErrorUtil";
import { isEmail, isValidZipCode } from "services/util/StringUtil";
import { CompanyDto, DefaultCompanyDto } from "services/apis/types/company/CompanyDto";
import CloseButton from "components/framework/modals/CloseButton";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { PermissionType } from "services/authorization/PermissionType";
import { authorize } from "services/authorization/AuthorizationService";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { CompanyClientType } from "../serviceProvider/CompanyClientType";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;

type Props = {
  closeModal: () => void;
  companyId?: string;
  availableCompanies: CompanyInfo[];
  manageAction: ManageAction;
};


export default function OrganizationModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [company, setCompany] = useState<Company>(DefaultCompany);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const manageContext = useContext(ManageContext);
  const [userIsAdmin] = useState(authorize(PermissionType.Admin));

  const closeModal = useCallback(props.closeModal, []);

  useEffect(() => {
    if (props.manageAction === ManageAction.Modify && props.companyId) {
      CompanyApi.get(props.companyId)
        .then(result => {
          if (isMounted.current) {
            setCompany({
              id: result.id,
              companyDto: {
                parentId: result.parent ? result.parent.id : DefaultCompanyDto.parentId,
                name: result.name,
                addressLine1: result.addressLine1,
                addressLine2: result.addressLine2,
                city: result.city,
                state: result.state,
                zipCode: result.zipCode,
                primaryContactEmail: result.primaryContact
                  ? result.primaryContact.email
                  : DefaultCompanyDto.primaryContactEmail,
                notificationEmail: result.notificationEmail,
                billingId: result.billingId,
                tenantId: result.tenantId,
                nonSpId: result.nonSpId,
                csrEnabled: result.csrEnabled,
                clientType: result.clientType,
              }
            });
          }
        })
        .catch(error => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  }, [isMounted, props.companyId, props.manageAction, setErrors]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    const errors = validateCompany(company);

    if (HasErrors(errors)) {
      setErrors(errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);
      }
    } else if (props.manageAction === ManageAction.Add) {
      CompanyApi.add(company.companyDto)
        .then(() => {
          showInfoNotification(intl.formatMessage({ id: "manage.organization.edit.successNotificationMessage" }));
          manageContext.organizationCallback();
          closeModal();
        })
        .catch(error => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    } else if (props.manageAction === ManageAction.Modify) {
      CompanyApi.modify(company.id, company.companyDto)
        .then(() => {
          showInfoNotification(intl.formatMessage({ id: "manage.organization.edit.successNotificationMessage" }));
          manageContext.organizationCallback();
          closeModal();
        })
        .catch(error => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered modal-lg" isOpen={true}>
        <div className="modal-header">
          <h5 className="modal-title">
            {!props.companyId && <FormattedMessage id="manage.organization.edit.createModalTitle" />}
            {props.companyId && <FormattedMessage id="manage.organization.edit.modifyModalTitle" />}
          </h5>
          <CloseButton close={props.closeModal} />
        </div>
        <Form onSubmit={e => handleSubmit(e)}>
          <div className="modal-body">
            <Row>
              <TextFormInput
                formGroupClassName="col-lg-6"
                required
                labelTranslationId="manage.organization.edit.name"
                value={company.companyDto.name}
                handleInputChange={(value: string) =>
                  setCompany({ ...company, companyDto: { ...company.companyDto, name: value } })
                }
                errorHandler={getErrorHandler(nameOf<CompanyDto>("name"))}
              />
              <DropdownFormInput
                className="col-lg-6"
                labelTranslationId="manage.organization.edit.parentId"
                required
                value={company.companyDto.parentId}
                handleInputChange={(value: string) =>
                  setCompany({ ...company, companyDto: { ...company.companyDto, parentId: value } })
                }
                errorHandler={getErrorHandler(nameOf<CompanyDto>("parentId"))}
                options={[
                  { key: "None", value: "" },
                  ...props.availableCompanies.map(x => {
                    return { key: x.name, value: x.id };
                  })
                ]}
              />
            </Row>
            <Row>
              <TextFormInput
                formGroupClassName="col-lg-6"
                labelTranslationId="manage.organization.edit.addressLine1"
                value={company.companyDto.addressLine1}
                handleInputChange={(value: string) =>
                  setCompany({
                    ...company,
                    companyDto: { ...company.companyDto, addressLine1: value }
                  })
                }
                errorHandler={getErrorHandler(nameOf<CompanyDto>("addressLine1"))}
              />
              <TextFormInput
                formGroupClassName="col-lg-6"
                labelTranslationId="manage.organization.edit.addressLine2"
                value={company.companyDto.addressLine2}
                handleInputChange={(value: string) =>
                  setCompany({
                    ...company,
                    companyDto: { ...company.companyDto, addressLine2: value }
                  })
                }
                errorHandler={getErrorHandler(nameOf<CompanyDto>("addressLine2"))}
              />
            </Row>
            <Row>
              <TextFormInput
                formGroupClassName="col-lg-6"
                labelTranslationId="manage.organization.edit.city"
                value={company.companyDto.city}
                handleInputChange={(value: string) =>
                  setCompany({ ...company, companyDto: { ...company.companyDto, city: value } })
                }
                errorHandler={getErrorHandler(nameOf<CompanyDto>("city"))}
              />
              <TextFormInput
                formGroupClassName="col-lg-6"
                labelTranslationId="manage.organization.edit.state"
                value={company.companyDto.state}
                handleInputChange={(value: string) =>
                  setCompany({ ...company, companyDto: { ...company.companyDto, state: value } })
                }
                errorHandler={getErrorHandler(nameOf<CompanyDto>("state"))}
              />
            </Row>
            <Row>
              <TextFormInput
                formGroupClassName="col-lg-6"
                required
                labelTranslationId="manage.organization.edit.zipCode"
                value={company.companyDto.zipCode}
                handleInputChange={(value: string) =>
                  setCompany({ ...company, companyDto: { ...company.companyDto, zipCode: value } })
                }
                errorHandler={getErrorHandler(nameOf<CompanyDto>("zipCode"))}
              />
              <TextFormInput
                formGroupClassName="col-lg-6"
                required
                labelTranslationId="manage.organization.edit.primaryContactEmail"
                value={company.companyDto.primaryContactEmail}
                handleInputChange={(value: string) =>
                  setCompany({
                    ...company,
                    companyDto: { ...company.companyDto, primaryContactEmail: value }
                  })
                }
                errorHandler={getErrorHandler(nameOf<CompanyDto>("primaryContactEmail"))}
              />
            </Row>
            <Row>
              <TextFormInput
                formGroupClassName="col-lg-6"
                required
                labelTranslationId="manage.organization.edit.notificationEmail"
                value={company.companyDto.notificationEmail}
                handleInputChange={(value: string) =>
                  setCompany({
                    ...company,
                    companyDto: { ...company.companyDto, notificationEmail: value }
                  })
                }
                errorHandler={getErrorHandler(nameOf<CompanyDto>("notificationEmail"))}
              />
              <TextFormInput
                formGroupClassName="col-lg-6"
                labelTranslationId="manage.organization.edit.billingId"
                value={company.companyDto.billingId}
                handleInputChange={(value: string) =>
                  setCompany({ ...company, companyDto: { ...company.companyDto, billingId: value } })
                }
                errorHandler={getErrorHandler(nameOf<CompanyDto>("billingId"))}
              />
            </Row>
            <Row>
              <FormGroup className="mb-2 col-lg-6 mt-4">
                <CheckboxFormInput
                  className="col-lg-6"
                  label={intl.formatMessage({ id: "manage.organization.edit.nonSpId" })}
                  value={"nonSpId"}
                  checked={company.companyDto.nonSpId}
                  handleInputChange={(value: boolean) =>
                    setCompany({
                      ...company,
                      companyDto: { ...company.companyDto, nonSpId: value }
                    })
                  }
                />
              </FormGroup>
              {/* displays the dropdown input for admin users and sets the client type based on a URL condition for non-admin users. */}

              {userIsAdmin ? (
                <DropdownFormInput
                  className="col-lg-6"
                  required
                  labelTranslationId="manage.organization.edit.clientType"
                  value={company.companyDto.clientType }
                  handleInputChange={(value: string) =>
                    setCompany({
                      ...company,
                      companyDto: { ...company.companyDto,clientType:value }
                    })
                  }
                  errorHandler={getErrorHandler(nameOf<CompanyDto>("clientType"))}
                  options={[
                    { key: "Select", value:""},
                    { key: "PortControl", value:CompanyClientType.PORTCONTROL },
                    { key: "Wholesale", value: CompanyClientType.WHOLESALE }
                  ]}
                /> 
              ) : (
                setCompany({
                  ...company,
                  companyDto: { ...company.companyDto, clientType : isMixNetworkUrl ? "WHOLESALE":"PORTCONTROL" }
                })
              )}
              <TextFormInput
                formGroupClassName="col-lg-6"
                labelTranslationId="manage.organization.edit.tenantId"
                value={company.companyDto.tenantId}
                handleInputChange={(value: string) =>
                  setCompany({ ...company, companyDto: { ...company.companyDto, tenantId: value } })
                }
                readonly
                errorHandler={getErrorHandler(nameOf<CompanyDto>("tenantId"))}
              />
            </Row>
            <Row>
              <FormGroup className="col-lg-6 mt-4">
                <CheckboxFormInput
                  className=""
                  label={intl.formatMessage({ id: "manage.organization.edit.csrOptIn" })}
                  value={"csrEnabled"}
                  checked={company.companyDto.csrEnabled}
                  handleInputChange={(value: boolean) =>
                    setCompany({
                      ...company,
                      companyDto: { ...company.companyDto, csrEnabled: value }
                    })
                  }
                />
              </FormGroup>
            </Row>
          </div>
          <div className="modal-footer">
            <Button color="link" type="button" onClick={() => props.closeModal()}>
              <FormattedMessage id="manage.organization.edit.cancelButton" />
            </Button>
            <Button color="primary" type="submit" className="ml-auto" disabled={showLoadingIndicator}>
              {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="manage.organization.edit.submitButton" />
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

const validateCompany = (company: Company) => {
  const errors: Errors = {};

  if (!company.companyDto.name) {
    AddError(errors, nameOf<CompanyDto>("name"), "manage.organization.edit.name.required");
  }

  if (!isValidZipCode(company.companyDto.zipCode)){
    AddError(errors, nameOf<CompanyDto>("zipCode"), "manage.organization.edit.zipCode.invalid");
  }

  if (!isEmail(company.companyDto.notificationEmail)) {
    AddError(errors, nameOf<CompanyDto>("notificationEmail"), "manage.organization.edit.notificationEmail.invalid");
  }

  if (!isEmail(company.companyDto.primaryContactEmail)) {
    AddError(errors, nameOf<CompanyDto>("primaryContactEmail"), "manage.organization.edit.primaryContactEmail.invalid");
  }
  if (!company.companyDto.clientType) {
    AddError(errors, nameOf<CompanyDto>("clientType"), "manage.organization.edit.clientType.invalid");
  }

  return errors;
};
