import React, { useMemo, useEffect, useContext } from "react";
import { useIntl, IntlShape } from "react-intl";
import ReactTable from "components/framework/table/ReactTable";
import PortingNumbersTableActionsCell from "./PortingNumbersTableActionsCell";
import { getValueOrEmpty } from "services/util/StringUtil";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { OrderDto } from "services/apis/types/order/OrderDto";
import PortingNumbersTableActionsButton from "./PortingNumbersTableActionsButton";
import { getFormattedUtcDate } from "services/util/DateUtil";
import { NumberStatusDto } from "services/apis/types/order/NumberStatusDto";
import { getNumberStatus } from "services/apis/types/order/NumberStatus";
import TextWithTooltip from "components/common/TextWithTooltip";
import DuplicateTNNotifier from "components/common/DuplicateTNNotifier";
import { showDuplicationInfoNotification } from "components/framework/notification/NotificationUtil";
import { AppContext } from "services/appContext/AppContext";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import FormatDate from "components/framework/date/FormateDate";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;
type Props = {
    order: OrderDto;
};

export default function PortingNumbersTable(props: Props) {
    const intl = useIntl();
    const { appContext } = useContext(AppContext);
    const columns = useMemo(() => getColumns(intl, props), [intl, props]);
    useEffect(() => {
        if (props.order.tnsInOtherOrders && props.order.tnsInOtherOrders !== undefined) {
            const orders = Array.prototype.concat.apply([], Object.values(props.order.tnsInOtherOrders));
            const unique = orders.filter((uniqueNumber, index) => {
                return (
                    uniqueNumber && orders.indexOf(uniqueNumber) === index
                );
            });
            const userSpIdProfiles = appContext.localStorageInfo.user?.spIdProfiles.map(x => x.spId);
            const spidOfOtherOrders = unique.filter(order => typeof order === 'string' && order.length >= 4)
                .map(order => order.substring(0, 4))
                .toString();


            if (userSpIdProfiles?.includes(spidOfOtherOrders)) {
                const value = intl.formatMessage({ id: "orders.orderDetails.portingNumber.table.duplicateNotificationMessage" }, { orders: unique.toString() });
                showDuplicationInfoNotification(value);
            }
            else {
                const value = intl.formatMessage({ id: "orders.orderDetails.portingNumber.table.duplicateNotificationMessageForOtherOrg" });
                showDuplicationInfoNotification(value);
            }

        }
    }, [props.order, props.order.tnsInOtherOrders, intl])

    return (
        <div className="card-wrapper">
            <ReactTable
                title={intl.formatMessage({ id: "orders.orderDetails.portingNumber.table.title" })}
                data={props.order.numbers}
                columns={columns}
                showExport={true}
                filterMapper={filterMapper}>
                <PortingNumbersTableActionsButton order={props.order} />
            </ReactTable>
        </div>
    );
}

const getColumns = (intl: IntlShape, props) => {
    const columns = [
        {
            Header: intl.formatMessage({ id: "orders.orderDetails.portingNumber.table.number" }),
            accessor: "phoneNumber",
            Cell: (cell: any) => (<DuplicateTNNotifier order={cell.cell.value} columnValue="phoneNumber" orderInfo={props} />)
        },
        {
            Header: intl.formatMessage(isMixNetworkUrl ? { id: "orders.portIn.stepTwo.autoActivation" } : { id: "orders.orderDetails.portingNumber.table.dueDate" }),
            accessor: "dueDate",
            Cell: (cell: any) => cell.cell.value && (
                isMixNetworkUrl ?
                    <FormatDate utcDate={cell.cell.value} /> :
                    <FormatDateTime utcDate={cell.cell.value} />
            )
        },
        {
            Header: intl.formatMessage({ id: "orders.orderDetails.portingNumber.table.status" }),
            accessor: "status",
            Cell: (cell: any) => (getValueOrEmpty(cell.cell.row.original.resolution) ? <TextWithTooltip
                className="order-status-tooltip cursor-pointer"
                tooltip={cell.cell.row.original.resolution}
                value={getNumberStatus(cell.cell.value)}
            /> : getNumberStatus(cell.cell.value)
            )
        },
        {
            Header: intl.formatMessage({ id: "orders.orderDetails.portingNumber.table.actions" }),
            Cell: (cell: any) => (
                <PortingNumbersTableActionsCell
                    numberStatusDto={cell.cell.row.original}
                    actions={cell.cell.row.original.availableActions}
                />
            )
        }
    ]
    if (!isMixNetworkUrl) {
        columns.splice(1, 0, {  // Insert LRN column as the second column
            Header: intl.formatMessage({ id: "orders.orderDetails.portingNumber.table.lrn" }),
            accessor: "lrn",
            Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
        });
    }

    return columns;
};

const filterMapper = (number: NumberStatusDto) => {
    return {
        ...number,
        dueDate: getFormattedUtcDate(number.dueDate)
    };
};