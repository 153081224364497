import React, { useState, useContext, FormEvent } from 'react';
import { Modal, Form, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CloseButton from 'components/framework/modals/CloseButton';
import { OrderDto } from 'services/apis/types/order/OrderDto';
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";

type Props = {
  closeModal: () => void;
  onSubmit: (reason: string | null) => void;
  order?: OrderDto;
};

export default function CancelAutoActivationModal(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [cancelReason, setCancelReason] = useState<string | null>(null);
  
  

  const handleCancelActivation = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);
    props.onSubmit(cancelReason || null);
    handleCloseModal();
  };
  

  const handleCancelReasonChange = (value) => {
    setCancelReason(value);
  };

  const handleCloseModal = () => {
    setShowLoadingIndicator(false);
    setCancelReason(null);
    props.closeModal();
  };

  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id="orders.portIn.stepTwo.cancelAutoActivationTime" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleCancelActivation(e)}>
        <div className="modal-body">
          <div>
            <h5 className="modal-title mb-2">
              <FormattedMessage id="orders.order.actions.cancelActivation.reason"></FormattedMessage>
            </h5>
          <TextAreaFormInput
              value={cancelReason || ''}
              handleInputChange={handleCancelReasonChange}
              rows={3}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={props.closeModal}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="manage.serviceProvider.create.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className="ml-auto">
            <FormattedMessage id="manage.serviceProvider.create.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
