import LoginLayout from "components/framework/loginLayout/LoginLayout";
import React, { useState } from "react";
import LoginHeader from "components/framework/loginLayout/LoginHeader";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { getTranslation } from "translations/TranslationService";
import SignupForm from "./SignupForm";
import SignupVerify from "./SignupVerify";
import { ToastContainer } from "react-toastify";

export default function Signup() {
  const intl = useIntl();
  const [signUpSuccessfull, setSignUpSuccessfull] = useState(false);

  return (
    <LoginLayout>
      <ToastContainer />
      <LoginHeader title={getTranslation(intl, "account.signup.header")} />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                {signUpSuccessfull ? (
                  <SignupVerify />
                ) : (
                  <SignupForm onSignUpSuccessfull={() => setSignUpSuccessfull(true)} />
                )}
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <small>
                  <Link to="/Login" className="text-light text-xs">
                    <FormattedMessage id="account.signup.login" />
                  </Link>
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </LoginLayout>
  );
}
