import React, { useState, FormEvent } from "react";
import { Modal, Form, Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useErrors } from "services/customHooks/useErrors";
import { Moment } from "moment";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { getFieldErrors, handleFieldOrApiErrors } from "services/util/ApiUtil";
import CloseButton from "components/framework/modals/CloseButton";
import { nameOf } from "services/util/ObjectUtil";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import { OrderApi } from "services/apis/OrderApi";
import { OrderDto } from "services/apis/types/order/OrderDto";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { ModifyCsrModel, DefaultModifyCsrModel } from "./ModifyCsrModel";
import AccountDetails from "../AccountDetails";
import ServiceAddress from "../ServiceAddress";
import classNames from "classnames";
import NpacMaintenanceAlert from "../NpacMaintenanceAlert";
import { ModifyCsrType, ModifyCsrTypes } from "services/apis/types/order/ModifyCsrType";
import { isSunday } from "services/util/DateUtil";
import { NewModifyCsrModel } from "services/apis/types/order/NewModifyCsrModel";
import { AddError, Errors, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { isValidZipCode } from "services/util/StringUtil";
import { CustomerDetailsDto } from "services/apis/types/port/CustomerDetailsDto";
import { ServiceAddressDto } from "services/apis/types/port/ServiceAddressDto";
import { PortInAccountModel } from "../types/PortInAccountModel";


type Props = {
  order: OrderDto;
  closeModal: () => void;
  onActionExecuted: () => void;
};

export default function ModifyCsrModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const [modifyCsrModel, setModifyModel] = useState<ModifyCsrModel>({
    ...DefaultModifyCsrModel,
    dueDate: undefined,
    customerDetails: props.order.customerDetails
  });

  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const errors = validate(modifyCsrModel);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {

      setShowLoadingIndicator(true);

      const model: NewModifyCsrModel = getNewModifyCsrModel(modifyCsrModel);

      OrderApi.modifyCsr(props.order.orderId, props.order.tenant, model)
        .then(() => {
          showInfoNotification(
            intl.formatMessage({
              id: "orders.supplement.successNotificationMessage"
            })
          );
          props.closeModal();
          props.onActionExecuted();
        })
        .catch((error) => {
          handleFieldOrApiErrors(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Modal
      size={modifyCsrModel.modifyType === ModifyCsrType.AccountInfo ? "lg" : "sm"}
      className={classNames("modal-dialog-centered", {
        "pc-modal-xlg": modifyCsrModel.modifyType === ModifyCsrType.AccountInfo
      })}
      isOpen={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id="orders.order.csr.modify.title" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body">
          <div className="d-flex">
            <DropdownFormInput
              className="flex-fill"
              required
              labelTranslationId="orders.order.csr.modify.modifyType"
              value={(modifyCsrModel.modifyType as unknown) as string}
              handleInputChange={(value: string) =>
                setModifyModel({
                  ...modifyCsrModel,
                  modifyType: parseInt(value)
                })
              }
              errorHandler={getErrorHandler(nameOf<ModifyCsrModel>("modifyType"))}
              options={Object.keys(ModifyCsrTypes).map((x) => {
                return {
                  key: ModifyCsrTypes[(x as unknown) as ModifyCsrType],
                  value: x
                };
              })}
            />
          </div>
          {modifyCsrModel.modifyType === ModifyCsrType.DueDate && (
            <div className="d-flex">
              <DateTimeFormInput
                className="flex-fill"
                labelTranslationId="orders.supplement.dueDate"
                value={modifyCsrModel.dueDate}
                handleInputChange={(value: Moment) =>
                  setModifyModel({
                    ...modifyCsrModel,
                    dueDate: value
                  })
                }
                errorHandler={getErrorHandler(nameOf<ModifyCsrModel>("dueDate"))}
              />
            </div>
          )}
          {modifyCsrModel.modifyType === ModifyCsrType.AccountInfo && (
            <>
              <div className="d-flex">
                <div className="add-supplement-modal-section mr-3">
                  <AccountDetails
                    customerDetails={modifyCsrModel.customerDetails}
                    overrideRequiredFields={["billingPhoneNumber", "authorizedName"]}
                    setCustomerDetails={(customerDetails) =>
                      setModifyModel({ ...modifyCsrModel, customerDetails: customerDetails })
                    }
                    getErrorHandler={getErrorHandler}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="add-supplement-modal-section mr-3">
                  <ServiceAddress
                    serviceAddress={modifyCsrModel.customerDetails.serviceAddress}
                    setServiceAddress={(serviceAddress) =>
                      setModifyModel({
                        ...modifyCsrModel,
                        customerDetails: {
                          ...modifyCsrModel.customerDetails,
                          serviceAddress: serviceAddress
                        }
                      })
                    }
                    getErrorHandler={getErrorHandler}
                  />
                </div>
              </div>
            </>
          )}

          {(modifyCsrModel.modifyType === ModifyCsrType.DueDate && isSunday(modifyCsrModel.dueDate)) && (
            <div className="d-flex">
              <NpacMaintenanceAlert classNames="pc-npac-maintenance-warning" />
            </div>
          )}

        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={props.closeModal}>
            <FormattedMessage id="orders.modify.edit.cancelButton" />
          </Button>
          <Button color="primary" type="submit" className="ml-auto" disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="orders.modify.edit.submitButton" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

function getNewModifyCsrModel(modifyModel: ModifyCsrModel) {
  const model: NewModifyCsrModel = {
    modifyType: modifyModel.modifyType
  };

  if (model.modifyType === ModifyCsrType.DueDate) {
    model.dueDate = modifyModel.dueDate?.toDate();
  } else if (model.modifyType === ModifyCsrType.AccountInfo) {
    model.customerDetails = modifyModel.customerDetails;
  }
  return model;
}

const validate = (modifyCsrModel: ModifyCsrModel): Errors => {
  const errors: Errors = {};

  if (modifyCsrModel.modifyType === ModifyCsrType.DueDate && !modifyCsrModel.dueDate) {
    AddError(
      errors,
      nameOf<ModifyCsrModel>("dueDate"),
      "orders.order.csr.modifyDueDate.required"
    );
  }

  if (modifyCsrModel.modifyType === ModifyCsrType.AccountInfo && (
    (!modifyCsrModel.customerDetails.authorizedName))) {
    AddError(errors, nameOf<PortInAccountModel, CustomerDetailsDto>("customerDetails", "authorizedName"),
      "orders.accountDetails.authorizedName");
  }


  if (modifyCsrModel.modifyType === ModifyCsrType.AccountInfo &&
    modifyCsrModel.customerDetails.serviceAddress.zipCode) {
    if (!isValidZipCode(modifyCsrModel.customerDetails.serviceAddress.zipCode)) {
      AddError(
        errors,
        nameOf<ModifyCsrModel, CustomerDetailsDto, ServiceAddressDto>(
          "customerDetails",
          "serviceAddress",
          "zipCode"),
        "orders.portIn.stepTwo.dueDate.zipCodeError"
      );
    }
  }

  return errors;
};
