import { RejectSupplementModel } from "./types/order/RejectSupplementModel";
import { HttpClient } from "services/httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { OrderListDto } from "./types/order/OrderListDto";
import { OrderDto } from "./types/order/OrderDto";
import queryString from "query-string";
import { ModifyModel } from "./types/port/ModifyModel";
import { NoteDto } from "./types/order/NoteDto";
import { AddNotesModel } from "./types/order/AddNotesModel";
import { TaskDto } from "./types/order/TaskDto";
import { AddTasksModel } from "./types/order/AddTasksModel";
import { ApiVersion } from "services/httpClient/ApiVersion";
import { NewSupplementModel } from "./types/order/NewSupplementModel";
import { AddDocumentSuccessModel } from "./types/order/AddDocumentSuccessModel";
import { UpdateOrderStatusModel } from "./types/order/UpdateOrderStatusModel"
import { NewModifyCsrModel } from "./types/order/NewModifyCsrModel";
import { CsrOnlyModel } from "./types/port/CsrOnlyModel";
import { ExportPortingReportDto } from "./types/reports/PortingReportDto";
import { OrderAutoActivationDto } from "./types/order/OrderAutoActivationDto";
import { SupplementType } from "./types/order/SupplementType";

export const OrderApi = {
  list: (search: string, spIds: string[], companyId: string | undefined, tenant: string | undefined = undefined): Promise<OrderListDto[]> => {

    let url = queryString.stringifyUrl({
        url: "order",
        query: {
          search: search && search.length > 0 ? search : undefined,
          spIds: spIds && spIds.length > 0 ? spIds : undefined,
          companyId: companyId ? companyId : undefined
        },
      }, {
      arrayFormat: 'comma'
    });

    return HttpClient.get<OrderListDto[]>(url, true, true, tenant, ApiVersion.V2);
  },

  mixList: (search: string, companyId: string | undefined = undefined, tenant: string | undefined = undefined): Promise<OrderListDto[]> => {
    let url = queryString.stringifyUrl({
        url: "wholesale",
        query: {
          search: search && search.length > 0 ? search : undefined,
          companyId: companyId && companyId.length > 0 ? companyId : undefined
        },
      }, {
      arrayFormat: 'comma'
    });

    return HttpClient.get<OrderListDto[]>(url, true, true, tenant, ApiVersion.V3);
  },

  get: (orderId: string, tenant?: string): Promise<OrderDto> => {
    return HttpClient.get<OrderDto>(`order/${orderId}`, true, true, tenant, ApiVersion.V2);
  },

  modify: (orderId: string, tenant: string, modify: ModifyModel): Promise<ApiError> => {
    return HttpClient.put(`order/${orderId}`, modify, true, true, tenant);
  },

  patch: (orderId: string, projectId: string): Promise<ApiError> => {
    return HttpClient.patch(`order/${orderId}`, { projectId }, true, true);
  },

  activate: (orderId: string, tenant: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`order/${orderId}/activate`, undefined, true, true, tenant);
  },

  cancel: (orderId: string, tenant: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`order/${orderId}/cancel`, undefined, true, true, tenant);
  },

  removeFromConflict: (orderId: string, tenant: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`order/${orderId}/conflict`, undefined, true, true, tenant);
  },

  archive: (orderId: string, tenant: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`order/${orderId}/archive`, undefined, true, true, tenant);
  },

  undoCancel: (orderId: string, tenant: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`order/${orderId}/undocancel`, undefined, true, true, tenant);
  },

  addSupplement: (
    orderId: string,
    tenant: string,
    supplementModel: NewSupplementModel
  ): Promise<ApiError> => {
    return HttpClient.post<ApiError>(
      `order/${orderId}/supplement/`,
      supplementModel,
      true,
      true,
      tenant,
      ApiVersion.V2
    );
  },

  approveSupplement: (orderId: string, tenant: string, focDate?: Date, supplementType?: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(
      `order/${orderId}/supplement/approve`,
      { focDate, supplementType },
      true,
      true,
      tenant,
      ApiVersion.V2
    );
  },

  rejectSupplement: (
    orderId: string,
    tenant: string,
    rejectSupplementModel: RejectSupplementModel
  ): Promise<ApiError> => {
    return HttpClient.post<ApiError>(
      `order/${orderId}/supplement/reject`,
      rejectSupplementModel,
      true,
      true,
      tenant,
      ApiVersion.V2
    );
  },

  updateOrderStatus: (
    orderId: string,
    tenant: string,
    updateOrderStatusModel: UpdateOrderStatusModel
  ): Promise<ApiError> => {
    return HttpClient.patch<ApiError>(
      `order/${orderId}`,
      updateOrderStatusModel,
      true,
      true,
      tenant,
      ApiVersion.V2
    );
  },

  modifyCsr: (
    orderId: string,
    tenant: string,
    modifyModel: NewModifyCsrModel
  ): Promise<ApiError> => {
    return HttpClient.post<ApiError>(
      `order/${orderId}/csr`,
      modifyModel,
      true,
      true,
      tenant,
      ApiVersion.V2
    );
  },

  csrOnlyOrder: (csrOnlyModel: CsrOnlyModel): Promise<ApiError> => {
    return HttpClient.post<ApiError>("order/csr", csrOnlyModel, true, true, "", ApiVersion.V2);
  },

  getNotes: (orderId: string): Promise<NoteDto[]> => {
    return HttpClient.get<NoteDto[]>(`order/${orderId}/notes`, true, true);
  },

  addNote: (orderId: string, noteModel: AddNotesModel): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`order/${orderId}/notes`, noteModel, true, true);
  },

  getTasks: (orderId: string): Promise<TaskDto[]> => {
    return HttpClient.get<TaskDto[]>(`order/${orderId}/tasks`, true, true);
  },

  addTask: (orderId: string, taskModel: AddTasksModel): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`order/${orderId}/tasks`, taskModel, true, true);
  },

  updateTask: (orderId: string, taskId: string, completed: boolean): Promise<ApiError> => {
    return HttpClient.patch(`order/${orderId}/tasks/${taskId}`, { completed }, true, true);
  },

  addDocument: (orderId: string, document: File): Promise<ApiError | AddDocumentSuccessModel> => {
    const data = new FormData();
    data.append("document", document);
    data.append("documentType", document.type);
    return HttpClient.postResource<ApiError>(`order/${orderId}/documents`, data, true, true);
  },

  getDocument: (orderId: string, documentId: string): Promise<ApiError | Blob> => {
    return HttpClient.getResource(`order/${orderId}/documents/${documentId}`, true, true);
  },

  deleteDocument: (orderId: string, documentId: string): Promise<ApiError> => {
    return HttpClient.delete<ApiError>(`order/${orderId}/documents/${documentId}`, {}, true, true);
  },

  portingReport: (from: Date, to: Date): Promise<ExportPortingReportDto[]> => {
    let url = queryString.stringifyUrl({
      url: "report/portingreport",
      query: {
        from: from.toISOString(),
        to: to.toISOString()
      }
    });

    return HttpClient.get<ExportPortingReportDto[]>(url, true, true);
  }
};
