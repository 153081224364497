import React from "react";
import { Label, Input, Tooltip } from "reactstrap";
import classNames from "classnames";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ReactNode } from "react";

type Props = {
  disabled?: boolean;
  value: string;
  className?: string;
  handleInputChange: (checked: boolean) => void;
  checked: boolean;
  label?: string;
  tooltipId?: string;
  children?: ReactNode;
};

export default function CheckboxFormInput(props: Props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div
      key={props.value}
      className={classNames("custom-control custom-checkbox mb-2", props.className)}>
      <Input
        className="custom-control-input"
        id={`customCheck${props.value}`}
        type="checkbox"
        onChange={(e) => props.handleInputChange(e.currentTarget.checked)}
        checked={props.checked}
        disabled={props.disabled}
      />

      {props.tooltipId &&
        <Tooltip placement="bottom" isOpen={tooltipOpen} target={`customCheckLabel${props.value}`} toggle={toggle}>
          <FormattedMessage
            id={props.tooltipId}
            values={undefined}
          />
        </Tooltip>
      }

      <Label className="custom-control-label" id={`customCheckLabel${props.value}`} htmlFor={`customCheck${props.value}`}>
        {props.label}

        {props.children}
      </Label>
    </div>
  );
}