import React, { useState, useEffect, useContext } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, ListGroup, ListGroupItem, Media } from "reactstrap";
import { NotificationDto } from "services/apis/types/notification/NotificationDto";
import { AppContext } from "services/appContext/AppContext";
import { NotificationService } from "services/notification/NotificationService";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { FormattedMessage } from "react-intl";

export default function NotificationsDropdown() {
  const { appContext } = useContext(AppContext);
  const [notifications, setNotifications] = useState<NotificationDto[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  useEffect(() => {
    if (appContext.notifications) {
      setNotifications(appContext.notifications);
      setUnreadNotifications(appContext.notifications.filter(x => !x.isRead).length);
    }
  }, [appContext.notifications]);

  const markAsRead = async (event: React.MouseEvent, notification: NotificationDto) => {
    event.preventDefault();
    await NotificationService.markAsRead(notification);
  };

  return (
    <UncontrolledDropdown nav className="mr-3">
      <DropdownToggle className="nav-link pr-0 cursor-pointer" tag="a">
        <Media className="align-items-center">
          <i className="fa fa-bell fa-lg" style={{ position: "relative" }}>{unreadNotifications > 0 && <span className="notification-circle"
          ><span className="notification-text">{unreadNotifications}</span></span>}</i>
        </Media>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden" right>
        {notifications.length === 0 ?
          <div className="px-3 py-3">
            <h6 className="text-sm text-muted m-0 text-center">
              <FormattedMessage id="framework.notifications.noNotifications" />
            </h6>
          </div>
         : <ListGroup flush>
          {notifications.map(x => (
            <ListGroupItem
              key={x.notificationId}
              className={`list-group-item-action ${!x.isRead && "bg-light"}`}
              href="#"
              onClick={e => markAsRead(e, x)}
              tag="a">
              <h4 className="mb-0">{x.title}</h4>
              <h6 className="mb-0">
                <FormatDateTime utcDate={x.notificationDate} />
              </h6>
              <p className="mb-0 text-sm">{x.message}</p>
            </ListGroupItem>
          ))}
        </ListGroup>}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
