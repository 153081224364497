import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Container, Dropdown, DropdownToggle, Button, DropdownMenu } from "reactstrap";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { handleError } from "services/util/ApiUtil";
import UsersCard from "./user/UsersCard";
import ServiceProvidersCard from "./serviceProvider/ServiceProvidersCard";
import { UserApi } from "services/apis/UserApi";
import { CompanyApi } from "services/apis/CompanyApi";
import { ServiceProviderApi } from "services/apis/ServiceProviderApi";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { FormattedMessage } from "react-intl";
import { useIsMounted } from "services/customHooks/useIsMounted";
import ServiceProviderCreateModal from "./serviceProvider/ServiceProviderCreateModal";
import UserModal from "./user/UserModal";
import { CompanyInfo } from "services/apis/types/company/CompanyInfo";
import { PortControlIdentityUser } from "services/apis/types/user/PortControlIdentityUser";
import OrganizationsCard from "./organization/OrganizationsCard";
import OrganizationModal from "./organization/OrganizationModal";
import { ManageItemType } from "components/manage/types/ManageItemType";
import { ManageAction } from "components/manage/types/ManageAction";
import { PermissionType } from "services/authorization/PermissionType";
import Authorize from "components/framework/authorization/Authorize";
import { authorize } from "services/authorization/AuthorizationService";
import { SpInfo } from "services/apis/types/serviceProvider/SpInfo";

type ManageContext = {
  organizationCallback: () => void;
  serviceProviderCallback: () => void;
  userCallback: () => void;
};

export const ManageContext = React.createContext<ManageContext>({
  organizationCallback: () => {},
  serviceProviderCallback: () => {},
  userCallback: () => {}
});

export default function Manage() {
  const [users, setUsers] = useState<PortControlIdentityUser[]>([]);
  const [companies, setCompanies] = useState<CompanyInfo[]>([]);
  const [serviceProviders, setServiceProviders] = useState<SpInfo[]>([]);

  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [organizationsLoaded, setOrganizationsLoaded] = useState(false);
  const [spidsLoaded, setSpidsLoaded] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);
  const [manageItemType, setManageItemType] = useState<ManageItemType>();
  const [showModal, setShowModal] = useState(false);
  const isMounted = useIsMounted();
  const [userOrganization, setUserOrganization] = useState<String[]>([]);

  useEffect(() => {
    setShowLoadingIndicator(!(usersLoaded && organizationsLoaded && spidsLoaded));
  }, [usersLoaded, organizationsLoaded, spidsLoaded]);

  const fetchOrganizations = useCallback(() => {
    if (authorize(PermissionType.Admin)) {
      CompanyApi.list()
        .then((result) => {
          if (isMounted) {
            setCompanies(result);
          }
        })
        .catch((error) => handleError(error))
        .finally(() => {
          if (isMounted) {
            setOrganizationsLoaded(true);
          }
        });
    } else {
      setOrganizationsLoaded(true);
    }
  }, [isMounted]);

  const fetchServiceProviders = useCallback(() => {
    if (authorize(PermissionType.Admin)) {
      ServiceProviderApi.list()
        .then((result) => {
          if (isMounted.current) {
            setServiceProviders(result);
          }
        })
        .catch((error) => handleError(error))
        .finally(() => {
          if (isMounted.current) {
            setSpidsLoaded(true);
          }
        });
    } else {
      setSpidsLoaded(true);
    }
  }, [isMounted]);

  const fetchUsers = useCallback(() => {
    UserApi.list()
      .then((result) => {
        if (isMounted.current) {
          setUsers(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted.current) {
          setUsersLoaded(true);
        }
      });
  }, [isMounted]);

  const dropdownToggle = () => setShowDropdown((prevState) => !prevState);

  useEffect(() => {
    fetchUsers();
    fetchOrganizations();
    fetchServiceProviders();
  }, [fetchOrganizations, fetchServiceProviders, fetchUsers]);

  const handleCreateDropdownItemButtonClick = (manageItemType: ManageItemType) => {
    setManageItemType(manageItemType);
    setShowModal(true);
    setShowDropdown(false);
  };

  const manageContext: ManageContext = {
    serviceProviderCallback: () => {
      fetchServiceProviders();
      fetchOrganizations();
    },
    userCallback: fetchUsers,
    organizationCallback: () => {
      fetchOrganizations();
      fetchUsers();
    }
  };

  useEffect(() => {
    if (users.length > 0 && companies.length > 0) {
      const updatedUserOrganization = users.map((user) => {
        const company = companies.find((c) => c.id === user.companyId);
        return company ? company.name : companies[0].name;
      });
  
      setUserOrganization(updatedUserOrganization);
    }
  }, [users, companies]);  

  return (
    <ManageContext.Provider value={manageContext}>
      <BreadcrumbHeader>
        <FormattedMessage id="manage.title" />
      </BreadcrumbHeader>
      <Container className="mb-5">
        <Row>
          <Col md="6" className="offset-md-3">
            <h1 className="display-3 text-white">
              <FormattedMessage id="manage.title" />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md="3" xs="12" className="mb-1">
            <div className="pc-create-dropdown">
              <Dropdown isOpen={showDropdown} toggle={dropdownToggle}>
                <DropdownToggle className="cursor-pointer pb-0 pt-0v" tag="span">
                  <Button color="primary" className="pc-btn">
                    <i className="fas fa-plus-square mr-2" />
                    <FormattedMessage id="manage.create" />
                  </Button>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-white bg-dark pc-no-shadow text-white">
                  <Row className="shortcuts px-4">
                    <Col
                      className="shortcut-item cursor-pointer"
                      onClick={() => handleCreateDropdownItemButtonClick(ManageItemType.User)}
                      xs="4">
                      <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue">
                        <i className="fa fa-user" />
                      </span>
                      <small>
                        <FormattedMessage id="manage.create.user" />
                      </small>
                    </Col>
                    <Authorize userPermissions={[PermissionType.Admin]}>
                      <Col
                        className="shortcut-item cursor-pointer"
                        onClick={() =>
                          handleCreateDropdownItemButtonClick(ManageItemType.Organization)
                        }
                        xs="4">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-pc-orange">
                          <i className="fa fa-building" />
                        </span>
                        <small>
                          <FormattedMessage id="manage.create.organization" />
                        </small>
                      </Col>
                    </Authorize>
                    <Authorize userPermissions={[PermissionType.Admin]}>
                      <Col
                        className="shortcut-item cursor-pointer"
                        onClick={() => handleCreateDropdownItemButtonClick(ManageItemType.SpId)}
                        xs="4">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                          <i className="fa fa-id-badge" />
                        </span>
                        <small>
                          <FormattedMessage id="manage.create.spid" />
                        </small>
                      </Col>
                    </Authorize>
                  </Row>
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>
          <Col md="6" xs="12" className="mb-1"></Col>
        </Row>
      </Container>
      <Container fluid>
        {showLoadingIndicator ? (
          <LoadingIndicator white />
        ) : authorize(PermissionType.Admin) ? (
          <>
            <Row>
              <Col lg="6" className="mb-3">
                <UsersCard users={users} availableCompanies={companies} userOrganization={userOrganization}/>
              </Col>
              <Col lg="6" className="mb-3">
                <OrganizationsCard companies={companies} />
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="mb-3">
                <ServiceProvidersCard serviceProviders={serviceProviders} companies={companies} />
              </Col>
            </Row>
          </>
        ) : (
          <Authorize userPermissions={[PermissionType.CompanyAdmin]}>
            <Col lg="10" className="offset-lg-1 mb-3">
              <UsersCard users={users} availableCompanies={companies} userOrganization={userOrganization}/>
            </Col>
          </Authorize>
        )}
      </Container>
      <Container fluid>
        {manageItemType === ManageItemType.Organization && showModal && (
          <OrganizationModal
            manageAction={ManageAction.Add}
            availableCompanies={companies}
            closeModal={() => setShowModal(false)}
          />
        )}
        {manageItemType === ManageItemType.SpId && showModal && (
          <ServiceProviderCreateModal
            availableCompanies={companies}
            closeModal={() => setShowModal(false)}
          />
        )}
        {manageItemType === ManageItemType.User && showModal && (
          <UserModal
            manageAction={ManageAction.Add}
            availableCompanies={companies}
            closeModal={() => setShowModal(false)}
          />
        )}
      </Container>
    </ManageContext.Provider>
  );
}
